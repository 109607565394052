import styled from "styled-components/macro"
import { motion } from "framer-motion"
import { layout, colours } from "theme"

interface HotSpotProps {
  position: { x: number; y: number }
}

interface SceneProps {
  onPan?: (event: any, info: object) => void
  height?: number | string
}

export const SceneWrapper = styled(motion.div)<SceneProps>`
  position: relative;
  touch-action: pan-y;
  padding-top: 1rem;
  z-index: 0;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100%")};
  
  
  top: 0;
  left: 0;
  // flex: 1;
  .shirt-loader {
    position: absolute !important;
    z-index: 0;
    >div{

      width: 6rem;
      margin: auto;
    }
  }
  canvas {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  > div {
    > div {
      transform: none !important;
      width: 100%;
      height: 100%;
    }
  }

  .hotspot-holder {
    position: absolute;
    width: 66% !important;
    left: 17% !important;
    right: 17% !important;
    top: 3% !important;
    height: 84% !important;
  }

  .shadow {
    position: absolute;
    z-index: 0;
    bottom: 0.5rem;
    left: 25%;
    right: 25%;
    width: 50%;
    opacity: 0.7;
  }

  @media (max-width: 991px) {
    min-height: ${(props) => (props.height ? props.height : "100%")};
    .hotspot-holder {
      width: 80% !important;
      left: 10% !important;
      right: 10% !important;
    }
  }
  @media (max-width: 600px) and (max-height: 811px) {
    .hotspot-holder {
      width: 66% !important;
      left: 17% !important;
      right: 17% !important;
    }
  }
`

export const Hotspot = styled(motion.div)<HotSpotProps>`
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin-left: -0.25rem;
  margin-top: -0.25rem;
  transform-origin: 50% 50%;
  justify-content: center;
  align-items: center;
  background: ${colours.background};
  left: ${(props) => props.position.x}%;
  top: ${(props) => props.position.y}%;
  font-family: "FuturaBold", Arial, sens-serif;
  font-size: 1.2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: 0.2s background;
  &:hover,
  &:active {
    background: white;
    transition: 0.2s;
    transform: scale(1.2) !important;
    box-shadow: inset 0 0 1px 2px ${colours.primary};
  }
  p {
    position: absolute;
    color: white;
    right: 120%;
  }
`

export const CanvasHolder = styled.div`
  display: none !important;
`
