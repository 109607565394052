import styled, { keyframes } from "styled-components/macro"
import { motion } from "framer-motion"
import { colours, layout } from "theme"
import { lighten } from "polished"

interface HomeWrapperProps {
  dimensions: {
    width: number
    height: number
  }
  completed?: boolean
}

interface HoodHolderProps {
  dimensions: {
    width: number
    height: number
  }
}

interface AuctionContentProps {
  dimensions: {
    width: number
    height: number
  }
  exploreWidth: number
  buttonWidth: number
}

interface IntroProps {
  completed?: boolean
}

interface CoCreateProps {
  expanded: boolean
  offset: number
}
interface DescriptionProps {
  expanded: boolean
}

const movingAnim = keyframes`
  from {
    transform: translateY(400px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const HomeWrapper = styled.div<HomeWrapperProps>`
  position: relative;
  background: ${colours.primary};
  overflow: hidden;
  flex: 1;
  padding-top: ${(props) => props.dimensions.width * 0.76}px;

  .moving-forward {
    margin: ${(props) => props.dimensions.width * 0.05}px 1rem;
    color: white;
    font-size: ${(props) => props.dimensions.width * 0.145}px;
    line-height: 1;
    opacity: 0;
    animation: ${movingAnim} forwards
      ${(props) => (props.completed ? "0.05s" : "3s")}
      ${(props) => (props.completed ? "0s" : "3s")}
      cubic-bezier(0.16, 1, 0.3, 1);
  }
  .together {
    margin: 0;
    opacity: 0;
    color: white;
    font-size: ${(props) => props.dimensions.width * 0.18}px;
    -webkit-text-stroke: 1px white;
    color: transparent;
    -ms-color: white;
    animation: ${movingAnim} forwards
      ${(props) => (props.completed ? "0.05s" : "3s")}
      ${(props) => (props.completed ? "0s" : "3.2s")}
      cubic-bezier(0.16, 1, 0.3, 1);
  }

  .flare1 {
    right: -200px;
    top: ${(props) => props.dimensions.width * 0.8 - 200}px;
    opacity: 0;
    animation: ${fadeIn} forwards 4s 3.5s;
  }
  .flare2 {
    left: -200px;
    top: ${(props) => props.dimensions.width * 0.8 + 450}px;
    opacity: 0;
    animation: ${fadeIn} forwards 4s 4.5s;
  }
  .flare3 {
    z-index: -3;
    left: -100px;
    right: -130px;
    top: -100px;
    width: 100%;
    opacity: 0;
    animation: ${fadeIn} forwards 4s 4.5s;
  }
  @media (min-width: 992px) {
    padding-top: 0;

    .moving-forward {
      font-size: ${(props) => props.dimensions.width / 23.27}px;
      margin: 0.5rem 1rem 0;
      padding: 1.5rem 1rem 2rem;
    }
    .together {
      margin: 0 0 0 2rem;
      opacity: 0;
      font-size: ${(props) => props.dimensions.width / 18.28}px;
    }

    .flare1 {
      width: 800px;
      height: 800px;
      right: -400px;
      top: -200px;
      opacity: 0;
      animation: ${fadeIn} forwards 4s 3.5s;
    }
  }
`

export const IntroWrapper = styled.div<HomeWrapperProps>`
  z-index: 1;
  @media (min-width: 992px) {
    position: relative;
    left: 50%;
    width: calc(50% - 5rem - ${layout.gutters});
    margin: 0 5rem 0 ${layout.gutters};
    border: white solid 1px;
    border-top: none;
    border-bottom: none;
    top: 0;
    opacity: 0;
    padding-bottom: ${(props) => props.dimensions.width * 0.1}px;
    animation: ${fadeIn} forwards 3s ${(props) => (props.completed ? "" : "1s")};
  }
`

export const MovingForwardWrapper = styled.div<HomeWrapperProps>`
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  margin-bottom: ${(props) => props.dimensions.width * 0.005}px;

  @media (min-width: 992px) {
    margin: ${(props) => props.dimensions.width * 0.095}px 0
      ${(props) => props.dimensions.width * 0.001}px;
  }
`

export const TogetherWrapper = styled(motion.div)<HomeWrapperProps>`
  flex-direction: row !important;
  h2 {
    justify-self: flex-end;
    text-align: right;
    position: relative;
    margin: 3rem 3rem -0.2rem 0;
    font-size: 3.2rem;
    -webkit-text-stroke: 1px white;
    color: transparent;
    -ms-color: white;
  }

  @media (max-width: 991px) {
    h2:last-child {
      display: none !important;
    }
  }

  @media (min-width: 992px) {
    h2 {
      font-size: ${(props) => props.dimensions.width / 12.8}px;
      transform: translateX(-80%);
    }
  }
`

export const AnimatedGridWrapper = styled.div<HomeWrapperProps>`
  position: realtive;
  .arrow-down {
    pacity: 1;
    transform: none;
    position: absolute;
    left: 48%;
    z-index: 40;
    top: ${(props) => props.dimensions.width * 0.36}px;
  }
`

export const IntroCopy = styled(motion.div)`
  margin: 1rem auto;
  position: relative;
  width: 340px;
  p {
    color: white;
    text-align: center;
    margin: 2rem 0;
    font-size: 1.3rem;
    line-height: 1.4;
  }
  opacity: 0;
  svg {
    position: absolute;
    &:first-child {
      top: -1.2rem;
      left: -1rem;
    }
    &:last-child {
      bottom: -2.5rem;
      right: -1rem;
    }
  }
  @media (min-width: 376px) {
    width: 73%;
  }

  @media (min-width: 992px) {
    margin: 5rem auto 3rem;
    width: 90%;
    p {
      font-size: 1.5rem;
    }
  }
`

export const Vid = styled.div<HomeWrapperProps>`
  width: 100%;
  position: relative;
  z-index: 3;

  margin-bottom: 2.5rem;
  opacity: 0;
  left: -0.5rem;
  animation: ${fadeIn} forwards 3s 1s;

  .loader {
    position: absolute !important;
    left: -10%;
  }

  > div {
    width: 110% !important;
    overflow: hidden;
    top: 0;
    > div {
      justify-content: flex-end;
    }
  }

  @media (min-width: 992px) {
    left: 0;
    margin-right: -2px;
    > div {
      position: absolute !important;
      right: 0;
      width: ${(props) => props.dimensions.width * 0.42}px;
      height: ${(props) => props.dimensions.width * 0.275}px !important;
      position: relative;
      top: 0;
      right: 0;
    }
  }
`

export const Description = styled.div<DescriptionProps>`
  margin: 0 1rem 3rem;
  position: relative;
  padding-bottom: 5rem;

  p {
    color: white;
    font-size: 1.15rem;
    line-height: 1.4;
  }
  .row {
    z-index: 10;
    justify-content: center;
    > svg {
      margin: 0 0.5rem;
      transform: rotate(${(props) => (props.expanded ? "180deg" : "0deg")});
    }
    margin-bottom: 5rem;
  }
  a {
    font-size: 0.8rem;
    text-decoration: underline;
    &:hover {
      color: white;
    }
  }

  .flag {
    margin: 2rem auto;
    width: 3rem;
  }

  @media (min-width: 992px) {
    left: -110%;
    width: 85%;
    top: -1rem;
    margin-bottom: 4rem;

    .row {
      display: none !important;
    }
  }
`

export const CoCreation = styled(motion.div)`
  position: relative;
  background: white;
  z-index: 1;
  width: 100%;

  .arrow {
    position: absolute;
    top: -5rem;
    left: 2rem;
    z-index: 4;
  }

  h5 {
    color: white;
    font-size: 2.4rem;
    line-height: 1.1;
    margin-left: 1rem;
  }

  h6 {
    font-size: 1.3rem;
    margin: 1rem 0 0.5rem;
    line-height: 1;
  }

  .button {
    width: 60%;
    margin-bottom: 2rem;
    &:last-child {
      margin-top: 1rem;
    }
  }

  .slant {
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    right: 0;
    width: 100%;
    height: 136px;
  }
  @media (min-width: 992px) {
    min-height: 60rem;

    .button {
      width: 250px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    height: 50%;
    width: 100%;
    background: ${colours.white};
    left: 0;
  }
`

export const CoCreateHero = styled(motion.div)`
  position: relative;
  z-index: 5;
  top: 3rem;
  margin-bottom: 4rem;

  @media (min-width: 376px) and (max-width: 991px) {
    align-items: self-start;
  }

  @media (min-width: 992px) {
    position: absolute;
    top: 1.2rem;
    margin-left: -445px;
    video {
      margin-right: 2rem;
    }
  }
`

export const InspiredWrapper = styled.div`
  margin-top: 2rem;

  p {
    width: 75%;
    margin-bottom: 0;
  }
  @media (max-width: 440px) {
    h6 {
      margin-left: -1rem;
    }
  }
  @media (min-width: 992px) {
    position: absolute;
    top: 460px;
    width: 400px;
    left: -445px;
    margin-bottom: 4rem;

    h6 {
      font-size: 2.375rem;
      line-height: 1;
    }
    .button {
      width: 60%;
    }
  }
`

export const Square = styled(motion.div)<HomeWrapperProps>`
  position: relative;
  background: ${colours.secondary2};
  padding: 1rem;
  top: -4rem;
  width: 80%;
  height: 15rem;
  justify-content: center;
  h5 {
    margin: -5rem 0 0 15%;
  }

  @media (min-width: 992px) {
    width: 40%;
    height: 378px;
    h5 {
      font-size: 3.2rem;
    }
  }
`

export const CoCreateCopy = styled.div<CoCreateProps>`
  position: relative;
  z-index: 5;
  left: 10%;
  top: ${(props) => props.offset / 2 - 220}px;
  width: 377px;
  margin-bottom: 2rem;
  p,
  a {
    color: ${colours.bodyCopyAlt};
  }
  p {
    font-size: 1.05rem;
    padding-right: 3rem;
  }
  .row {
    justify-content: center;
    svg {
      margin: 0 0.5rem;
      transition: 0.2s ease-out;
      transform: rotate(${(props) => (props.expanded ? "180deg" : "0deg")});

      circle {
        fill: ${colours.bodyCopyAlt};
      }
      path {
        stroke: ${colours.white};
      }
    }
  }
  a {
    font-size: 0.8rem;
    text-decoration: underline;
    &:hover {
      color: ${colours.bodyCopyAlt};
    }
  }

  @media (min-width: 376px) and (max-width: 991px) {
    width: 90%;
    .button {
      width: 45%;
    }
  }

  @media (min-width: 992px) {
    position: absolute;
    top: ${(props) => props.offset / 2 + 140}px;
    left: 50%;
    padding: 0 1rem;
    width: 50%;

    .row {
      display: none !important;
    }
  }
`

export const AuctionWrapper = styled(motion.div)`
  pointer-events: none;
  position: relative;
  margin-top: -12rem;
  padding: 10rem 1rem 4rem;
  z-index: 5;
  p,
  h1,
  h5,
  h6 {
    color: ${colours.white};
  }

  * {
    pointer-events: auto;
  }

  h5 {
    font-size: 2.4rem;
    line-height: 1.1;
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    margin: 6rem 0 1rem;
  }

  h6 {
    font-size: 1.3rem;
  }

  h1 {
    // font-size: 4.5rem;
    -webkit-text-stroke: 1px white;
    color: transparent;
    -ms-color: white;
    margin: 0 auto 1rem;
    line-height: 0.7;
  }

  p {
    font-size: 1.05rem;
    max-width: 330px;
  }

  @media (min-width: 992px) {
    margin-top: -6rem;
    p {
      max-width: 500px;
    }
    h2,
    h5 {
      margin-bottom: 0;
    }
  }
`

export const AuctionContent = styled.div<AuctionContentProps>`
  position: realtive;
  padding-top: 5rem;
  z-index: 2;

  h1 {
    white-space: nowrap;
    font-size: ${(props) => {
      let fontWidth = (props.dimensions.width * 1.2) / props.exploreWidth
      if (fontWidth > 200) fontWidth = 200
      return fontWidth
    }}px;
    line-height: 0.7 !important;
  }
  h2 {
    color: white;
    width: 2rem;
    @media (min-width: 992px) {
      font-size: 1.8rem;
    }
  }

  .exploreAnim {
    flex-direction: row !important;
    justify-content: center;
    margin-top: 5rem;
    h1 {
      margin: 1rem 0.5rem;

      &:last-child {
        display: none !important;
      }
    }
  }
  .moreAnim {
    text-align: center;
  }

  .buttonGroup {
    justify-content: center;

    margin: -3.5rem 0 3rem;
    .button {
      margin: 2rem 0.5rem 0;
      &:hover {
        background: ${lighten(0.8, colours.primary)};
      }
    }
    > div {
      &:first-child {
        .button {
          margin-left: 0;
        }
      }
      &:last-child {
        .button {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 375px) {
    .buttonGroup {
      ${(props) => (props.buttonWidth > 180 ? "margin-top: -1.2rem" : "")};
      flex-direction: ${(props) =>
        props.buttonWidth > 180 ? "column" : "row"} !important;
      .button {
        ${(props) => (props.buttonWidth > 180 ? "margin: 0 0 0.8rem" : "")};
      }
    }
  }

  @media (min-width: 700px) {
    // padding: 2rem 6.3rem;
    h1 {
      font-size: ${(props) => {
        let fontWidth = (props.dimensions.width * 1.2) / props.exploreWidth
        if (fontWidth > 180) fontWidth = 180
        return fontWidth
      }}px;
    }
  }

  @media (min-width: 992px) {
    padding-left: 10%;
    .exploreAnim {
      h1 {
        font-size: ${(props) => {
          let fontWidth = (props.dimensions.width * 0.7) / props.exploreWidth
          if (fontWidth > 120) fontWidth = 120
          return fontWidth
        }}px;
        &:last-child {
          margin-left: ${(props) =>
            props.dimensions.width / 6 / props.exploreWidth}px;
          display: flex !important;
        }
      }
    }
    .moreAnim {
      display: none !important;
    }
  }
`

export const DragContainer = styled(motion.div)`
  > .button {
    width: 10rem;
  }
  margin: 2rem 0 1rem;
`

export const DragRow = styled(motion.div)`
  flex-direction: row !important;

  .collabImg {
    width: 30%;
    margin: 0 0.3rem;
    height: 9rem;

    img {
      object-fit: cover;
      height: inherit;
      user-select: none;
      pointer-events: none;
    }
  }

  margin: 0 0 1.5rem -20%;

  @media (min-width: 600px) {
    .collabImg {
      height: 15rem;
    }
  }

  @media (min-width: 992px) {
    margin-left: -15%;
    .collabImg {
      height: 20rem;
    }
  }

  @media (min-width: 1500px) {
    margin-left: -15%;
    .collabImg {
      height: 25rem;
    }
  }
`

export const ExpandSection = styled(motion.div)`
  overflow: hidden;
  width: 100%;
  > div {
    margin-top: -0.8rem;
    padding-bottom: 1rem;
  }
`

export const Slant = styled.div`
  position: absolute;
  z-index: 0;
  width: 5000px;
  height: 5000px;
  left: 50%;
  margin: -50px 0 0 -2500px;
  transform-origin: 50% 0;
  transform: rotate(-10deg);
  background: ${colours.primary};
`

export const HoodHolder = styled.div<HoodHolderProps>`
  position: absolute;
  z-index: 10;
  pointer-events: none;

  width: 375px;
  margin-top: -${(props) => props.dimensions.width * 0.1 + 60}px;

  .hood {
    pointer-events: none;
    position: absolute;
    width: initial;
    top: -7rem;
    img {
      width: 270px;
      height: auto;
      transform: rotate(-10deg);
    }

    .shop-button {
      position: absolute;
      z-index: 100;
      left: 50%;
      width: 80px;
      height: 80px;
      pointer-events: auto;

      svg {
        transform: scale(1);
      }

      > div {
        color: white;
        font-size: 0.6rem;
      }
    }

    &:first-of-type {
      //shirt
      transform: translate(-50px, 20px);
      img {
        transform: translate(-35px, 20px) rotate(-8deg);
      }
      .shop-button {
        margin-left: -60px;
        bottom: -1rem;
      }
    }
    &:last-of-type {
      //hood
      transform: translateX(70px);
      margin-left: 1rem;
      img {
        transform: rotate(4deg);
      }
      .shop-button {
        bottom: -0.5rem;
        margin-left: -45px;
      }
    }
  }

  @media (max-width: 991px) {
    right: 50%;
    top: 10rem;
    margin-right: -245px;
  }
  @media (min-width: 440px) {
    .hood {
      top: -4rem;
    }
  }
  @media (min-width: 992px) {
    right: 7%;

    .hood {
      top: -6rem;
      img {
        width: 330px;
        height: auto;
      }
      &:first-of-type {
        //shirt
        transform: translate(-90px, 30px);
      }
      &:last-of-type {
        //hood
        transform: translateX(80px);
      }
    }
  }
`

export const CollaboratorYoutubeButton = styled.div`
  align-self: flex-start;
  margin: 1.5rem 0 0;
  align-items: center;
  background: ${colours.grey2};
  padding-left: 0.3rem;
  height: 2.2rem;
  border-radius: 1.2rem;
  color: ${colours.primary};
  flex-direction: row !important;
  cursor: pointer;
  p {
    color: ${colours.primary};
    font-size: 0.75rem;
    line-height: 0 !important;
    margin: 0 1.5rem 0 1rem;
  }
  transition: 0.2s;
  &:hover {
    background: ${lighten(0.1, colours.grey2)};
  }
  &:active {
    background: ${lighten(0.1, colours.grey2)};
    transform: translateY(2px);
  }
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`
