import React, { useEffect, useState } from "react"
import { navigate, useLocation } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { stepDirection, viewport } from "store/atoms"
import { Text } from "components"
import { Checkpoint, TickPoint, TimelineWrapper } from "./styles"
import { ReactComponent as Tick } from "assets/images/tick.svg"
import { useTranslation } from "react-i18next"

const checkpoints = [
  "editorTimelineSlice",
  "editorTimelineFabric",
  "editorTimelinePatches",
  "editorFinish",
]

interface TimelineProps {
  className?: string
}

const Timeline: React.FC<TimelineProps> = ({ className }) => {
  const view = useRecoilValue(viewport)
  const [check, setCheck] = useState({ step: 0, progress: [0, 0, 0, 0] })
  const setDirection = useSetRecoilState(stepDirection)
  const url = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    const stepNum = Number(url.pathname.charAt(url.pathname.length - 1))
    let w = window.innerWidth / 3
    if (view.isMobile && window.innerWidth > 600) {
      w = window.innerWidth / 4
    } else if (!view.isMobile) {
      w = (window.innerWidth * 0.35) / 4
    }

    let progress = [0, 0, 0, 0]
    let step = 0

    if (stepNum === 2) {
      step = 0
      progress = [0.5 * w, 0, 0, 0]
    } else if (stepNum === 3) {
      step = 1
      progress = [1 * w, 0, 0, 0]
    } else if (stepNum > 3 && stepNum < 8) {
      step = 2
      progress = [1 * w, 1 * w, 0.25 * (stepNum - 4) * w, 0]
    } else if (stepNum === 8) {
      step = 3
      progress = [1 * w, 1 * w, 1 * w, window.innerWidth > 600 ? 0 : 1 * w]
    }

    setCheck({ step, progress })
  }, [url])

  const goto = (index: number) => {
    const steps = [
      `${t("pathStep")}1`,
      `${t("pathStep")}3`,
      `${t("pathStep")}4`,
      `${t("pathStep")}8`,
    ]
    setDirection("left")
    navigate(steps[index])
  }

  const getPoints = () => {
    return checkpoints.map((val, i) => {
      if (i === checkpoints.length - 1) {
        return (
          <TickPoint
            key={`Checkpoint${i}`}
            className="tickpoint"
            active={check.step === i}
          >
            <Tick />
          </TickPoint>
        )
      }

      return (
        <Checkpoint
          key={`Checkpoint${i}`}
          visited={check.step > i}
          active={check.step === i}
          className={
            check.step === i
              ? " checkpoint active"
              : check.step > i
              ? "checkpoint visited"
              : "checkpoint "
          }
          progressWidth={check.progress[i]}
          onClick={() => (check.step > i ? goto(i) : null)}
        >
          <Text textId={val} type="p" />
        </Checkpoint>
      )
    })
  }

  return (
    <TimelineWrapper
      className={`${className} ${check.step === 3 && "finished"}`}
    >
      {getPoints()}
      {check.step === 3 && <Text textId="editorReviewComplete" type="h5" />}
    </TimelineWrapper>
  )
}

export default Timeline
