import React, { useEffect, useMemo, useRef } from "react"
import { useRecoilValue } from "recoil"
import { Flare } from "theme"
import {
  homeImagesLoaded,
  scrollY,
  startAnimCompleted,
  viewport,
} from "store/atoms"
import { RouteComponentProps } from "@reach/router"
import AnimatedGrid from "./AnimatedGrid"
import Intro from "./Intro"
import CoCreate from "./CoCreate"
import Auction from "./Auction"
import { HomeWrapper } from "./styles"
import DataLayer from "utils/dataLayer"

let scrollPct = 0

const Home: React.FC<RouteComponentProps> = () => {
  const wrapper = useRef<HTMLDivElement>(null)
  const view = useRecoilValue(viewport)
  const homeLoaded = useRecoilValue(homeImagesLoaded)
  const scroll = useRecoilValue(scrollY)

  const completed = useRecoilValue(startAnimCompleted)

  const sendToDataLayer = (scrollPct: number) => {
    let depth = "0%"
    if (scrollPct > 25 && scrollPct < 50) depth = "25%"
    if (scrollPct > 50 && scrollPct < 75) depth = "50%"
    if (scrollPct > 75) depth = "100%"
    DataLayer.event(depth, "page_depth", "engagement")
  }

  useEffect(() => {
    if (wrapper) {
      const el = wrapper.current!
      const scrollHeight: number = el.getBoundingClientRect().height
      scrollPct = (scroll / (scrollHeight - view.height)) * 100
      sendToDataLayer(scrollPct)
    }
  }, [scroll, view])

  return (
    <HomeWrapper ref={wrapper} dimensions={view} completed={completed}>
      {useMemo(
        () => (
          <AnimatedGrid dimensions={view} />
        ),
        [view]
      )}

      {homeLoaded && (
        <>
          <Intro scrollPct={scrollPct} view={view} completed={completed} />
          <CoCreate scrollPct={scrollPct} view={view} />
          <Auction scrollPct={scrollPct} view={view} />
          <Flare className="flare1" />
          <Flare className="flare2" />
        </>
      )}
    </HomeWrapper>
  )
}

export default Home
