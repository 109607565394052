import styled from "styled-components/macro"
import { motion } from "framer-motion"
import { colours } from "theme"
import { rgba } from "polished"

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${rgba(colours.background, 0.8)};
  justify-content: center;
  align-items: center;
  z-index: 900;
  transform-origin: 50% 40%;
  padding: 1rem;
`

export const ModalWrapper = styled(motion.div)`
  background: white;
  min-height: 20%;
  max-width: 600px;
  padding: 2rem;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  h3 {
    font-size: 2rem;
    line-height: 1;
  }
`

export const ModalContent = styled.div`
  h3 {
    margin: 1rem auto;
    line-height: 1;
  }
  p {
    margin: 1rem auto 3rem;
    text-align: center;
    width: 80%;
  }

  .button {
    margin: 0.5rem auto 0.5rem;
    width: 70%;
    &:first-child {
      margin-top: 3rem;
    }
  }

  &.share {
    .row {
      margin: 1rem 0;
      &.center {
        margin-top: 4rem;
        justify-content: center;
      }
    }
  }
`
