import React, { useEffect, useState, useRef } from "react"
import { useRecoilValue } from "recoil"
import { scrollY, viewport, rootPath, locale } from "store/atoms"
import { configSettings} from "store/selectors"
import { navigate, RouteComponentProps } from "@reach/router"
import AnimatedPics from "./AnimatedPics"
import { AnimatedContent, InlineButton, Text } from "components"
import Collab1 from "assets/images/landingCollaborator1.jpg" // Calle Y Poche
import Collab2 from "assets/images/landingCollaborator2.jpg" // Pietro Boselli
import Collab3 from "assets/images/landingCollaborator3.jpg" // Korean Englishmen - DELETED
import Collab4 from "assets/images/landingCollaborator4.jpg" // Haifa Beseisso
import Collab5 from "assets/images/landingCollaborator5.jpg" // Alton Mason
import Collab6 from "assets/images/landingCollaborator6.jpg" // Neels Visser
import Collab7 from "assets/images/landingCollaborator7.jpg" // Jasmine Sanders
import Collab8 from "assets/images/landingCollaborator8.jpg" // Dilone
import Collab9 from "assets/images/landingCollaborator9.jpg" // Michaela De Prince
import Collab10 from "assets/images/landingCollaborator10.jpg" // Audrey Hilfiger

// import Design1 from "assets/videos/collabs/desktop/1.mp4" // Korean Englishmen - DELETED
import Design2 from "assets/videos/collabs/desktop/2.mp4"
import Design3 from "assets/videos/collabs/desktop/3.mp4"
import Design4 from "assets/videos/collabs/desktop/4.mp4"
import Design5 from "assets/videos/collabs/desktop/5.mp4"
import Design6 from "assets/videos/collabs/desktop/6.mp4"
import Design7 from "assets/videos/collabs/desktop/7.mp4"
import Design8 from "assets/videos/collabs/desktop/8.mp4"
import Design9 from "assets/videos/collabs/desktop/9.mp4"
import Design10 from "assets/videos/collabs/desktop/10.mp4"

// import Design1Mob from "assets/videos/collabs/mobile/1.mp4" // Korean Englishmen - DELETED
import Design2Mob from "assets/videos/collabs/mobile/2.mp4"
import Design3Mob from "assets/videos/collabs/mobile/3.mp4"
import Design4Mob from "assets/videos/collabs/mobile/4.mp4"
import Design5Mob from "assets/videos/collabs/mobile/5.mp4"
import Design6Mob from "assets/videos/collabs/mobile/6.mp4"
import Design7Mob from "assets/videos/collabs/mobile/7.mp4"
import Design8Mob from "assets/videos/collabs/mobile/8.mp4"
import Design9Mob from "assets/videos/collabs/mobile/9.mp4"
import Design10Mob from "assets/videos/collabs/mobile/10.mp4"

// import Poster1 from "assets/videos/collabs/posters/1.png"// Korean Englishmen - DELETED
import Poster2 from "assets/videos/collabs/posters/2.png"
import Poster3 from "assets/videos/collabs/posters/3.png"
import Poster4 from "assets/videos/collabs/posters/4.png"
import Poster5 from "assets/videos/collabs/posters/5.png"
import Poster6 from "assets/videos/collabs/posters/6.png"
import Poster7 from "assets/videos/collabs/posters/7.png"
import Poster8 from "assets/videos/collabs/posters/8.png"
import Poster9 from "assets/videos/collabs/posters/9.png"
import Poster10 from "assets/videos/collabs/posters/10.png"
import { Flare } from "theme"
import { useTranslation } from "react-i18next"
import { Collaborators, AuctionHead, AuctionWrapper } from "./styles"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import Collaborator from "./Collaborator"
import { motion } from "framer-motion"


/*
SQUIDEX KEY NUMBERS

1- Audrey Hilfiger
2- Jasmine Sanders
3- Neels Visser
4- Calle Y Poche
5- Pietro Boselli
6- Haifa Beseisso
7- Korean Englishman // Korean Englishmen - TO BE DELETED
8- Alton Mason
9- Dilone
10 - Michaela De Prince

*/


/*
DISPLAY ORDER

1- Calle Y Poche
2- Pietro Boselli
3- Haifa Beseisso
4- Neels Visser
5- Michaela De Prince
6- Jasmine Sanders
8- Audrey Hilfiger
9- Alton Mason
10- Dilone
DELETED -- Korean Englishmen

*/


const collabs = [
/*  
  DELETED
  {
    // Korean Englishmen
    portrait: Collab3,
    design: Design1,
    designMob: Design1Mob,
    poster: Poster1,
    translationsIndex: 7,
    showBidButton:true,
  },
*/
  {
    // Calle Y Poche
    portrait: Collab1,
    design: Design3,
    designMob: Design3Mob,
    poster: Poster3,
    translationsIndex: 4,
    showBidButton:true,
  },
  {
    // Pietro Boselli
    portrait: Collab2,
    design: Design2,
    designMob: Design2Mob,
    poster: Poster2,
    translationsIndex: 5,
    showBidButton:true,
  },
  {
    // Haifa Beseisso
    portrait: Collab4,
    design: Design4,
    designMob: Design4Mob,
    poster: Poster4,
    translationsIndex: 6,
    showBidButton:true,
  },
  {
    // Neels Visser
    portrait: Collab6,
    design: Design5,
    designMob: Design5Mob,
    poster: Poster5,
    translationsIndex: 3,
    showBidButton:true,
  },
  {
    // Michaela De Prince
    portrait: Collab9,
    design: Design6,
    designMob: Design6Mob,
    poster: Poster6,
    translationsIndex: 10,
    showBidButton:true,
  },
  {
    // Jasmine Sanders
    portrait: Collab7,
    design: Design7,
    designMob: Design7Mob,
    poster: Poster7,
    translationsIndex: 2,
    showBidButton:true,
  },
  {
    // Audrey Hilfiger
    portrait: Collab10,
    design: Design8,
    designMob: Design8Mob,
    poster: Poster8,
    translationsIndex: 1,
    showBidButton:true,
  },
  {
    // Alton Mason
    portrait: Collab5,
    design: Design9,
    designMob: Design9Mob,
    poster: Poster9,
    translationsIndex: 8,
    showBidButton:true,
  },
  {
    // Dilone
    portrait: Collab8,
    design: Design10,
    designMob: Design10Mob,
    poster: Poster10,
    translationsIndex: 9,
    showBidButton:true,
  },
]

let scrollPct = 0

const Auction: React.FC<RouteComponentProps> = () => {
  const wrapper = useRef<HTMLDivElement>(null)
  const view = useRecoilValue(viewport)
  const scroll = useRecoilValue(scrollY)
  const rootUrl = useRecoilValue(rootPath)
  const [tunedWidth, setTunedWidth] = useState(10)
  const { t } = useTranslation()
  const { isMobile } = useRecoilValue(viewport)
  const config = useRecoilValue(configSettings)
  const { longCode } = useRecoilValue(locale)

  useEffect(() => {
    window.scrollTo(0, 0)
    window.onbeforeunload = () => {
      window.scrollTo(0, 0)
    }
    setTunedWidth(t("auctionStayTuned").length)
  }, [])

  useEffect(() => {
    if (wrapper) {
      const el = wrapper.current!
      const scrollHeight: number = el.getBoundingClientRect().height
      scrollPct = (scroll / (scrollHeight - view.height)) * 100
    }
  }, [scroll, view])

  const goto = (url: any) => {
    navigate(url)
  }

  const getCollabs = () => {
    return collabs.map((val, i) => {
      const { portrait, design, designMob, poster, translationsIndex, showBidButton } = val
      return (
        <Collaborator
          key={`collab${i}`}
          portrait={portrait}
          poster={poster}
          design={isMobile ? designMob : design}
          index={translationsIndex}
          scrollPos={scroll}
          viewHeight={view.height}
          showBidButton={showBidButton}
        />
      )
    })
  }

  const canBid = !config.nonBidCountries.includes(longCode);

  return (
    <AuctionWrapper
      ref={wrapper}
      dimensions={view}
      scrollPos={scroll}
      tunedWidth={tunedWidth}
    >
      <AuctionHead scrollPos={scroll}>
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={() => goto(rootUrl === "/" ? rootUrl : `/${rootUrl}/`)}
        />
        <AnimatedPics scrollPos={scroll} dimensions={view} />
        <Flare className="flare1" />
        <motion.div
          animate={scroll > 5 ? "inactive" : "active"}
          variants={{
            active: {
              opacity: 1,
            },
            inactive: {
              opacity: 0,
            },
          }}
        >
          <Text className="together" textId="auctionHeader" type="h1" />
        </motion.div>
      </AuctionHead>

      <Collaborators>
        <AnimatedContent trigger={scroll > 5} from="100%" delay={0.2}>
          <Text textId="auctionCollaborators" type="h3" />
        </AnimatedContent>
        {/* <AnimatedContent trigger={scroll > 5} from="100%" delay={0.4}>
          <Text textId="landingGiveBackDescription" type="p" />
        </AnimatedContent> */}
        <AnimatedContent trigger={scroll > 5} from="100%" delay={0.6}>
          <Text textId={canBid ? "auctionCopy2" : "auctionCopyAlternative"} type="p" />
          {!canBid && <Text className={"auctionTerms"} textId={"auctionCopyAlternativeTerms"} type="p" />}
        </AnimatedContent>
        <AnimatedContent trigger={scroll > 5} delay={0.8}>
          <>{getCollabs()}</>
        </AnimatedContent>

        <Flare className="flare2" />
      </Collaborators>
    </AuctionWrapper>
  )
}

export default Auction
