import React from "react"
import { motion, useMotionValue } from "framer-motion"

interface AnimatedContentProps {
  style?: any
  className?: string
  delay?: number
  direction?: string
  from?: number | string
  trigger: boolean
  scrollTo?: number
}

const AnimatedContent: React.FC<AnimatedContentProps> = ({
  children,
  className,
  delay,
  direction,
  from,
  scrollTo,
  style,
  trigger,
}) => {
  const y = useMotionValue(0)
  let animateFrom: number | string = 50
  if (from) {
    animateFrom = from
  }

  if (scrollTo) {
    y.set(scrollTo)
  }

  return (
    <motion.div
      className={className}
      style={{ ...style, y }}
      animate={trigger ? "active" : "inactive"}
      initial="inactive"
      variants={{
        active: {
          opacity: 1,
          y: 0,
          x: 0,
          transition: {
            type: "spring",
            damping: 50,
            delay,
          },
        },
        inactive: {
          opacity: 0,
          x: direction === "x" ? animateFrom : 0,
          y: direction !== "x" ? animateFrom : 0,
        },
      }}
    >
      {children}
    </motion.div>
  )
}

export default AnimatedContent
