import styled from "styled-components/macro"
import { motion } from "framer-motion"
import { colours } from "theme"
import { lighten } from "polished"

interface SwatchProps {
  colour: string
}

interface DotProps {
  active: boolean
}

export const StepsWrapper = styled(motion.div)`
  width: 100%;
  z-index: 20;
  align-items: center;
  h5 {
    width: 100%;
    margin: 0.5rem 0 1.5rem;
  }

  .icon-button {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .row {
    position: relative;
    align-items: center;
    width: 100%;

    &.center {
      justify-content: center;
      align-items: center;
      margin: 2rem 0 1rem;

      .inline-button {
        position: absolute;
        left: 0;
      }
      .button {
        margin: 2rem 0 0;
      }
    }

    &.dots {
      margin: 0;
    }

    &.icon-buttons {
      margin-top: 0;
    }
    &.medium-buttons {
      .button {
        margin: 0;
        width: 45%;
      }
    }
  }

  .swatch-labels {
    justify-content: space-around;
    width: 100%;
    h6 {
      margin: 0.5rem 0 0;
    }
  }

  &.finish {
    h5 {
      margin: 1rem 0 0;
    }
    text-align: center;
    .button {
      margin: 1rem 0 0;
      width: 60%;
    }
    a {
      font-family: "futuraBold", futura, arial, san-serif;
      font-size: 0.7rem;
      letter-spacing: 1.5px;
      color: ${colours.primary};
      text-decoration: underline;
      text-transform: uppercase;
      margin: 0.5rem 0 1rem;
    }

    .scene-wrapper.screenshot {
      height: 700px !important;
      width: 700px !important;
      > div {
        height: 700px !important;
        width: 700px !important;
      }
      position: absolute;
      left: 300%;
    }
  }

  @media (min-width: 992px) {
    justify-content: flex-start;
    padding-top: 9rem;
    height: 100%;

    &.step4 {
      justify-content: flex-start;
      flex: none;
      min-height: 100%;

      .dots {
        display: none !important;
      }
    }
    .row {
      position: relative;
      margin-top: 0 !important;
    }
  }
  @media (max-width: 600px) and (max-height: 811px) {
    .scalable {
      margin: -1.3rem 0 -1rem !important;
      transform: scale(0.7);
    }
    .swatch-labels {
      width: 70%;
    }
  }
`

export const SwatchWrapper = styled.div`
  flex-direction: row !important;
  margin: 0 auto;
  align-items: center;
  overflow: hidden;
  border-radius: 0.3rem;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) and (max-height: 811px) {
    width: 70%;
  }
`

export const Swatch = styled.div<SwatchProps>`
  width: 170px;
  height: 130px;
  background: ${(props) =>
    //@ts-ignore
    colours[`${props.colour}`]};
  @media (max-width: 600px) and (max-height: 811px) {
    height: 80px;
    width: 50%;
  }
`

export const Switch = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -1.5rem;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 2px ${colours.primary} solid;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  transition: 0.2s ease-out;
  background: ${colours.white};
  cursor: pointer;
  &:active {
    transform: scale(0.8);
  }
  &:hover {
    background: ${lighten(0.15, colours.secondary)};
  }
`

export const DragRow = styled(motion.div)`
  position: absolute;
  flex-direction: row !important;
`

export const DragContainer = styled(motion.div)`
  position: relative;
  flex-direction: row !important;
  justify-content: flex-start;
  align-content: flex-start;
  width: 400px;
  height: 130px;
  margin-left: 4rem;
`

export const Dot = styled.div<DotProps>`
  margin: 1.5rem 0.5rem 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  background: ${(props) =>
    props.active ? colours.primary : colours.secondary};
  transition: 0.2s background;
`

export const Disclaimer = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  margin: 2rem 0 1rem;
  text-align: center;
  p {
    font-size: 1rem;
    b {
      display: contents !important;
    }
  }
  @media (min-width: 992px) {
    display: block !important;
    margin: 0 auto 0;
    width: 80%;
  }
  @media (min-width: 1492px) {
    margin-top: 4.2rem;
  }

  @media (min-width: 992px) and (max-height: 825px) {
     !important !important;
  }
`

export const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 10rem;
`

export const DesktopPatchHolder = styled.div`
  width: 80%;
  min-height: 300px;
  margin: 0 auto 1rem;
  display: block !important;
  position: relative;

  > div {
    height: 6.5rem;
    position: relative;
    top: 0;
    display: inline-flex !important;
    width: 48%;
    margin: 0 0 1rem;

    svg {
      width: 60%;
    }

    &:nth-child(even) {
      float: right;
    }
  }

  @media (min-width: 1492px) {
    > div {
      height: 8.5rem;
    }
  }
`
