import styled from 'styled-components';

export const Wrapper = styled.li`
  display: block;
    
  &.group.open button.header {
    margin: 12px 0 20px;
  }
  
  ul {
    margin: 0;
    padding: 0;
    
    li {
      display: flex;
      font-family: Futura;
      flex-direction: column;
      justify-content: stretch;
      width: 100%;
      
      select, input, button, input[type="text"] {
        font-family: Futura;
        margin: 0 0 10px;
        background-color: transparent;
        color: #fff;
        height: 40px;
        line-height: 40px;
        position: relative;
        padding: 0 10px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        outline: none;
        border-radius: 0;
      }

      option {
        color: black;
      }
      
      button {
        margin-top: 10px;
        font-weight: 700;
        border: 2px solid #ddd;
        cursor: pointer;
      }
    }
  }
  
  @media (min-width: 1280px) {
    margin: 0 0 50px;
  
    ul {
      li {
        width: 205px;
      }
    }
  }
`;

export default Wrapper;
