import React, { useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { homeImagesLoaded, isLoading, startAnimCompleted } from "store/atoms"
import { motion } from "framer-motion"
import { AnimatedContent } from "components"
import { AnimatedGridWrapper } from "./styles"
import { ReactComponent as ArrowDown } from "assets/images/arrowDownBig.svg"
import Big1 from "assets/images/largeGrid/1.jpg"
import Big2 from "assets/images/largeGrid/2.jpg"
import Big3 from "assets/images/largeGrid/3.jpg"
import Big4 from "assets/images/largeGrid/4.jpg"
import Big5 from "assets/images/largeGrid/5.jpg"
import Big6 from "assets/images/largeGrid/6.jpg"
import Big7 from "assets/images/largeGrid/7.jpg"
import Big8 from "assets/images/largeGrid/8.jpg"

import Small1 from "assets/images/smallGrid/1.jpg"
import Small2 from "assets/images/smallGrid/2.jpg"
import Small3 from "assets/images/smallGrid/3.jpg"
import Small4 from "assets/images/smallGrid/4.jpg"
import Small5 from "assets/images/smallGrid/5.jpg"
import Small6 from "assets/images/smallGrid/6.jpg"
import Small7 from "assets/images/smallGrid/7.jpg"
import Small8 from "assets/images/smallGrid/8.jpg"
import Small9 from "assets/images/smallGrid/9.jpg"
import Small10 from "assets/images/smallGrid/10.jpg"
import Small11 from "assets/images/smallGrid/11.jpg"
import Small12 from "assets/images/smallGrid/12.jpg"
import Small13 from "assets/images/smallGrid/13.jpg"
import Small14 from "assets/images/smallGrid/14.jpg"
import Small15 from "assets/images/smallGrid/15.jpg"
import Small16 from "assets/images/smallGrid/16.jpg"
import Small17 from "assets/images/smallGrid/17.jpg"
import Small18 from "assets/images/smallGrid/18.jpg"
import Small19 from "assets/images/smallGrid/19.jpg"
import Small20 from "assets/images/smallGrid/20.jpg"
import Small21 from "assets/images/smallGrid/21.jpg"
import Small22 from "assets/images/smallGrid/22.jpg"
import Small23 from "assets/images/smallGrid/23.jpg"
import Small24 from "assets/images/smallGrid/24.jpg"

import Big1Mobile from "assets/images/largeGrid/1-mobile.jpg"
import Big2Mobile from "assets/images/largeGrid/2-mobile.jpg"
import Big3Mobile from "assets/images/largeGrid/3-mobile.jpg"
import Big4Mobile from "assets/images/largeGrid/4-mobile.jpg"
import Big5Mobile from "assets/images/largeGrid/5-mobile.jpg"
import Big6Mobile from "assets/images/largeGrid/6-mobile.jpg"
import Big7Mobile from "assets/images/largeGrid/7-mobile.jpg"
import Big8Mobile from "assets/images/largeGrid/8-mobile.jpg"

import Small1Mobile from "assets/images/smallGrid/1-mobile.jpg"
import Small2Mobile from "assets/images/smallGrid/2-mobile.jpg"
import Small3Mobile from "assets/images/smallGrid/3-mobile.jpg"
import Small4Mobile from "assets/images/smallGrid/4-mobile.jpg"
import Small5Mobile from "assets/images/smallGrid/5-mobile.jpg"
import Small6Mobile from "assets/images/smallGrid/6-mobile.jpg"
import Small7Mobile from "assets/images/smallGrid/7-mobile.jpg"
import Small8Mobile from "assets/images/smallGrid/8-mobile.jpg"
import Small9Mobile from "assets/images/smallGrid/9-mobile.jpg"
import Small10Mobile from "assets/images/smallGrid/10-mobile.jpg"
import Small11Mobile from "assets/images/smallGrid/11-mobile.jpg"
import Small12Mobile from "assets/images/smallGrid/12-mobile.jpg"
import Small13Mobile from "assets/images/smallGrid/13-mobile.jpg"
import Small14Mobile from "assets/images/smallGrid/14-mobile.jpg"
import Small15Mobile from "assets/images/smallGrid/15-mobile.jpg"
import Small16Mobile from "assets/images/smallGrid/16-mobile.jpg"
import Small17Mobile from "assets/images/smallGrid/17-mobile.jpg"
import Small18Mobile from "assets/images/smallGrid/18-mobile.jpg"
import Small19Mobile from "assets/images/smallGrid/19-mobile.jpg"
import Small20Mobile from "assets/images/smallGrid/20-mobile.jpg"
import Small21Mobile from "assets/images/smallGrid/21-mobile.jpg"
import Small22Mobile from "assets/images/smallGrid/22-mobile.jpg"
import Small23Mobile from "assets/images/smallGrid/23-mobile.jpg"
import Small24Mobile from "assets/images/smallGrid/24-mobile.jpg"
const big = [
  { id: 0, img: Big1 },
  { id: 1, img: Big2 },
  { id: 2, img: Big3 },
  { id: 3, img: Big4 },
  { id: 4, img: Big5 },
  { id: 5, img: Big6 },
  { id: 6, img: Big7 },
  { id: 7, img: Big8 },
]
const small = [
  { id: 0, img: Small17 },
  { id: 1, img: Small18 },
  { id: 2, img: Small19 },
  { id: 3, img: Small20 },
  { id: 4, img: Small21 },
  { id: 5, img: Small22 },
  { id: 6, img: Small23 },
  { id: 7, img: Small24 },
  { id: 8, img: Small13 },
  { id: 9, img: Small14 },
  { id: 10, img: Small15 },
  { id: 11, img: Small16 },
  { id: 12, img: Small9 },
  { id: 13, img: Small10 },
  { id: 14, img: Small11 },
  { id: 15, img: Small12 },
  { id: 16, img: Small1 },
  { id: 17, img: Small2 },
  { id: 18, img: Small3 },
  { id: 19, img: Small4 },
  { id: 20, img: Small5 },
  { id: 21, img: Small6 },
  { id: 22, img: Small7 },
  { id: 23, img: Small8 },
]

const bigMobile = [
  { id: 0, img: Big1Mobile },
  { id: 1, img: Big2Mobile },
  { id: 2, img: Big3Mobile },
  { id: 3, img: Big4Mobile },
  { id: 4, img: Big5Mobile },
  { id: 5, img: Big6Mobile },
  { id: 6, img: Big7Mobile },
  { id: 7, img: Big8Mobile },
]
const smallMobile = [
  { id: 0, img: Small17Mobile },
  { id: 1, img: Small18Mobile },
  { id: 2, img: Small19Mobile },
  { id: 3, img: Small20Mobile },
  { id: 4, img: Small21Mobile },
  { id: 5, img: Small22Mobile },
  { id: 6, img: Small23Mobile },
  { id: 7, img: Small24Mobile },
  { id: 8, img: Small13Mobile },
  { id: 9, img: Small14Mobile },
  { id: 10, img: Small15Mobile },
  { id: 11, img: Small16Mobile },
  { id: 12, img: Small9Mobile },
  { id: 13, img: Small10Mobile },
  { id: 14, img: Small11Mobile },
  { id: 15, img: Small12Mobile },
  { id: 16, img: Small1Mobile },
  { id: 17, img: Small2Mobile },
  { id: 18, img: Small3Mobile },
  { id: 19, img: Small4Mobile },
  { id: 20, img: Small5Mobile },
  { id: 21, img: Small6Mobile },
  { id: 22, img: Small7Mobile },
  { id: 23, img: Small8Mobile },
]

const bigLoaded: any[] = []
const smallLoaded: any[] = []

interface AnimatedGridProps {
  dimensions: {
    width: number
    height: number
    isMobile: boolean
  }
}

let animatedFinished = 0

const AnimatedGrid: React.FC<AnimatedGridProps> = ({ dimensions }) => {
  const completed = useRecoilValue(startAnimCompleted)
  const setCompleted = useSetRecoilState(startAnimCompleted)

  const imagesLoaded = useRecoilValue(homeImagesLoaded)
  const setImagesLoaded = useSetRecoilState(homeImagesLoaded)
  const setLoading = useSetRecoilState(isLoading)
  const [gridWidth, setGridWidth] = useState(0)

  const countLoaded = () => {
    if (
      big.length === bigLoaded.length &&
      small.length === smallLoaded.length
    ) {
      setLoading({ status: false, wipe: false })
      setImagesLoaded(true)
    }
  }

  const loadBigImages = () => {
    const bigImages = dimensions.isMobile ? bigMobile : big
    bigImages.forEach((val, i) => {
      const img = new Image()
      img.src = val.img
      img.onload = () => {
        bigLoaded.push({ id: val.id, img })
        countLoaded()
      }
    })
  }

  const loadSmallImages = () => {
    const smallImages = dimensions.isMobile ? smallMobile : small
    smallImages.forEach((val, i) => {
      const img = new Image()
      img.src = val.img
      img.onload = () => {
        smallLoaded.push({ id: val.id, img })
        countLoaded()
      }
    })
  }

  useEffect(() => {
    if (!imagesLoaded) {
      setLoading({ status: true, wipe: true })
      loadBigImages()
      loadSmallImages()
    }
  }, [])

  const setFinished = (index: number) => {
    animatedFinished++
    if (animatedFinished === small.length && completed === false) {
      setCompleted(true)
    }
  }

  useEffect(() => {
    if (animatedFinished === small.length && completed === false) {
      setCompleted(true)
    }
    setGridWidth(dimensions.isMobile ? dimensions.width : dimensions.width / 2)
  }, [dimensions])

  const getBigGrid = () => {
    return bigLoaded.map((val, i) => {
      return (
        <motion.img
          src={val.img.src}
          key={`big${i}`}
          style={{
            zIndex: i + 30,
            position: "absolute",
            opacity: 0,
            width: 80 + Math.random() * 200,
            top:
              dimensions.height * 0.5 +
              Math.random() * (dimensions.height * 1.5),
            left: Math.random() * (gridWidth * 0.8) - 0.4,
          }}
          animate={{
            top: -500,
            transition: {
              ease: "easeIn",
              duration: 2 + Math.random() * 0.5,
              delay: 0.3 + Math.random() * 1,
              opacity: {
                duration: 0.5 + Math.random() * 0.5,
              },
            },
            opacity: 1,
          }}
        />
      )
    })
  }

  let count = -1

  const getSmallGrid = () => {
    const sortedLoaded = smallLoaded.sort((a, b) => a.id - b.id)

    return sortedLoaded.map((val, i) => {
      let picWidth = gridWidth / 8
      let picHeight = 0
      if (i > 7 && i < 12) {
        picWidth = gridWidth / 4
        picHeight = gridWidth / 5.3
      } else if (i > 11 && i < 16) {
        picWidth = gridWidth / 4
        picHeight = gridWidth / 2.63
      } else if (i > 15) {
        picHeight = gridWidth / 1.75
      }
      if (i === 8 || i === 12 || i === 16) {
        count = -1
      }
      count++

      return (
        <motion.img
          src={val.img.src}
          key={`small${i}`}
          style={{
            zIndex: i,
            position: "absolute",
            opacity: 0,
            width: picWidth,
            top:
              dimensions.height * 0.5 +
              Math.random() * (dimensions.height * 1.5),
            left: picWidth * count,
          }}
          animate={{
            top: picHeight,
            transition: {
              duration: completed ? 0 : 0.5 + Math.random() * 1,
              delay: completed ? 0 : 2.5 + Math.random() * 0.1 * i,
              opacity: {
                duration: 1 + Math.random() * 0.5,
              },
            },
            opacity: 1,
          }}
          onAnimationComplete={() => setFinished(i)}
        />
      )
    })
  }

  return (
    <AnimatedGridWrapper dimensions={dimensions}>
      {!completed && imagesLoaded && getBigGrid()}
      {imagesLoaded && getSmallGrid()}
      {!dimensions.isMobile && (
        <AnimatedContent
          className="arrow-down"
          trigger={imagesLoaded}
          delay={4}
          from="-100px"
        >
          <ArrowDown className="arrow" />
        </AnimatedContent>
      )}
    </AnimatedGridWrapper>
  )
}

export default AnimatedGrid
