import React from "react"
import { NextButtonWrapper } from "./styles"
import { ReactComponent as Arrow } from "assets/images/arrowRight.svg"
import { useTranslation } from "react-i18next"

interface NextButtonProps {
  onClick: () => void
  textId?: string
  disabled?: boolean
}

const NextButton: React.FC<NextButtonProps> = ({
  disabled,
  onClick,
  textId,
}) => {
  const { t } = useTranslation()
  return (
    <NextButtonWrapper
      className="NextButton"
      onClick={onClick}
      big={textId ? true : false}
      disabled={disabled}
    >
      <Arrow />
      {textId && <div>{t(textId)}</div>}
    </NextButtonWrapper>
  )
}

export default NextButton
