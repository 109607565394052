import styled from "styled-components"
import { colours, breakpoints } from "../../theme"

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  height: 50px;
  background: ${colours.white};

  ${breakpoints.md`
    height: 60px;
    margin-bottom: 3.7rem;
  `}

  li {
    list-style: none;
    white-space: nowrap;
  }
`

export const FindAStore = styled.a`
  position: absolute;
  left: 30px;
  font-family: Futura,Calibri,sans-serif;
  font-weight: 700;
  text-align: center;
  color: #00174f;
  font-size: 10.5px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 60px;
  width: auto;
  text-decoration: none;
  outline: none;
`
