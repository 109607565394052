import React, { useState } from 'react';

import NavigationLink from './NavigationLink';
import NavigationItemCategoryWrapper from './styles';

interface NavigationItemCategoryProps {
  parentName: string;
  baseUrl: string;
  itemChildren: any;
  isMobile: boolean
}

const NavigationItemList: React.FC<NavigationItemCategoryProps> = ({
  parentName, itemChildren, baseUrl, isMobile,
}) => {
  const [expanded, setExpanded] = useState('');

  return (
    <NavigationItemCategoryWrapper>
      {itemChildren.map((data: any) => {
        const {
          name, url: relativePath, id, children: categoryChildren,
        } = data;
        const url = `${baseUrl}/${relativePath}`;
        const twoCol = categoryChildren.length > 10;
        const linkProps = !isMobile && { href: url, rel: 'noopener' };

        return (
          <li className={`list ${twoCol ? 'list--two-column' : ''}`} key={id}>
            <h3 className="heading">
              <a role="button" tabIndex={0} className="list-name" onClick={setExpanded.bind(true, id)} {...linkProps}>
                {name}
                {isMobile && <i className="icon icon-arrow-right" />}
              </a>
            </h3>
            <ul
              className="list__wrapper"
              aria-expanded={expanded === id}
            >
              {isMobile && (
                <>
                  <button className="back" onClick={setExpanded.bind(true, '')}>
                    <i className="icon icon-arrow-left" />
                    {parentName}
                  </button>
                  <h4 className="list__heading">{name}</h4>
                </>
              )}

              <NavigationLink categoryChildren={categoryChildren} baseUrl={baseUrl} />
            </ul>
          </li>
        );
      })}
    </NavigationItemCategoryWrapper>
  );
};

export default NavigationItemList;
