import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"

interface TermsProps {
  onBack: () => void
}

const TermsUK: React.FC<TermsProps> = ({ onBack }) => {
  return (
    <ArticleWrapper>
      <span className="back" onClick={onBack}>
        Back
      </span>
      <div className="container">
        <h1>CONDITIONS GÉNÉRALES</h1>
        <ol>
          <li>
            <p>
              &#171; Créer ensemble &#187; (la &#171;<b>Promotion</b>&#187;) est
              organisée par <b>Tommy Hilfiger Europe B.V.</b>, une société
              privée à responsabilité de droit néerlandais (le &#171;
              <b>Promoteur</b>&#187;) et sera régie par les présentes conditions
              générales (les &#171;
              <b>Conditions</b> &#187;). Tout consommateur participant à la
              Promotion (désigné comme &#171; <b>Participant</b>&#187;) consent
              avoir lu et compris les Conditions et sera lié par celles-ci. Ces
              conditions s’appliquent aux territoiresdes Pays-Bas, de
              l’Allemagne, de la France et du Royaume-Uni. Seuls les résidents
              des Pays-Bas, Allemagne, France et Royaume-Unipeuvent participer.
              La Promotion sera invalide dans les pays ou elle est légalement
              interdite ou limitée. En participant à cette Promotion entre le 2
              septembre 2020 et le 7 septembre 2020 (&#171;Période
              Promotionnelle&#187;), les participants peuvent gagner leur
              création produite, soit le t-shirt soit le sweat, au hasard. Le
              prix à gagner est spécifié à l’article 6 des présentes Conditions.
            </p>
            <p>
              Comment faire pour gagner ? Participez en personnalisant votre
              pièce Tommy préférée à l’aide de chutes de tissu et de motifs
              graphiques, puis partagez votre création. Les autres fans de Tommy
              sont conquis ? Votre pièce pourrait devenir réalité…
            </p>
            <p>
              Participez en choisissant votre parti pris créatif. Vous pouvez
              personnaliser votre pièce en ajoutant un logo, en choisissant vos
              finitions ainsi que la combinaison de couleurs des chutes de tissu
              utilisées. Lorsque vous êtes prêt(e), envoyez-nous votre création
              et laissez vos coordonnées et taille. Le gagnant sera tiré au sort
              le 14 septembre 2020. La participation et la création
              personnalisée ne donneront aucun droit de propriété intellectuelle
              au Participant. Tous les droits de propriété intellectuelle
              concernant les créations ainsi que le produit final sélectionné
              resteront ceux du Promoteur.
            </p>
            <p>
              Votre participation implique votre acceptation de ces Conditions.
            </p>
            <p>
              Les 8 personnes tirées au sort (chacun est un &#171; Gagnant
              &#187;) seront informées peu après le 14 septembre 2020 sur leur
              adresse email et les noms des gagnants seront publiés sur
              <a href="https://uk.tommy.com/flag">https://uk.tommy.com/flag</a>
            </p>
          </li>
          <li>
            <p>
              Cette Promotion est valable au cours de la Période Promotionnelle
              aux Pays-Bas, Allemagne, France et Royaume-Uni uniquement.
            </p>
          </li>
          <li>
            <p>
              Les Participants doivent être âgés d’au moins dix-huit (18) ans à
              la date de leur inscription à la Promotion. La présente Promotion
              n’est pas ouverte, directement ou indirectement, aux employés et à
              la famille proche des employés du Promoteur, de ses filiales ou de
              ses sociétés affiliées, ou à toute autre personne liée
              professionnellement à la Promotion. Des informations sur le
              Promoteur sont fournies à la clause 7 des présentes Conditions.
            </p>
          </li>
          <li>
            <p>
              Le Gagnant devra répondre au Promoteur dans les 48 heures suivant
              la notification pour confirmer l’acceptation du prix. Si aucune
              réponse n’est reçue par le Promoteur durant ce laps de temps, le
              Promoteur a le droit de désigner un autre Gagnant en appliquant
              les mêmes critères que ceux utilisés pour choisir le premier
              Gagnant.
            </p>
          </li>
          <li>
            <p>
              La Promotion sera invalide dans les pays où elle est légalement
              interdite ou limitée.
            </p>
          </li>
          <li>
            <p>
              Prix : Le prix qui pourra être gagné par participant sera composé
              soit d’un t-shirt de la collection d’une valeur de 50 euros soit
              d’un sweat à capuche d’une valeur de 100 euros. Le prix est non
              cessible et aucune substitution ou réclamation en espèces n’est
              autorisée, sauf à la seule discrétion du Promoteur ou tel que
              disposé dans les présentes Conditions. Aucun équivalent en espèces
              ne sera offert. Si, en cas de circonstances indépendantes de la
              volonté du Promoteur, celui-ci se voit dans l’incapacité de
              décerner le Prix entièrement ou en partie, le Promoteur se réserve
              le droit d’offrir un prix de substitution d’une valeur équivalente
              ou supérieure.
            </p>
          </li>
          <li>
            <p>
              Toute demande ou réclamation concernant la Promotion doit
              comporter le nom de la Promotion et doit être envoyée par e-mail
              à:
            </p>
            <p>
              Tommy Hilfiger Europe B.V. <br />
              Danzigerkade 165
              <br />
              1013 AP Amsterdam
              <br />
              Pays-Bas
              <br />
              e-mail: <a href="mailto:MYTOMMY@tommy.com">MYTOMMY@tommy.com</a>
            </p>
          </li>
          <li>
            <p>
              Le Promoteur décline toute responsabilité en cas d’erreurs
              techniques sur les réseaux de télécommunication, l’accès à
              Internet ou tout autre empêchement à la participation à cette
              Promotion.
            </p>
          </li>
          <li>
            <p>
              Le Promoteur se réserve le droit de sélectionner un gagnant
              alternatif s’il y a raisonnablement lieu de croire que le Gagnant
              sélectionné a contrevenu à l’une des dispositions des présentes.
              Tout Gagnant alternatif sera sélectionné selon les mêmes critères
              que ceux utilisés pour choisir le gagnant d’origine.
            </p>
          </li>
          <li>
            <p>
              Aucune renonciation de tout droit par le Promoteur ou par un
              Participant ne sera considérée comme une renonciation à tout autre
              droit qu’il peut avoir. Par exemple, si le Promoteur renonce à son
              droit de se plaindre d’une violation particulière des Conditions
              par un Participant, cela n’empêche par le Promoteur de se plaindre
              d’autres violations.
            </p>
          </li>
          <li>
            <p>
              Les données à caractère personnel collectées par le Promoteur
              auprès des Participants (par ex. le nom du Gagnant) seront
              utilisées uniquement en lien avec cette Promotion.
            </p>
          </li>
          <li>
            <p>
              Les Conditions sont exclusivement régies et interprétées
              conformément aux lois des Pays-Bas, à moins que des lois locales
              ne prévalent et tous les litiges seront soumis à la compétence
              exclusive des tribunaux compétents à Amsterdam, aux Pays-Bas, sauf
              si des lois locales prévalent.
            </p>
          </li>
        </ol>
      </div>
    </ArticleWrapper>
  )
}

export default TermsUK
