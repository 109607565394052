import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { navigate } from "@reach/router"
import { ReactComponent as YoutubeIcon } from "assets/images/iconBtnYoutube.svg"
import { ReactComponent as Shop } from "assets/images/iconShop.svg"
import { AnimatedContent, BadgeButton, Button, Text } from "components"
import { ButtonGroup } from "theme"
import {
  AuctionContent,
  DragContainer,
  DragRow,
  AuctionWrapper,
  HoodHolder,
  Slant,
  CollaboratorYoutubeButton,
} from "./styles"
import TopShirt from "assets/images/topShirt.png"
import TopHood from "assets/images/topHood.png"
import Collab1 from "assets/images/landingCollaborator1.jpg"
import Collab2 from "assets/images/landingCollaborator2.jpg"
import Collab4 from "assets/images/landingCollaborator4.jpg"
import Collab5 from "assets/images/landingCollaborator5.jpg"
import Collab6 from "assets/images/landingCollaborator6.jpg"
import Collab7 from "assets/images/landingCollaborator7.jpg"
import Collab8 from "assets/images/landingCollaborator8.jpg"
import Collab9 from "assets/images/landingCollaborator9.jpg"
import Collab10 from "assets/images/landingCollaborator10.jpg"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { startAnimCompleted, viewport } from "store/atoms"
import { useTranslation } from "react-i18next"
import DataLayer from "utils/dataLayer"
import { size } from "../../theme/breakpoints"

const collabs = [
  Collab1,
  Collab2,
  //  Collab3,  // Korean Englishmen - DELETED
  Collab4,
  Collab5,
  Collab6,
  Collab7,
  Collab8,
  Collab9,
  Collab10,
]
const hoods = [TopShirt, TopHood]

interface AuctionProps {
  scrollPct: number
  view: {
    width: number
    height: number
    isMobile: boolean
  }
}

const clickLink = (url: string) => {}

const Auction: React.FC<AuctionProps> = ({ scrollPct, view }) => {
  const setCompleted = useSetRecoilState(startAnimCompleted)
  const [buttonWidth, setButtonWidth] = useState(160)
  const [exploreWidth, setExploreWidth] = useState(7)
  const viewPort = useRecoilValue(viewport)

  const isMobile = viewPort.width < size.md
  const { t } = useTranslation()

  useEffect(() => {
    const btnMensText = t("buttonShopMens")
    const btnWomansText = t("buttonShopWomens")

    const explore = t("landingExplore")
    const more = t("landingMore")

    const btnWidth =
      btnMensText.length > btnWomansText.length
        ? btnMensText.length
        : btnWomansText.length
    setButtonWidth(btnWidth * 13)

    const exploreWidth =
      explore.length > more.length ? explore.length : more.length
    setExploreWidth(exploreWidth)
  }, [])

  const getCollabs = () => {
    return collabs.map((val, i) => {
      return (
        <motion.div
          className="collabImg"
          key={`collab${i}`}
          initial="inactive"
          variants={{
            active: {
              opacity: 1,
              marginLeft: 5,
              marginRight: 5,
              transition: {
                type: "spring",
                damping: 50,
                delay: 0.5,
              },
            },
            inactive: { opacity: 0, marginLeft: 100, marginRight: 100 },
          }}
        >
          <img src={val} />
        </motion.div>
      )
    })
  }

  const goto = (url: string) => {
    DataLayer.cta("View_artists")
    setCompleted(true)
    const anchor = document.createElement("a")
    anchor.href = t(url)
    // anchor.target = "_blank"
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }

  const clickYoutube = () => {
    goto("auctionYoutube")
  }

  const shopNow = (index: number) => {
    DataLayer.cta(index === 1 ? "Shop_Hoodie" : "Shop_Tshirt")
    goto(`shopNowLink${index === 1 ? "2" : ""}`)
  }

  const getHoods = () => {
    return hoods.map((val, i) => {
      return (
        <motion.div
          key={`hood${i}`}
          className="hood"
          initial="inactive"
          variants={{
            active: {
              opacity: 1,
              transition: {
                delay: (2 - i) * 0.2,
              },
            },
            inactive: {
              opacity: 0,
            },
          }}
        >
          <img src={val} alt="tommy top design" />
          <BadgeButton
            className="shop-button"
            textId="buttonShop"
            circle
            icon={Shop}
            onClick={() => shopNow(i)}
          />
        </motion.div>
      )
    })
  }

  return (
    <AuctionWrapper animate={scrollPct > 60 ? "active" : "inactive"}>
      <HoodHolder dimensions={view}>{getHoods()}</HoodHolder>
      <AuctionContent
        dimensions={view}
        exploreWidth={exploreWidth}
        buttonWidth={buttonWidth}
      >
        <AnimatedContent trigger={scrollPct > 70}>
          <Text textId="landingGiveBack" type="h5" />
        </AnimatedContent>
        <AnimatedContent trigger={scrollPct > 73}>
          <Text textId="landingGiveBackDescription" type="p" />
        </AnimatedContent>
        <DragContainer
          animate={scrollPct > 75 ? "active" : "inactive"}
          initial="inactive"
          variants={{
            active: {
              opacity: 1,
              x: 15,
              transition: {
                type: "spring",
                damping: 50,
                delay: 0.5,
              },
            },
            inactive: { opacity: 0, x: 30 },
          }}
        >
          <DragRow
            drag="x"
            dragConstraints={{
              left: view.isMobile
                ? -((collabs.length / 2.4) * 200)
                : -((collabs.length / 1.6) * 200),
              right: 0,
            }}
            dragElastic
          >
            {getCollabs()}
          </DragRow>
        </DragContainer>
        <AnimatedContent trigger={scrollPct > 82}>
          <Text textId="auctionCollaborators" type="h2" />
        </AnimatedContent>
        <AnimatedContent trigger={scrollPct > 88}>
          <Text textId="landingCollaboratorsDescription" type="p" />
          <CollaboratorYoutubeButton onClick={clickYoutube}>
            <YoutubeIcon />
            <Text textId={t(`auctionCollabYoutubeButton`)} type="p" />
          </CollaboratorYoutubeButton>
        </AnimatedContent>
        <AnimatedContent
          className="exploreAnim"
          trigger={scrollPct > 93}
          from="-50%"
          direction="x"
        >
          <Text textId="landingExplore" type="h1" />
          <Text textId="landingMore" type="h1" />
        </AnimatedContent>
        <AnimatedContent
          className="moreAnim"
          trigger={scrollPct > 93}
          from="50%"
          direction="x"
        >
          <Text textId="landingMore" type="h1" />
        </AnimatedContent>
        <ButtonGroup className="buttonGroup">
          <AnimatedContent trigger={scrollPct > 94} delay={0.4}>
            <Button
              textId="buttonShopMens"
              colour="white"
              onClick={() => {
                DataLayer.event("Shop_mens", "click_cta", "ecommerce")
                goto("shopMensLink")
              }}
              minWidth={buttonWidth}
            />
          </AnimatedContent>
          <AnimatedContent trigger={scrollPct > 94} delay={0.6}>
            <Button
              textId="buttonShopWomens"
              colour="white"
              onClick={() => {
                DataLayer.event("Shop_womens", "click_cta", "ecommerce")
                goto("shopWomensLink")
              }}
              minWidth={buttonWidth}
            />
          </AnimatedContent>
        </ButtonGroup>
      </AuctionContent>

      <Slant />
    </AuctionWrapper>
  )
}

export default Auction
