import styled, { keyframes } from "styled-components/macro"
import { colours } from "theme"

interface SignupWrapperProps {
  dimensions: {
    width: number
    height: number
  }
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const SignupWrapper = styled.form<SignupWrapperProps>`
  position: relative;
  background: ${colours.primary};
  overflow: hidden;
  flex: auto;
  height: 100%;
  padding: 4rem 1rem;

  > div {
    position: relative;
    z-index: 1;
  }

  h3 {
    width: 250px;
    line-height: 1;
    font-size: 2rem;
  }

  h3,
  p {
    color: white;
    margin: 1rem 0 2rem;
  }

  input[type="text"] {
    height: 3rem;
    padding: 0 1rem;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    border: none;
    &::placeholder {
      opacity: 0.5;
    }
  }

  .text-terms {
    font-size: 0.75rem;
    padding: 0 1rem;
    margin: 0 0 4rem 2.2rem;
    text-align: left;
  }

  .inline-links {
    display: block;
    a {
      text-decoration: underline !important;
      display: initial;
      color: ${colours.white};
      margin: unset;
      :hover {
        color: unset;
      }
      &:active,
      &:visited {
        color: ${colours.white};
      }
    }
  }

  .size_select {
    margin-bottom: 20px;
  }

  .button {
    margin: 0.5rem auto 0.5rem;
    width: 70%;
  }

  .flare1 {
    position: absolute;
    left: -200px;
    top: -200px;
    opacity: 0;
    animation: ${fadeIn} forwards 4s 1.5s;
  }

  .return-home {
    font-family: "futuraBold", futura, arial, san-serif;
    font-size: 0.7rem;
    letter-spacing: 1.5px;
    color: ${colours.white};
    opacity: 0.8;
    text-decoration: underline;
    text-transform: uppercase;
    margin: 0.5rem auto 1rem;
    &:hover {
      color: ${colours.white};
      opacity: 1;
    }
  }

  .checkbox-wrapper {
    justify-content: flex-start;
    margin: 1rem 0 2rem;
  }

  @media (min-width: 992px) {
    padding: 4rem 30%;
    text-align: center;
    h3 {
      width: 100%;
      margin: 0;
    }

    .text-terms,
    .checkbox-wrapper {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .text-terms {
      margin-left: 2.2rem;
    }

    .button {
      width: 50%;
    }

    .flare1 {
      position: absolute;
      width: 1000px;
      height: 1000px;
      left: -500px;
      top: -500px;
      opacity: 0;
      animation: ${fadeIn} forwards 4s 1.5s;
    }
  }
`
