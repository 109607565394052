import React, { ReactElement } from "react"
import { Text } from "components"
import { InlineButtonWrapper } from "./styles"

interface InlineButtonProps {
  onClick: () => void
  icon: ReactElement
  textId: string
}

const InlineButton: React.FC<InlineButtonProps> = ({
  icon,
  onClick,
  textId,
}) => {
  return (
    <InlineButtonWrapper className="inline-button" onClick={onClick}>
      {icon}
      <Text textId={textId} type="span" />
    </InlineButtonWrapper>
  )
}

export default InlineButton
