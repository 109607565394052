import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import Config from "../flag-config/";

const publicPath = process.env.REACT_APP_TRANSLATIONS_PATH || `${process.env.PUBLIC_URL}/translations`;
const locale = Config.defaultLanguage;
const country = Config.defaultCountryCode;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: `${locale}-${country}`,
    fallbackLng: `${locale}-${country}`,
    backend: {
      loadPath: `${publicPath}/{{lng}}.json`,
      allowMultiLoading: false,
      requestOptions: { 
        cache: 'no-store' //ignore cache
      }
    },
    load: 'currentOnly',
    keySeparator: false,

    // debug: true,

    interpolation: {
      escapeValue: false,
    },
    
  })

export default i18n
