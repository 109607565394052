import styled, {keyframes} from "styled-components"

import SearchIconImage from 'assets/images/searchIcon.svg';

const expandForm = keyframes`
  0%   { height: 0 }
  100% { height: 40px }
`

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row !important;
  padding: 5px 20px;
  top: 50px;
  width: 100%;
  z-index: 10;
  background-color: white;
  transition-property: height;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  height: auto;
  overflow: visible;
`

export const SearchField = styled.input`
  &&&& {
    background-color: #f4f4f4;
    color: #00174f;
    display: block;
    width: calc(100% - 40px);
    position: relative;
    border: none;
    outline: none;
    font-family: Futura,Calibri,sans-serif;
    font-weight: 400;
    font-size: .95em;
    height: 40px;
    line-height: normal;
    text-transform: capitalize;
    box-sizing: border-box;
    padding: 0 10px;
    float: left;
    user-select: text !important;
    box-shadow: none;
    border-radius: 0;
    
    animation: ${expandForm} 500ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
`

export const SearchIcon = styled.button`
  background-image: url(${SearchIconImage});
  border: none;
  background-color: #f4f4f4;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  right: 0;
  top: 0;
  height: 40px;
  width: 40px;
  transition: opacity .2s ease-in-out;
  clear: both;
  
  animation: ${expandForm} 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`