import styled from "styled-components"

import { colours, breakpoints } from "theme"
import iconArrowRight from "assets/images/icon-arrow-right.svg"

const NavigationItemsWrapper = styled.nav`
  position: absolute;
  top: 100%;
  background: ${colours.white};
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  transform-origin: top left;
  transform: translate(-100%, 0);
  transition: transform 0.3s ease-in-out;
  height: calc(100vh - 50px);

  ${breakpoints.md`
    color: white;
    background: rgba(0,23,79,.9);
    height: 60px;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    transform: none;
    transition: none;
  `}

  &[aria-hidden="true"] {
    transform: translate(0, 0);
    overflow-x: hidden;
    overflow-y: auto;

    ul.category {
      display: block;
    }
  }

  ul.items {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    ${breakpoints.md`
      flex-direction: initial !important;
      padding: 0;
      &:hover {
        color: rgba(255, 255, 255, 0.5);

        ul.category {
          transform: translate(0, 0);
          transition: transform 0.3s ease-in-out;
          transform: scaleY(1);
        }
      }
    `}

    li.item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      background-color: ${colours.grey2};
      height: 90px;

      ${breakpoints.md`
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        height: auto;
        background-color: transparent;
        margin-bottom: 0;

        &:hover {
          ul.category {
            z-index: 9;
            transition: transform 0.3s ease-in-out;
            transform: scaleY(1);
            opacity: 1;

            li.list {
              opacity: 1;
              transition: opacity 0.3s ease-in-out 200ms;
            }
          }
        }
      `}

      > a {
        font-family: "Futura", Calibri, sans-serif;
        font-size: 1rem;
        color: inherit;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-decoration: none;
        display: flex;
        height: 100%;
        padding: 0 32px;
        flex: 0 0 calc(100% - 64px);
        flex-direction: initial !important;
        align-items: center;

        ${breakpoints.md`
          font-size: 0.75rem;
          align-items: center;
          justify-content: center;
          height: 100%;
        `}

        &:hover {
          color: #cc0c2f;
          text-decoration: none;
          opacity: 1;
          transition: opacity 250ms ease-in-out;
        }

        &.active + ul.category {
          transform: translate(0, 0);
        }

        .icon-arrow-right {
          background: url(${iconArrowRight}) no-repeat;
          background-position: 50%;
          background-size: 10px 14px;
          margin-left: 10px;
          width: 12px;
          height: 14px;
        }
      }
    }
  }

  ul.category {
    display: none;
    width: 100%;
    height: calc(100vh - 50px);
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
    background: ${colours.white};
    transform-origin: top;
    transform: translate(200%, 0);
    transition: transform 0.3s ease-in-out, opacity 100ms ease-in-out 450ms;

    li {
      a {
        font-size: 12px;
        font-family: "Futura", Calibri, sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 1.2rem;
        flex-direction: initial !important;
        
        ${breakpoints.md`
          font-weight: 400;
          font-size: 15px;
          color: white;
        `}
      }
    }

    ${breakpoints.md`
      height: auto;
      padding: 0;
      top: 60px;
      display: flex;
      justify-content: center;
      transform: scaleY(0);
      opacity: 0;
      margin-bottom: 60px;
      background: rgba(0,23,79,.9);
    `}

      &[aria-expanded="true"] {
        transform: translate(100%, 0);
      }

      button {
        display: flex;
        align-items: center;
        border: 0;
        padding: 0;
        background: transparent;
        font-family: "Futura", Calibri, sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.8px;
        line-height: 20px;
        text-transform: uppercase;
        color: ${colours.primary};

        .icon-arrow-left {
          background: url(${iconArrowRight}) no-repeat;
          background-position: 50%;
          background-size: 8px 10px;
          margin: 0 5px 0 0;
          width: 12px;
          height: 12px;
          display: block;
          transform: rotate(180deg);
        }
      }

      h3 {
        a{
          justify-content: left;
          font-weight: bold;
          font-size: 12px;
        }
        text-align: left;
      }

      h4 {
        font-size: 1rem;
        margin: 20px 0 10px;
        letter-spacing: .13333rem;
      }
    }
  }
`

export default NavigationItemsWrapper
