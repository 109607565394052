import styled from "styled-components/macro"
import { colours, layout } from "theme"
import { darken } from "polished"

interface HotSpotProps {
  position: { x: number; y: number }
}
interface EditorWrapperProps {
  dimensions: {
    width: number
    height: number
  }
}

export const EditorWrapper = styled.div<EditorWrapperProps>`
  align-items: center;
  position: relative;

  flex: none;
  background: ${colours.background};

  > .inline-button {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 20;
    @media (max-width: 375px) {
      top: 2rem;
    }
  }

  #icon-360 {
    position: absolute;
    z-index: 30;
    bottom: 3rem;
    left: 32.5%;
    margin-left: -32px;
  }

  .scene-wrapper {
    background: ${colours.grey1};
  }

  @media (max-width: 600px) and (max-height: 811px) {
    .scene-wrapper {
      padding-top: 1rem;
      min-height: ${(props) => props.dimensions.height * 0.55}px !important;
      height: ${(props) => props.dimensions.height * 0.55}px !important;
      flex: none;
      > div {
        position: relative;
        margin: auto;
        height: ${(props) => props.dimensions.height * 0.55}px !important;
        width: ${(props) => props.dimensions.height * 0.55}px !important;
        left: 50%;
        margin-left: -${(props) => (props.dimensions.height * 0.55) / 2}px;
      }
    }
  }

  .textureCanvas {
    position: absolute;
    top: -35rem;
    left: -20rem;
    opacity: 1;
    pointer-events: none;
    transform: scale(0.4) translateY(-100px);
    z-index: 0;
    @media (max-width: 800px) {
      transform: scale(0.4) translateY(-700px);
    }
  }

  @media (max-width: 991px) {
    min-height: ${(props) => props.dimensions.height * 0.9}px;
    #icon-360 {
      display: none;
    }
  }

  @media (min-width: 992px) {
    background: ${colours.grey1};
    flex-direction: row !important;
    flex: none;
    height: 90vh;
    min-height: 45rem;

    .timeline {
      position: absolute;
      top: 0;
      right: 0;
      width: 35%;
      &:before {
        background: ${colours.grey4};
      }
      p {
        color: ${colours.grey5};
      }
      .checkpoint {
        &:after {
          background: ${colours.desktopStepsBackground};
          border-color: ${colours.desktopStepsBackground};
          box-shadow: inset 0 0 0 2px ${colours.grey5};
        }
        &.active {
          p {
            color: ${colours.secondary2};
          }
          &:after {
            background: ${colours.secondary2};
            box-shadow: none;
          }
        }
        &.visited {
          p {
            color: ${colours.primary};
          }
          &:after {
            box-shadow: inset 0 0 0 2px ${colours.primary};
          }
        }
      }
    }

    .scene-wrapper {
      width: ${(props) => props.dimensions.height * 0.9}px;
      background: none;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 32.5%;
      margin-left: -${(props) => (props.dimensions.height * 0.9) / 2}px;
      bottom: 5%;
      height: ${(props) => props.dimensions.height * 0.9}px;
    }
  }
`

export const Flip = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 15;
  top: 8rem;
  right: 8%;
  width: 2.8rem;
  height: 2.8rem;
  background: ${colours.primary};
  border-radius: 50%;
  transition: 0.2s ease-out transform;
  cursor: pointer;

  &:active {
    transform: scale(0.7);
  }
  svg {
    stroke: "#ffffff" !important;
    path: {
      stroke: "#ffffff" !important;
    }
    width: 1.5rem;
    opacity: 1;
  }

  @media (max-width: 600px) {
    top: 1.5rem;
    right: 1rem;
  }

  @media (min-width: 992px) {
    top: 1.5rem;
    right: 38%;
  }
`

export const EditorTabs = styled.div`
  flex-direction: row !important;

  .button {
    margin: 2rem 0.25rem;
  }
`

export const StepsContainer = styled.div`
  position: relative;
  background: ${colours.background};
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  width: 100%;
  flex: 1;
  overflow: hidden;

  h5 {
    font-size: 1.125rem;
  }
  h6 {
    font-size: 0.8rem;
  }

  > div {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex: none;
  }
  @media (min-width: 992px) {
    background: ${colours.desktopStepsBackground};
    position: absolute;
    justify-content: center;
    width: 35%;
    top: 0;
    left: 65%;
    bottom: 0;
    height: 100%;
    h5 {
      text-align: center;
      margin-bottom: 2.5rem !important;
    }
    > div {
      position: relative;
      height: 100%;
      justify-content: center;
    }

    .row {
      &:last-child {
        margin-top: 4rem !important;
        .inline-button {
          left: ${layout.gutters} !important;
        }
      }
    }
  }
`
