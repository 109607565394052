import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"

interface TermsProps {
  onBack: () => void
}

const TermsUK: React.FC<TermsProps> = ({ onBack }) => {
  return (
    <ArticleWrapper>
      <span className="back" onClick={onBack}>
        Back
      </span>
      <div className="container">
        <h1>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h1>
        <ol>
          <li>
            <p>
              Zusammen kreieren wir Neues“ (das „<b>Gewinnspiel</b>“) wird von{" "}
              <b>Tommy Hilfiger Europe B.V.</b> organisiert, einer privaten
              Gesellschaft mit beschränkter Haftung, die den Gesetzen der
              Niederlande unterliegt (der „<b>Veranstalter</b>“), und wird durch
              diese allgemeinen Geschäftsbedingungen (die „<b>Bedingungen</b>“)
              bestimmt. Mit der Teilnahme an dem Gewinnspiel stimmt der
              Verbraucher (nachfolgend „<b>Teilnehmer</b>“ genannt) zu, dass er
              die Bedingungen gelesen und verstanden hat und diese akzeptiert.
              Diese Bedingungen gelten in den Niederlanden, Deutschland,
              Frankreich und im Vereinigten Königreich. Teilnahmeberechtigt sind
              ausschließlich Personen, die in den Niederlanden, Deutschland,
              Frankreich oder im Vereinigten Königreich wohnhaft sind. Das
              Gewinnspiel ist nichtig, wo es verboten oder gesetzlich
              eingeschränkt ist. Durch die Teilnahme des Gewinnspiels zwischen
              dem 2. September 2020 und 7. September 2020 (der
              „Gewinnspielzeit“), haben die Teilnehmer die Chance, das finale
              Designprodukt, entweder das T-Shirt oder den Hoodie, zu gewinnen.
              Der Preis, der gewonnen werden kann, ist unter Klausel 6 in den
              Bedingungen weiter spezifiziert.
            </p>
            <p>
              Was musst du tun, um zu gewinnen? Gestalte eines unserer
              Tommy-Lieblingsstücke individuell mit Stoffresten, füge grafische
              Details hinzu und reiche dein Design ein. Dein Produkt könnte zum
              Leben erweckt und von Tommy-Fans getragen werden.
            </p>
            <p>
              Du nimmst teil, indem du deine bevorzugten Designoptionen
              einreichst. Du kannst mit Branding und Logodetails, Auswählen der
              Spleißrichtung und Farbkombinationen der Stoffreste dein Design
              individuell gestalten. Wenn du fertig bist, reiche dein Design ein
              und hinterlasse deine E-Mail-Adresse und Größe. Der Gewinner wird
              nach Zufallsprinzip am 14. September 2020 ausgewählt. Durch die
              Teilnahme und die Einreichung der bevorzugten Designoptionen
              entstehen für den Teilnehmer keine Rechte an geistigem Eigentum.
              Alle geistigen Eigentumsrechte an den Designoptionen und Grafiken,
              einschließlich des endgültigen Designprodukts, verbleiben beim
              Veranstalter.
            </p>
            <p>Durch deine Teilnahme akzeptierst du diese Bedingungen.</p>
            <p>
              Die 8 Gewinner (jeder ein „Gewinner“) werden kurz nach dem 14.
              September 2020 per E-Mail darüber informiert, gewonnen zu haben.
              Die Namen des/der Gewinner werden auf
              <a href="https://uk.tommy.com/flag">
                https://uk.tommy.com/flag
              </a>{" "}
              veröffentlicht.
            </p>
          </li>
          <li>
            <p>
              Zum Zeitpunkt der Teilnahme am Gewinnspiel müssen die Teilnehmer
              mindestens achtzehn (18) Jahre alt sein. Mitarbeiter und
              Angehörige von Mitarbeitern des Veranstalters, seiner
              Tochterunternehmen oder verbundener Unternehmen sind weder direkt
              noch indirekt zur Teilnahme an diesem Gewinnspiel berechtigt.
              Details zum Veranstalter sind unter Klausel 7 dieser Bedingungen
              angegeben.
            </p>
          </li>
          <li>
            <p>
              Der Gewinner muss dem Veranstalter innerhalb von 48 Stunden nach
              Erhalt der ersten Benachrichtigung antworten und bestätigen, dass
              er/sie den Preis annimmt. Wenn diese Antwort nicht innerhalb des
              angegebenen Zeitrahmens beim Veranstalter eintrifft, hat dieser
              das Recht, einen anderen Gewinner auf Basis derselben Kriterien
              auszuwählen, mit denen der ursprüngliche Gewinner bestimmt wurde.
            </p>
          </li>
          <li>
            <p>
              Das Gewinnspiel ist nichtig, wo es verboten oder gesetzlich
              eingeschränkt ist.
            </p>
          </li>
          <li>
            <p>
              Preis: Der Preis, der durch die Teilnahme gewonnen werden kann,
              umfasst entweder 50 EURO Verkaufswert für das T-Shirt oder 100
              EURO Verkaufswert für den Hoodie. Der Preis ist nicht übertragbar
              und kann nicht in Bargeld ausgezahlt oder ersetzt werden, außer
              dies liegt im eigenen Ermessen des Veranstalters oder wird hier
              explizit dargelegt. Es werden keinerlei Auszahlungsalternativen
              für den Preis geboten. Wenn der Veranstalter aufgrund von
              Umständen, die vom Veranstalter nicht beeinflussbar sind, den
              Preis oder Teile davon nicht zur Verfügung stellen kann, behält
              sich der Veranstalter das Recht vor, einen gleich- oder
              höherwertigen Ersatzpreis zu verleihen.
            </p>
          </li>
          <li>
            <p>
              Jegliche Anfragen und Beschwerden im Hinblick auf das Gewinnspiel
              sollten den Namen des Gewinnspiels vermerken und per E-Mail an
              folgende Adresse versandt werden:
            </p>
            <p>
              Tommy Hilfiger Europe B.V. <br />
              Danzigerkade 165
              <br />
              1013 AP Amsterdam
              <br />
              Niederlande
              <br />
              e-mail: <a href="mailto:MYTOMMY@tommy.com">MYTOMMY@tommy.com</a>
            </p>
          </li>
          <li>
            <p>
              Der Veranstalter ist nicht verantwortlich für technische Fehler im
              Hinblick auf Telekommunikationsnetzwerke und Internetzugang oder
              andere Hindernisse, die die Teilnahme an diesem Gewinnspiel
              hindern.
            </p>
          </li>
          <li>
            <p>
              Der Veranstalter behält sich das Recht vor, einen alternativen
              Gewinner auszuwählen, wenn Grund zur Annahme besteht, dass der
              ausgewählte Gewinner gegen eine Bedingung verstoßen hat. Ein
              alternativer Gewinner wird nach denselben Kriterien ausgewählt,
              mit denen der ursprüngliche Gewinner bestimmt wurde.
            </p>
          </li>
          <li>
            <p>
              Keine Verzichtserklärung jeglicher Rechte von Seiten des
              Veranstalters oder eines Teilnehmers stellt einen Verzicht auf
              andere Rechte dar. Wenn zum Beispiel der Veranstalter auf sein
              Recht verzichtet, gegen eine besondere Verletzung der Bedingungen
              durch einen Teilnehmer zu klagen, hält dies den Veranstalter nicht
              davon ab, gegen andere Verletzungen zu klagen.
            </p>
          </li>
          <li>
            <p>
              Jegliche persönliche Daten, die zu den Teilnehmern gespeichert
              werden (z. B. Name des Gewinners), werden vom Veranstalter
              ausschließlich in Verbindung mit diesem Gewinnspiel verwendet.
            </p>
          </li>
          <li>
            <p>
              Die Bedingungen unterliegen ausschließlich dem Recht der
              Niederlande und werden im Einklang mit diesem ausgelegt, es sei
              denn zwingende Bestimmungen des nationalen Rechts am Sitz des
              Verbrauchers schließen dies aus. Etwaige Streitfälle unterliegen
              der ausschließlichen Gerichtszuständigkeit der zuständigen
              Gerichte in Amsterdam, Niederlande, es sei denn zwingende
              Bestimmungen des nationalen Rechts am Sitz des Verbrauchers
              schließen dies aus.
            </p>
          </li>
        </ol>
      </div>
    </ArticleWrapper>
  )
}

export default TermsUK
