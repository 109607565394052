import styled from "styled-components"

import { colours, breakpoints } from "theme"
import iconArrowRight from "assets/images/icon-arrow-right.svg"

const NavigationItemCategoryWrapper = styled.div`
  ${breakpoints.md`
    flex-direction: row !important;;
    margin-bottom: 1rem;
  `}

  .list {
    display: flex;
    flex-direction: column;
    color: ${colours.white};
    transition: opacity 100ms ease-in-out;
    min-width: 175px;

    ${breakpoints.md`
      opacity: 0;
      padding: 0 1rem;

      &--two-column {
        min-width: 350px;

        .list {
          &__wrapper > div {
            flex-wrap: wrap;
            max-height: 300px;
          }

          &__link{
            width: 50%;
            padding-right: 7px;
            word-wrap: break-word;
          }
        }
      }
    `}

    ul.list__wrapper {
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: calc(100vh - 50px);
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px;
      box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      transform: translate(100%, 0);
      transition: transform 0.3s ease-in-out;

      &[aria-expanded="true"] {
        transform: translate(0, 0);
        overflow-x: hidden;
        overflow-y: scroll;
      }

      ${breakpoints.md`
        background: transparent;
        opacity: 1;
        padding: 0;
        width: auto;
        height: auto;
        position: relative;
        transform: none;
        transition: none;
      `}
    }

    h3 {
      margin: 0;

      ${breakpoints.md`
                margin: 1rem 0;
              `}
    }

    a {
      display: flex;
      font-size: 1rem;
      color: ${colours.primary};
      letter-spacing: 2px;
      text-decoration: none;
      align-items: center;
      flex-direction: initial;
      padding-left: 2rem;
      margin-bottom: 10px;
      background-color: ${colours.grey2};
      height: 90px;

      ${breakpoints.md`
                justify-content: center;
                font-size: 0.75rem;
                margin-bottom: 0;
                background-color: transparent;
                height: auto;
                padding: 0;
                align-items: normal;
                flex-direction: column;
              `}

      &:hover {
        color: #cc0c2f;
      }

      .icon-arrow-right {
        background: url(${iconArrowRight}) no-repeat;
        background-position: 50%;
        background-size: 10px 14px;
        margin-left: 10px;
        width: 12px;
        height: 14px;
      }
    }
  }
`

export default NavigationItemCategoryWrapper
