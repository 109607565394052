import styled from "styled-components/macro"
import { colours } from "theme"
import { lighten } from "polished"

interface NextButtonProps {
  big: boolean
  disabled?: boolean
}

export const NextButtonWrapper = styled.div<NextButtonProps>`
  position: relative;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.big ? "4.2rem" : "3.15rem")};
  width: ${(props) => (props.big ? "4.2rem" : "3.15rem")};
  border-radius: 50%;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};

  svg {
    transform: rotate(-90deg) scale(${(props) => (props.big ? 1 : 0.8)});
  }

  transition: 0.2s ease-out;
  background: ${colours.secondary2};
  cursor: pointer;
  &:active {
    transform: scale(0.8);
  }
  &:hover {
    background: ${lighten(0.15, colours.secondary2)};
  }
  div {
    font-family: "futuraBold", futura, arial, sans-serif;
    position: absolute;
    top: 120%;
    font-size: 0.7rem;
    white-space: nowrap;
    text-transform: uppercase;
    color: ${colours.secondary2};
  }
`
