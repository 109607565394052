import React, { MouseEvent } from "react"
import { EditIcon, AddorEditWrapper } from "./styles"
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion"
import { ReactComponent as Tick } from "assets/images/tick.svg"

interface AddorEditProps {
  onClick: (e: MouseEvent) => void
  className?: string | null
  size: number
}

const badgeVariants = {
  active: {
    scale: 1,
    transition: {
      type: "spring",
      damping: 20,
      mass: 1,
      stiffness: 500,
    },
  },
  inactive: {
    scale: 0,
  },
}

const editVariants = {
  pressed: (active: boolean) => ({ pathLength: active ? 0.85 : 0.2 }),
  checked: {
    pathLength: 1,
    transition: {
      delay: 0.4,
    },
  },
  unchecked: { pathLength: 0 },
}

const AddorEdit: React.FC<AddorEditProps> = ({
  children,
  className,
  onClick,
  size,
}) => {
  const pathLength = useMotionValue(0)
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1])

  return (
    <AddorEditWrapper
      className={`${className} add-or-edit-button`}
      onClick={onClick}
      size={size}
    >
      {children}
      {className && (
        <EditIcon
          animate="active"
          initial="inactive"
          exit="inactive"
          variants={badgeVariants}
        >
          <motion.svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            initial="unchecked"
            animate={className ? "checked" : "unchecked"}
            exit="unchecked"
          >
            <g
              id="edit"
              transform="translate(10.091453, 10.566327) rotate(15.000000) translate(-10.091453, -10.566327) translate(2.591453, -0.433673)"
            >
              <motion.path
                d="M5.21343114,18.4698908 L0.653890927,21.1522086 L0.962927541,15.8705829 L10.5050581,0.249732122 C10.6087791,0.0818606179 10.828535,0.0291549301 10.9971164,0.13171814 L14.6375477,2.3579819 C14.8054192,2.46170292 14.8581249,2.68145883 14.7555617,2.8500402 L5.21343114,18.4698908 Z"
                id="Path_575"
                stroke="#FFFFFF"
                stroke-width="1.00011849"
                stroke-linecap="round"
                stroke-linejoin="round"
                style={{ pathLength, opacity, strokeWidth: 2 }}
                custom={className}
                variants={editVariants}
              ></motion.path>
              <motion.path
                d="M7.93814262,4.42601639 C7.70286027,4.28139656 7.62936377,3.97342498 7.77398361,3.73814262 C7.91860344,3.50286027 8.22657502,3.42936377 8.46185738,3.57398361 L12.3765913,5.98023361 C12.6118736,6.12485344 12.6853701,6.43282502 12.5407503,6.66810738 C12.3961304,6.90338973 12.0881589,6.97688623 11.8528765,6.83226639 L7.93814262,4.42601639 Z"
                id="Line_33"
                fill="#FFFFFF"
                fill-rule="nonzero"
                style={{ pathLength, opacity, strokeWidth: 2 }}
                custom={className}
                variants={editVariants}
              ></motion.path>
              <motion.path
                d="M0.738245044,17.4260793 C0.502927929,17.2815161 0.429357403,16.9735622 0.573920673,16.738245 C0.718483944,16.5029279 1.02643784,16.4293574 1.26175496,16.5739207 L3.97842976,18.242866 C4.21374688,18.3874293 4.2873174,18.6953832 4.14275413,18.9307003 C3.99819086,19.1660174 3.69023696,19.2395879 3.45491985,19.0950246 L0.738245044,17.4260793 Z"
                id="Line_34"
                fill="#FFFFFF"
                fill-rule="nonzero"
                style={{ pathLength, opacity, strokeWidth: 2 }}
                custom={className}
                variants={editVariants}
              ></motion.path>
            </g>
          </motion.svg>
        </EditIcon>
      )}
    </AddorEditWrapper>
  )
}

export default AddorEdit
