import React from 'react';
import { useRecoilState } from 'recoil';

import { isOpen } from '../../../store/atoms';
import OverlayWrapper from './styles';

const Overlay = () => {
  const [open, setOpen] = useRecoilState(isOpen);

  const language = ['English (current)', 'German', 'Spanish', 'French', 'Italian', 'Russian', 'Polish', 'Dutch'];

  const selectHandler = () => {
    setOpen(!open);
  };

  return (
    <OverlayWrapper className={open ? 'open' : ''}>
      <h1 className="title">Select a language</h1>
      <button type="button" className="close-btn" onClick={() => setOpen(!open)}>Close</button>
      <div className="content">
        {language.map((item: any, i:number) => (<button key={i} className="item" onClick={selectHandler}>{item}</button>))}
      </div>
    </OverlayWrapper>
  );
};

export default Overlay;
