import styled from "styled-components"

import SearchIconImage from 'assets/images/searchIcon.svg';
import WishlistIconImage from 'assets/images/wishlistIcon.svg';
import BasketIconImage from 'assets/images/basketIcon.svg';
import {breakpoints} from "../../../theme";

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 13px;
  right: 0;
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: baseline;
  max-width: calc(50% - 150px);
  
  ${breakpoints.md`
    right: 20px;
    top: 4px;
  `}
`

export const SearchField = styled.input`
  text-transform: capitalize;
  width: min(100%, 80px);
  border: none;
  border-bottom: 2px solid #e5e5e5;
  height: 22px;
  padding: 0 3px 0 10px;
  transition: width .4s ease-in-out,max-width .4s ease-in-out;
  background-color: #fff;
  color: #00174f;
  
  :focus {
    width: min(100%, 180px);
  }
`

export const SignIn = styled.a`
  top: -4px;
  position: relative;
  font-family: Futura,Calibri,sans-serif;
  font-weight: 700;
  padding: 0 15px;
  color: #00174f;
  font-size: 10.5px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 60px;
  width: auto;
  text-decoration: none;
  outline: none;
  white-space: nowrap;
  flex-shrink: 0;
`

const iconButton = `
  border: none;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 10px;
  cursor: pointer;
  outline: none;
  flex-shrink: 0;
`

export const SearchIcon = styled.button`
  background-image: url(${SearchIconImage});
  width: 17px;
  height: 17px;
  ${iconButton}
`

export const WishlistIcon = styled.a`
  background-image: url(${WishlistIconImage});
  width: 20px;
  height: 20px;
  ${iconButton}
`

export const BasketIcon = styled.a`
  background-image: url(${BasketIconImage});
  width: 17px;
  height: 17px;
  ${iconButton}
`