import styled, { keyframes } from "styled-components/macro"
import { motion } from "framer-motion"
import { colours } from "theme"
import { lighten } from "polished"

interface AuctionWrapperProps {
  dimensions: {
    width: number
    height: number
  }
  scrollPos: number
  tunedWidth: number
}

interface AuctionHeadProps {
  scrollPos: number
}

interface AnimatedPicsWrapper {
  width: number
  scrollPos: number
}

interface AnimatedImgProps {
  zIndex: number
}

const movingAnim = keyframes`
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeInHalf = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
`

export const AuctionWrapper = styled.div<AuctionWrapperProps>`
  position: relative;
  background: ${colours.primary};
  overflow: hidden;
  flex: none;
  padding-top: ${(props) => (props.scrollPos > 2 ? "40vh" : "100vh")};
  min-height: ${(props) => (props.scrollPos > 2 ? "100vh" : "140vh")};
  transition: padding-top 0.5s;

  .inline-button {
    position: absolute;
    z-index: 100;
    top: 1rem;
    left: 1rem;
    span {
      color: white;
    }

    svg {
      path {
        stroke: white;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .together {
    position: absolute;
    z-index: 40;
    top: ${(props) => props.dimensions.height / 3.7}px;
    margin-left: 10%;
    opacity: 0;
    color: white;
    font-size: ${(props) => props.dimensions.width * 0.15}px;
    animation: ${movingAnim} forwards 4s 1.5s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .flare1 {
    left: 0;
    right: 0;
    top: ${(props) => props.dimensions.height - 300}px;
    opacity: 0;
    animation: ${fadeIn} forwards 4s 1.5s;
  }

  .tuned {
    h1 {
      font-size: ${(props) =>
        props.dimensions.width * props.tunedWidth * 0.0009}rem;
    }
  }

  @media (min-width: 376px) {
    .together {
      font-size: 3rem;
      width: 20rem;
    }
  }
  @media (min-width: 992px) {
    .together {
      font-size: 5rem;
      width: 25rem;
      top: ${(props) => props.dimensions.height / 2.7}px;
    }

    .tuned {
      bottom: -1.5rem;
      h1 {
        font-size: 8rem;
      }
    }
  }

  @media (min-width: 1700px) {
    .together {
      font-size: 7rem;
      width: 35rem;
    }
  }

  @media (min-width: 600px) {
    .flare1 {
      left: initial;
      right: -400px;
      top: 2%;
      opacity: 0;
      width: 800px;
      height: 800px;
      animation: ${fadeIn} forwards 4s 1.5s;
    }
  }
`

export const AuctionHead = styled.div<AuctionHeadProps>`
  position: absolute;
  min-height: ${(props) => (props.scrollPos > 2 ? "140vh" : "240vh")};
  top: 0;
  left: 0;
  right: 0;
`

export const AnimatedPicsWrapper = styled.div<AnimatedPicsWrapper>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 30;
  > div {
    position: absolute;
  }

  .arrowHolder {
    width: 100%;
  }

  .arrow {
    position: absolute;
    z-index: 100;
    top: 88vh;
    left: 50%;
    margin-left: -0.5rem;
    opacity: 0;
    animation: ${fadeIn} forwards 4s 1s;
    @media (min-width: 600px) {
      top: 70vh;
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    transform: translateX(-50%);
  }
  @media (min-width: 376px) {
    img {
      width: 80%;
    }
  }
  @media (min-width: 600px) {
    img {
      width: 70%;
    }
  }
  @media (min-width: 800px) {
    img {
      width: 50%;
    }
  }
  @media (min-width: 1000px) {
    img {
      width: 40%;
    }
  }
  @media (min-width: 1200px) {
    img {
      width: 35%;
    }
  }
`

export const Collaborators = styled(motion.div)`
  padding: 10rem 1.25rem 3rem;
  margin: 1rem auto 0;
  position: relative;
  background: ${colours.white};
  z-index: 20;

  h3 {
    line-height: 1;
    width: 50%;
    font-size: 2rem;
    margin: 0;
  }

  .tuned {
    position: relative;
    width: 100%;
    text-align: center;
    height: 6rem;
    bottom: -0.7rem;
  }

  .auctionTerms {
    font-size: .75rem;
  }

  h1 {
    position: absolute;
    bottom: 0;
    -webkit-text-stroke: 1px ${colours.primary};
    color: transparent;
    -ms-color: ${colours.primary};
    white-space: nowrap;
    margin: 3rem auto 0;
    left: 50%;
    transform: translateX(-50%);
  }

  p {
    font-size: 1.05rem;
    max-width: 330px;
    text-align: center;
  }
  > div {
    z-index: 2;
  }
  .flare2 {
    left: -220px;
    z-index: 0;
    top: -100px;
    opacity: 0;
    animation: ${fadeInHalf} forwards 4s;
  }

  @media (min-width: 376px) {
    margin: 1rem 0 0;
    width: 100%;
    padding: 10rem 10% 0;

    h1 {
      margin: 3rem 0 -1rem;
      relative: absolute;
    }

    p {
      max-width: 800px;
      text-align: left;
    }
    .tuned {
      height: 6rem;
      bottom: -0.2rem;
    }
  }

  @media (min-width: 992px) {
    padding: 10rem 18% 3rem;

    h3 {
      font-size: 4rem;
      margin-bottom: 2rem;
    }
    > p {
      margin-bottom: 1rem;
      margin-left: 2rem;
    }
  }
  @media (min-width: 1100px) {
    padding-top: 14rem;
  }
`

export const AnimatedImg = styled(motion.div)<AnimatedImgProps>`
  position: absolute;
  z-index: ${(props) => props.zIndex};
`

export const CollaboratorWrapper = styled.div`
  margin: 2rem 0 0;

  @media (min-width: 992px) {
    &:first-child {
      margin-top: 5rem;
    }
    flex-direction: row !important;
    margin-bottom: 2rem;
  }
  &:last-child {
    margin-bottom: 1rem;
  }
`

export const CollaboratorImages = styled.div`
  flex-direction: row !important;
  flex: none;
  overflow: hidden;
  margin: 0 -1.25rem;

  img {
    position: relative;
    z-index: 3;
  }

  img,
  video {
    height: 237px !important;
    width: auto !important;
  }
  video {
    background: white;
    transform: scale(1.1);
  }
  @media (min-width: 420px) {
    margin: 0 auto;
  }

  @media (min-width: 992px) {
    margin: 0;
    margin-right: 2rem;
    img,
    video {
      height: 266px !important;
    }
  }
`

export const CollaboratorVideo = styled.div`
  position: relative;
  overflow: hidden;

  height: 266px;
  width: 212px;
  > div {
    width: initial !important;
    height: initial !important;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.1);
    z-index: 2;
    opacity: 0.3;
  }
  svg {
    transform: scale(0.5) !important;
  }
  @media (min-width: 992px) {
    margin-left: 1rem;
  }
`

export const CollaboratorCopy = styled.div`
  position: relative;
  justify-content: center;

  h2 {
    font-size: 1.5rem;
    margin: 0;
    line-height: 1.25 !important;
    color: ${colours.secondary2};
  }

  p {
    font-size: 1rem;
  }

  @media (min-width: 376px) {
    h2 {
      font-size: 2rem;
    }
  }

  @media (min-width: 992px) {
    justify-content: flex-start;
    p {
      font-size: 1.15rem;
    }
    h2 {
      font-size: 3rem;
    }
  }
`

export const CollaboratorYoutubeButton = styled.div`
  flex: none;
  margin: 1.5rem auto 0;
  align-items: center;
  background: ${colours.primary};
  padding-left: 0.3rem;
  height: 2.2rem;
  border-radius: 1.2rem;
  color: white;
  flex-direction: row !important;
  cursor: pointer;
  p {
    color: white;
    font-size: 0.75rem;
    line-height: 0 !important;
    margin: 0 1.5rem 0 1rem;
  }
  transition: 0.2s;
  &:hover {
    background: ${lighten(0.1, colours.primary)};
  }
  &:active {
    background: ${lighten(0.1, colours.primary)};
    transform: translateY(2px);
  }
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`

export const CenterButton = styled.div`
  justify-content: center;

  .button {
    margin: 0 auto;
    margin-top: 1rem;
  }
  .description {
    text-align: center;
    margin-top: 0.5rem;
    color: ${colours.darkGrey1};
    opacity: 0.75;
    font-size: 0.9rem;
  }
  @media (min-width: 992px) {
    margin-right: auto;
    .button {
      margin-left: 0;
    }
  }
`
