// @ts-nocheck

import Data from './stores.json';
// @ts-nocheck
export default {
  getStoreInfo(localeCode) {
    const store = Data.stores.find((d) => d.fourLetterCountryCode === localeCode);
    return store;
  },
};
