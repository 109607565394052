import React from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useTranslation } from "react-i18next"
import { modal } from "store/atoms"
import { viewport, submittedDesign, rootPath } from "store/atoms"
import { configSettings } from "store/selectors"
import { navigate, RouteComponentProps } from "@reach/router"
import { AnimatedContent, Button, Checkbox, Text } from "components"
import { Flare } from "theme"
import { SignupConfirmWrapper } from "./styles"
import DataLayer from "utils/dataLayer"

const SignupConfirm: React.FC<RouteComponentProps> = () => {
  const config = useRecoilValue(configSettings)
  const setModal = useSetRecoilState(modal)
  const view = useRecoilValue(viewport)
  const design = useRecoilValue(submittedDesign)
  const { t } = useTranslation()
  const rootUrl = useRecoilValue(rootPath)
  const root = rootUrl === "/" ? rootUrl : `/${rootUrl}/`

  const share = () => {
    DataLayer.cta("Share_Design")
    navigate(`${root}${t("pathGalleryItem")}/${design.designId}`)
  }

  const handleBack = () => {
    DataLayer.cta("GO_BACK")
    navigate(rootUrl === "/" ? rootUrl : `/${rootUrl}/`)
  }

  return (
    <SignupConfirmWrapper dimensions={view}>
      <Flare className="flare1" />
      <AnimatedContent trigger delay={0.2}>
        <Text textId="signupConfirm" type="h3" />
      </AnimatedContent>
      <AnimatedContent trigger delay={0.6}>
        <Button
          colour="red"
          textId="buttonShareDesign"
          onClick={share}
        />
      </AnimatedContent>
      <AnimatedContent trigger delay={0.8}>
        <Button
          colour="white"
          outline
          textId="buttonBackHome"
          onClick={handleBack}
        />
      </AnimatedContent>
    </SignupConfirmWrapper>
  )
}

export default SignupConfirm
