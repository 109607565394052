import styled, { keyframes } from "styled-components/macro"
import { colours } from "theme"
import BackImg from "assets/images/back-arrow.svg"

export const TermsWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  padding: 20px 50px;
  margin: auto;
`

export const ArticleWrapper = styled.article`
    .container {
		h1 {
			margin-bottom: 1em;
			font-size: var(--size__font__heading);
			font-weight: var(--font_weight__slab);
			line-height: var(--line_height__heading);
		}
		p {
			a {
				margin: unset;
				display: contents !important;
				:hover {
				  color: unset;
				}
			}
		}
		ol {
			margin: 1.62em 0;
			padding-left: 1.62em;
			list-style-type: decimal;
			li {
				& + li {
					margin-top: 1em;
				}
			}
		}

		address {
			margin-top: 1em;
			padding-left: 1em;
		}
	}
}
.back {
	display: block;
    margin-bottom: 1em;
    cursor: pointer;
	&::before {
		content: "";
		display: inline-block;
		height: 0.62em;
		width: 1em;
		background: url(${BackImg}) center / contain no-repeat;
		font-size: 1em;

	}
`
