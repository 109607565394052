import React, { createElement, useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import {
  currentBadge,
  editorStep,
  textureSettings,
  stepDirection,
} from "store/atoms"
import { hotspots } from "store/constants"
import { Badge } from "utils/types"
import { FlexRow } from "theme"
import { AddorEdit, InlineButton, Button, Text } from "components"
import { StepsWrapper } from "./styles"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { ReactComponent as Add } from "assets/images/add.svg"
import { ReactComponent as Tommy0 } from "assets/images/tommy0.svg"
import { ReactComponent as Tommy1 } from "assets/images/tommy1.svg"
import { ReactComponent as Tommy2 } from "assets/images/tommy2.svg"
import { ReactComponent as Tommy3 } from "assets/images/tommy3.svg"
import { ReactComponent as Tommy4 } from "assets/images/tommy4.svg"
import { ReactComponent as Tommy5 } from "assets/images/tommy5.svg"
import { useTranslation } from "react-i18next"
import DataLayer from "utils/dataLayer"

const badges = [Tommy0, Tommy1, Tommy2, Tommy3, Tommy4, Tommy5]

const Step7: React.FC<RouteComponentProps> = () => {
  const currBadge = useRecoilValue(currentBadge)
  const currStep = useRecoilValue(editorStep)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const spots = useRecoilValue(hotspots)
  const settings = useRecoilValue(textureSettings)
  const updateTexture = useSetRecoilState(textureSettings)
  const direction = useRecoilValue(stepDirection)
  const setDirection = useSetRecoilState(stepDirection)
  const [currentBadges, setCurrentBadges] = useState(settings.badges)
  const [submitting, setSubmitting] = useState(false)
  const { t } = useTranslation()

  const goto = (url: string) => {
    const dir = url === `${t("pathStep")}8` ? "right" : "left"
    setDirection(dir)
    navigate(url)
  }

  useEffect(() => {
    let cBadges = settings.badges
    if (currBadge.type === -1) {
      goto(`${t("pathStep")}6`)
    }

    if (currentBadges.length < 4 && currStep === 7 && !submitting) {
      if (
        currBadge.type > -1 &&
        currBadge.position > -1 &&
        currBadge.size > -1
      ) {
        cBadges = [...settings.badges, currBadge, Add]
      } else {
        cBadges = [...settings.badges, Add]
      }

      setCurrentBadges(cBadges)
    }
  }, [currBadge, settings])

  const convertBadge = () => {
    const { position, size } = currBadge
    if (position < 0) {
      return currBadge
    }

    const liveSpot = spots[settings.modelType as "shirt"][position]
    const { allowed } = liveSpot
    return { ...currBadge, size: allowed[size] }
  }

  const addBadge = () => {
    const newSettings = { ...settings }
    const newBadge = convertBadge()
    const doesBadgeExist =
      settings.badges.filter(
        // @ts-ignore
        (b) => Number(b.type) === Number(newBadge.type)
      ).length > 0

    if (!doesBadgeExist) {
      const newBadges = [...newSettings.badges, newBadge]
      // @ts-ignore
      newSettings.badges = newBadges
    }

    updateTexture(newSettings)
  }

  const handleAddOrEdit = (index: number, args: Badge | any) => {
    if (Number.isInteger(args.type)) {
      if (args.type !== currBadge.type) {
        // addBadge()
        const newBadges = [
          ...settings.badges.filter(
            // @ts-ignore
            (val) => val.type !== args.type
          ),
          convertBadge(),
        ]
        const newSettings = { ...settings }

        //@ts-ignore
        newSettings.badges = newBadges
        updateTexture(newSettings)
        setCurrBadge(args)
      } else {
        setCurrBadge({ type: args.type, position: -1, size: -1 })
      }
    } else {
      setCurrBadge({ type: -1, position: -1, size: -1 })
      addBadge()
    }

    navigate(`${t("pathStep")}4`)
  }

  const goBack = () => {
    const { modelType, split } = settings
    const { position, slant } = split
    const id = currBadge.position > -1 ? currBadge.position : 0
    const currSpots = spots[modelType as "shirt"]
    const allowed = currSpots.filter((h) => h.id === id)[0].allowed

    let multipleSizes = true
    if (modelType === "shirt" && position === "right" && slant && id === 2) {
      multipleSizes = false
    }
    if (position === "left" && slant && id === 3) {
      multipleSizes = false
    }
    if (modelType === "hood" && position === "left" && slant && id === 9) {
      multipleSizes = false
    }
    if (
      modelType === "shirt" &&
      position === "right" &&
      slant &&
      (id === 5 || id === 9 || id === 11)
    ) {
      multipleSizes = false
    }
    if (
      modelType === "hood" &&
      position === "right" &&
      slant &&
      (id === 2 || id === 7)
    ) {
      multipleSizes = false
    }
    navigate(
      allowed.length > 1 && multipleSizes
        ? `${t("pathStep")}6`
        : `${t("pathStep")}4`
    )
  }

  const finish = () => {
    setSubmitting(true)
    DataLayer.cta("Finish")
    goto(`${t("pathStep")}8`)
  }

  const getButtons = () => {
    return currentBadges.map((val, i) => {
      let icon = Add
      let className = null

      if (val === null || i > 2) {
        return false
      }

      const { type } = val

      if (i < currentBadges.length - 1) {
        icon = badges[type]
        className = "badge"
        if (type === 5) {
          className = "badge portrait"
        }
      }

      return (
        <AddorEdit
          key={`addOrEdit${i}`}
          className={className}
          onClick={() => handleAddOrEdit(i, val)}
          size={currentBadges.length < 3 ? 110 : 95}
        >
          {createElement(icon)}
        </AddorEdit>
      )
    })
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  return (
    <StepsWrapper
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
    >
      <Text textId="editorAddAnother" type="h5" />
      <FlexRow className="row center icon-buttons scalable">
        {getButtons()}
      </FlexRow>
      <FlexRow className="row center medium-buttons">
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={goBack}
        />
        <Button colour="secondary2" textId="buttonFinish" onClick={finish} />
      </FlexRow>
    </StepsWrapper>
  )
}

export default Step7
