import deAT from './data/de-AT.json';
import deCH from './data/de-CH.json';
import deDE from './data/de-DE.json';
import enCZ from './data/en-CZ.json';
import enDE from './data/en-DE.json';
import enEE from './data/en-EE.json';
import enFI from './data/en-FI.json';
import enHR from './data/en-HR.json';
import enHU from './data/en-HU.json';
import enIE from './data/en-IE.json';
import enLT from './data/en-LT.json';
import enLV from './data/en-LV.json';
import enPT from './data/en-PT.json';
import enRU from './data/en-RU.json';
import enSE from './data/en-SE.json';
import enSI from './data/en-SI.json';
import enSK from './data/en-SK.json';
import enUK from './data/en-UK.json';
import esES from './data/es-ES.json';
import frBE from './data/fr-BE.json';
import frCH from './data/fr-CH.json';
import frFR from './data/fr-FR.json';
import frLU from './data/fr-LU.json';
import itCH from './data/it-CH.json';
import itIT from './data/it-IT.json';
import nlBE from './data/nl-BE.json';
import deLU from './data/de-LU.json';
import nlNL from './data/nl-NL.json';
import plPL from './data/pl-PL.json';
import ruRU from './data/ru-RU.json';

const getDataFromLang = (lang: string): { footer: any } => {
  switch (lang) {
    case 'de-AT': return deAT;
    case 'de-CH': return deCH;
    case 'de-DE': return deDE;
    case 'en-CZ': return enCZ;
    case 'en-DE': return enDE;
    case 'en-EE': return enEE;
    case 'en-FI': return enFI;
    case 'en-HR': return enHR;
    case 'en-HU': return enHU;
    case 'en-IE': return enIE;
    case 'en-LT': return enLT;
    case 'en-LV': return enLV;
    case 'en-PT': return enPT;
    case 'en-RU': return enRU;
    case 'en-SE': return enSE;
    case 'en-SI': return enSI;
    case 'en-SK': return enSK;
    case 'en-UK': return enUK;
    case 'es-ES': return esES;
    case 'fr-BE': return frBE;
    case 'fr-CH': return frCH;
    case 'fr-FR': return frFR;
    case 'fr-LU': return frLU;
    case 'it-CH': return itCH;
    case 'it-IT': return itIT;
    case 'nl-BE': return nlBE;
    case 'de-LU': return deLU;
    case 'nl-NL': return nlNL;
    case 'pl-PL': return plPL;
    case 'ru-RU': return ruRU;
    default:
      return enUK;
  }
};

export default { getDataFromLang };
