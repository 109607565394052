import styled from 'styled-components';
import { breakpoints, colours } from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  outline: none;
  box-sizing: border-box;
  animation: fade-in-backdrop .4s linear both;
  background: #00174F;
  overflow: auto;
  margin: 0;
  color: ${colours.white};
  padding: 1.33333rem 4vw 1.33333rem 4vw;
  position: relative;
  
  ${breakpoints.md`
     flex-direction: row !important;
     padding: 1.66667rem 3.84rem;
  `}
`;

export const Content = styled.div`
  margin-bottom: 1.33333rem;

  ${breakpoints.md`
    margin-bottom: 0;
    justify-content: flex-end;
    flex: 80;
  `}
`

export const Buttons = styled.div`
  ${breakpoints.md`
    justify-content: center;
    padding-left: 30px;
    flex: 20;
  `}
`

export const BodyText = styled.span`
  font-family: Futura,Calibri,sans-serif;
  //font-family: FuturaLTW02-Book,Helvetica,Arial,sans-serif;
  color: #848484;
  font-size: 17px;
  line-height: 1.46667rem;
  letter-spacing: normal;
  display: inline-block;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  ${breakpoints.sm`
    align-items: flex-start;
  `}
  
  
  ${breakpoints.md`
    justify-content: center;
    align-items: flex-end;
  `}  
`;

export const ButtonText = styled.span`
  font-family: FuturaMedium,Calibri,sans-serif;
  display: block;
  position: relative;
  z-index: 3;
  color: #00174F;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 300ms color cubic-bezier(0.785, 0.135, 0.15, 0.86);
`

export const Button = styled.button`
  position: relative;
  display: inline-block;
  background: #fff;
  min-width: 200px;
  height: 45px;
  padding: 0 1.13333rem;
  text-align: center;
  overflow: hidden;
  transition: 300ms border-color cubic-bezier(0.785, 0.135, 0.15, 0.86);
  border: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  
  :before {
    position: absolute;
    background: #00174F;
    z-index: 1;
    transition: 300ms transform cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  :after {
    background: #CC0C2F;
    z-index: 2;
    -webkit-transition: 300ms -webkit-transform cubic-bezier(0.785, 0.135, 0.15, 0.86) 50ms;
    transition: 300ms transform cubic-bezier(0.785, 0.135, 0.15, 0.86) 50ms;
  }
  
  :before, :after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
  }
 
  :hover {
  
    span {
      display: inline-block;
      color: #fff !important;
    }
  
    :before, :after {
      transform: translate3d(-100%, 0, 0);
    }
  }
`;
