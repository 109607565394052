import React from "react"
import {
  Wrapper,
  BodyText,
  Action,
  Button,
  Content,
  Buttons, ButtonText,
} from "./styles"

interface CookieContentProps {
  translations: any
  agreeOnClick: (analytics: boolean, socialMedia: boolean) => void
}

export const CookieContent: React.FC<CookieContentProps> = ({
  translations,
  agreeOnClick,
}) => (
  <Wrapper>
    <Content>
      <BodyText>
        {translations.text}
      </BodyText>
    </Content>
    <Buttons>
      <Action>
        <Button onClick={() => agreeOnClick(true, true)}>
          <ButtonText>{translations.agree}</ButtonText>
        </Button>
      </Action>
    </Buttons>
  </Wrapper>
)

export default CookieContent
