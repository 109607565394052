/**
 * @constant
 */

export const size = {
  sm: 576,
  md: 768,
  lg: 1024,
};

/**
 * @param {{
 * sm: string,
 * md: string,
 * lg: string,
 * xl: string
 * }} size
 */
export default (Object.keys(size) as Array<keyof typeof size>).reduce(
  (acc, key) => {
    acc[key] = (style: String) => `@media (min-width: ${size[key]}px) { ${style} }`;
    return acc;
  },
  {} as { [index: string]: Function },
);
