import React, { lazy, Suspense, useEffect } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { AnimatePresence } from "framer-motion"
import { Redirect, Router, useLocation } from "@reach/router"
import { useTranslation } from "react-i18next"
import { Loader, Modal } from "components"
import ScrollToTop from "utils/scrollToTop"

import {
  SignupConfirm,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Terms,
} from "pages"
import { isLoading, modal, rootPath, locale, viewport } from "store/atoms"
import { configSettings } from "store/selectors"
import { LayoutWrapper } from "./styles"
import RoutingManager from "utils/routingManager"
import DataLayer from "utils/dataLayer"

const AuctionLazy = lazy(() => import("pages/Auction"))
const HomeLazy = lazy(() => import("pages/Home"))
const CreateLazy = lazy(() => import("pages/Create"))
const GalleryLazy = lazy(() => import("pages/Gallery"))
const GalleryItemLazy = lazy(() => import("pages/Gallery/GalleryItem"))
const EditorLazy = lazy(() => import("pages/Editor"))

const Layout: React.FC = () => {
  const showLoader = useRecoilValue(isLoading)
  const showModal = useRecoilValue(modal)
  const config = useRecoilValue(configSettings)
  const { t, i18n } = useTranslation()
  const rootUrl = useRecoilValue(rootPath)
  const setRootUrl = useSetRecoilState(rootPath)
  const {
    shortCode: localeTwoLetter,
    longCode: localeFourLetter,
  } = useRecoilValue(locale)
  const setLocale = useSetRecoilState(locale)
  const { isMobile } = useRecoilValue(viewport)

  type tLocaleOptions = {
    [key: string]: string
  }

  const localeOptions: tLocaleOptions = config.defaultLocales

  useEffect(() => {
    let langCode = `${
      config.defaultLanguage
    }-${config.defaultCountryCode.toUpperCase()}`
    let localeCode = config.defaultLanguage
    let pathLocale = ""
    if (config.useURLsForLanguages === true) {
      const res: any = RoutingManager.detectLanguageFromUrl(
        config,
        localeOptions
      )
      if (res) {
        const { newLang, locale, pathCode } = res
        if (newLang) {
          i18n.changeLanguage(newLang)
          langCode = newLang
          localeCode = locale
          pathLocale = pathCode
        }
      }
    }

    const url = new URL(window.location.href)
    const path = url.pathname
    // @ts-ignore
    const baseUrl = RoutingManager.getBaseUrl(config, langCode)
    const root = RoutingManager.getRootPath(baseUrl, path)

    setRootUrl(root)
    setLocale({ longCode: langCode, shortCode: localeCode, pathLocale })

    if (path.substr(path.length - baseUrl.length, baseUrl.length) === baseUrl) {
      RoutingManager.addSlashToUrl()
    }
  }, [])

  const getCookie = (name: string) =>
    document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=")
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, "")

  const location = useLocation()
  useEffect(() => {
    const acceptCookie = getCookie("PVH_COOKIES_GDPR_ANALYTICS") === "Accept"
    const acceptSocialCookie =
      getCookie("PVH_COOKIES_GDPR_SOCIALMEDIA") === "Accept"
    DataLayer.setupDataLayer(
      location.pathname,
      localeTwoLetter,
      isMobile,
      acceptCookie,
      acceptSocialCookie
    )
  }, [location])

  const wrapperMode = process.env.REACT_APP_WRAPPER_MODE || ""
  const compCountries = (config.winSignupCountries as any) || ([] as any)
  const signupMode = compCountries.includes(localeFourLetter) ? "WIN" : "NOWIN"

  return (
    <>
      <AnimatePresence>{showLoader.status && <Loader />}</AnimatePresence>

      <LayoutWrapper modalOpen={showModal.show}>
        <Router primary={false} basepath={rootUrl} className="router">
          <ScrollToTop path="/">
            {/* <AuctionLazy path={t("pathAuction")} /> */}
            <CreateLazy path={t("pathCoCreate")} />
            <Redirect
              noThrow
              from={t("pathEditor")}
              to={t("pathEditorShirt")}
            />
            <EditorLazy path={`${t("pathEditor")}/:itemType`}>
              <Redirect noThrow from="/" to={`${t("pathStep")}1`} />
              <Step1 path={`${t("pathStep")}1`} />
              <Step2 path={`${t("pathStep")}2`} />
              <Step3 path={`${t("pathStep")}3`} />
              <Step4 path={`${t("pathStep")}4`} />
              <Step5 path={`${t("pathStep")}5`} />
              <Step6 path={`${t("pathStep")}6`} />
              <Step7 path={`${t("pathStep")}7`} />
              <Step8 path={`${t("pathStep")}8`} />
            </EditorLazy>
            {/* <SignupBasic path={t("pathSignup")} /> */}
            {/* <GalleryLazy path={t("pathGallery")} />
          <GalleryItemLazy path={`${t("pathGalleryItem")}`} />
          <GalleryItemLazy path={`${t("pathGalleryItem")}/:itemId`} /> */}
            <Terms path={`${t("pathTerms")}/:termsType`} />
            <SignupConfirm path={`${t("pathSignupConfirm")}`} />
            <HomeLazy path="/" default />
          </ScrollToTop>
        </Router>
      </LayoutWrapper>
    </>
  )
}

export default Layout
