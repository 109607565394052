import React, { MouseEvent } from "react"
import { IconBadge, IconButtonWrapper } from "./styles"
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion"
import { ReactComponent as Tick } from "assets/images/tick.svg"

interface IconButtonProps {
  active: boolean
  badge?: boolean
  badgeSize?: number
  onClick: (e: MouseEvent) => void
  portrait?: boolean
  size?: {
    width: number
    height: number
  }
}

const badgeVariants = {
  active: {
    scale: 1,
    transition: {
      type: "spring",
      damping: 20,
      mass: 1,
      stiffness: 500,
    },
  },
  inactive: {
    scale: 0,
  },
}

const tickVariants = {
  pressed: (active: boolean) => ({ pathLength: active ? 0.85 : 0.2 }),
  checked: {
    pathLength: 1,
    transition: {
      delay: 0.2,
    },
  },
  unchecked: { pathLength: 0 },
}

const IconButton: React.FC<IconButtonProps> = ({
  children,
  badge,
  badgeSize,
  active,
  onClick,
  size,
  portrait,
}) => {
  const pathLength = useMotionValue(0)
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1])

  return (
    <IconButtonWrapper
      badge
      badgeSize={badgeSize}
      className="icon-button"
      onClick={onClick}
      active={active}
      size={size}
      portrait={portrait}
    >
      {children}
      {active && (
        <IconBadge
          animate="active"
          initial="inactive"
          exit="inactive"
          variants={badgeVariants}
        >
          <motion.svg
            width="11px"
            height="9px"
            viewBox="0 0 11 9"
            initial="unchecked"
            animate={active ? "checked" : "unchecked"}
            exit="unchecked"
          >
            <motion.path
              d="M4805.115,4663.22l2.611,2.742,6.073-5.909"
              transform="translate(-4803.702 -4658.638)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              style={{ pathLength, opacity, strokeWidth: 2 }}
              custom={active}
              variants={tickVariants}
            />
          </motion.svg>
        </IconBadge>
      )}
    </IconButtonWrapper>
  )
}

export default IconButton
