import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { AnimatedImg, AnimatedPicsWrapper } from "./styles"
import Big1 from "assets/images/auctionBig/1.jpg"
import Big2 from "assets/images/auctionBig/2.jpg"
import Big3 from "assets/images/auctionBig/3.jpg"
import Big4 from "assets/images/auctionBig/4.jpg"
import Big5 from "assets/images/auctionBig/5.jpg"
import { ReactComponent as ArrowDown } from "assets/images/arrowDownBig.svg"
import { viewport } from "store/atoms"
import { useRecoilValue } from "recoil"

const big = [
  {
    width: 45,
    x: 50,
    y: 1.5,
    desktopWidth: 45,
    desktopX: 60,
    desktopY: 1.5,
    secondX: 55,
    secondY: 12,
    secondWidth: 38,
    index: 0,
    src: Big3,
  },
  {
    width: 35,
    x: 16,
    y: 8,
    desktopWidth: 42,
    desktopX: 7,
    desktopY: 6,
    secondX: 7,
    secondY: 15,
    secondWidth: 30,
    index: 0,
    src: Big1,
  },
  {
    width: 39,
    x: 83,
    y: 6,
    desktopWidth: 39,
    desktopX: 89,
    desktopY: 6,
    secondX: 94,
    secondY: 19,
    secondWidth: 27,
    index: 1,
    src: Big4,
  },
  {
    width: 38,
    x: 10,
    y: 25,
    desktopWidth: 53,
    desktopX: 40,
    desktopY: 14,
    secondX: 20,
    secondY: 20,
    secondWidth: 32,
    index: 3,
    src: Big2,
  },
  {
    width: 80,
    x: 64,
    y: 19,
    desktopWidth: 80,
    desktopX: 72,
    desktopY: 19,
    secondX: 65,
    secondY: 20,
    secondWidth: 40,
    index: 0,
    src: Big5,
  },
]

interface AnimatedPicsProps {
  dimensions: {
    width: number
  }
  scrollPos: number
}

const AnimatedPics: React.FC<AnimatedPicsProps> = ({
  dimensions,
  scrollPos,
}) => {
  const view = useRecoilValue(viewport)
  const [animState, setAnimState] = useState("active")
  const [init, setInit] = useState(true)

  useEffect(() => {
    if (animState === "active" && scrollPos > 1) {
      setAnimState("scroll")
      if (init) {
        setInit(false)
      }
    } else if (animState === "scroll" && scrollPos < 1) {
      setAnimState("active")
    }
  }, [scrollPos])

  const getPics = () => {
    return big.map((val, i) => {
      const {
        desktopWidth,
        desktopX,
        desktopY,
        index,
        x,
        y,
        width,
        secondX,
        secondY,
        secondWidth,
      } = val

      return (
        <AnimatedImg
          key={`big${i}`}
          zIndex={index}
          animate={animState}
          initial="inactive"
          variants={{
            active: {
              top: `${view.isMobile ? y : desktopY}%`,
              left: `${view.isMobile ? x : desktopX}%`,
              width: `${view.isMobile ? width : desktopWidth}%`,
              opacity: 1,
              transition: {
                delay: init ? 0.2 * i : 0,
                type: "spring",
                damping: 100,
                mass: 1,
              },
            },
            scroll: {
              top: `${secondY}%`,
              left: `${secondX}%`,
              width: `${secondWidth}%`,
              opacity: 1,
              transition: { duration: 0.5 },
            },
            inactive: {
              top: `${(view.isMobile ? y : desktopY) + 7}%`,
              left: `${view.isMobile ? x : desktopX}%`,
              width: `${view.isMobile ? width : desktopWidth}%`,
              opacity: 0,
            },
          }}
        >
          <img src={val.src} />
        </AnimatedImg>
      )
    })
  }

  return (
    <AnimatedPicsWrapper width={dimensions.width} scrollPos={scrollPos}>
      {getPics()}
      <motion.div
        className="arrowHolder"
        animate={animState}
        initial="inactive"
        variants={{
          scroll: {
            opacity: 0,
          },
          inactive: {
            opacity: 1,
          },
        }}
      >
        <ArrowDown className="arrow" />
      </motion.div>
    </AnimatedPicsWrapper>
  )
}

export default AnimatedPics
