import React from 'react';
import { Link } from '@reach/router';

import NavigationLinkWrapper from './styles';

interface NavigationLinkProps {
  baseUrl: string;
  categoryChildren: any;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ categoryChildren, baseUrl }) => (
  <NavigationLinkWrapper>
    {categoryChildren.map((linkData: any) => {
      const { name, url, id } = linkData;
      const itemURL = `${baseUrl}/${url}`;

      return (
        <li
          className="list__link"
          key={id}
        >
          <a
            className="link"
            href={itemURL}
            rel="noopener"
          >
            {name}
          </a>
        </li>
      );
    })}
  </NavigationLinkWrapper>
);

export default NavigationLink;
