import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps, useLocation } from "@reach/router"
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil"
import { AnimatePresence } from "framer-motion"
import { CanvasTexture, InlineButton, Scene, Timeline } from "components"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { EditorWrapper, Flip, StepsContainer } from "./styles"
import {
  editorStep,
  hotspotsVisible,
  isZoomed,
  meshView,
  rootPath,
  textureSettings,
  viewport,
} from "store/atoms"
import { ReactComponent as FlipIcon } from "assets/images/flip.svg"
import { ReactComponent as Icon360 } from "assets/images/iconRotate360.svg"

import { useTranslation } from "react-i18next"

const Editor: React.FC<RouteComponentProps> = ({ children }) => {
  const [sect, setSect] = useRecoilState(editorStep)
  const [showGuide, setShowGuide] = useState(false)
  const currentView = useRecoilValue(meshView)
  const view = useRecoilValue(viewport)
  const currTextureSettings = useRecoilValue(textureSettings)
  const updateTexture = useSetRecoilState(textureSettings)
  const showHotspots = useRecoilValue(hotspotsVisible)
  const setView = useSetRecoilState(meshView)
  const setIsZoom = useSetRecoilState(isZoomed)
  const isZoom = useRecoilValue(isZoomed)
  const rootUrl = useRecoilValue(rootPath)
  const root = rootUrl === "/" ? rootUrl : `/${rootUrl}/`
  const location = useLocation()
  const { t } = useTranslation()

  const setSection = (index: number) => {
    setSect(index)
    setView(index)
  }

  const flipObject = () => {
    const newView = currentView === 0 ? 2 : 0
    setView(newView)
  }

  useEffect(() => {
    const matches = location.pathname.match(`${t("pathStep")}([1-8])`) || []
    const stepNum = Number(matches.length > 1 ? matches[1] : 1)
    setSect(stepNum)
    setIsZoom(stepNum === 6)
    const modelType = location.pathname.includes(t("pathEditorShirt"))
      ? "shirt"
      : "hood"
    const newSettings = { ...currTextureSettings }
    newSettings.modelType = modelType
    if (newSettings.leftColour === "") {
      newSettings.leftColour = modelType === "shirt" ? "white" : "hoodBlack"
      newSettings.rightColour = modelType === "shirt" ? "navy" : "red"
    }
    updateTexture(newSettings)
  }, [location])

  const updateTextureColours = (direction: string, colour: string) => {
    const newSettings = { ...currTextureSettings }
    //@ts-ignore
    newSettings[direction] = colour
    updateTexture(newSettings)
  }

  const goto = (url: string) => {
    navigate(url)
  }

  const updateBadge = (e: any, badge: string) => {
    const newSettings = { ...currTextureSettings }
    const newArray = [...newSettings.badges]
    //@ts-ignore
    if (!newArray.includes(badge) && e.currentTarget.checked) {
      //@ts-ignore
      newArray.push(badge)
    } else {
      const index = newArray.findIndex((b) => b === badge)
      newArray.splice(index, 1)
    }
    newSettings.badges = newArray
    updateTexture(newSettings)
  }

  const toggleGuide = () => {
    setShowGuide(!showGuide)
  }
  const toggleSlant = () => {
    const newSettings = { ...currTextureSettings }
    newSettings.split.slant = !currTextureSettings.split.slant
    updateTexture(newSettings)
  }

  const toggleSlice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = { ...currTextureSettings }
    newSettings.split.position = e.currentTarget.id
    updateTexture(newSettings)
  }

  return (
    <EditorWrapper dimensions={view}>
      <InlineButton
        textId="buttonExit"
        icon={<ChevronLeft />}
        onClick={() => goto(`${root}${t("pathCoCreate")}`)}
      />
      <Timeline className="timeline" />
      <Flip className="flip" onClick={flipObject}>
        <FlipIcon />
      </Flip>
      <Scene dimensions={view} section={sect} />
      {sect !== 4 && !showHotspots && <Icon360 id="icon-360" />}
      <StepsContainer>{children}</StepsContainer>
      {/* <CanvasTexture
        debug={true}
        section={6}
        modelType="hood"
        badges={currTextureSettings.badges}
      /> */}
    </EditorWrapper>
  )
}

export default Editor
