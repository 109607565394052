import React, { useState } from 'react';
import {
  Title, Field, Description, Wrapper, CheckBox, Label, Overlay,
} from './styles';
import { GreenButton } from '../CookieFooter/styles';
import { useTranslation } from "react-i18next"

interface SettingsProps {
  translations: any;
  doneOnClick: (analytics: boolean, socialMedia: boolean) => void;
}

export const Settings: React.FC<SettingsProps> = ({ translations, doneOnClick }) => {

   const { t } = useTranslation()
  const [analytics, setAnalytics] = useState(true);
  const [socialMedia, setSocialMedia] = useState(true);
  const { title, preferences } = translations;

  const handleAnalyticsOnClick = () => setAnalytics(!analytics);
  const handleSocialMediaOnClick = () => setSocialMedia(!socialMedia);

  return (
    <Overlay>
      <Wrapper>
        <Title>{t("cookiePreferences")}</Title>
        <Field>
          <Label htmlFor="functional">
            <CheckBox id="functional" checked={true} disabled defaultChecked type="checkbox" />
            {t("cookieFunctional")}
          </Label>
          <Description> {t("cookieFunctionalBody")}</Description>
        </Field>
        <Field>
          <Label htmlFor="analytical">
            <CheckBox id="analytical" checked={analytics} type="checkbox" onClick={handleAnalyticsOnClick} />
            {t("cookieAnalytics")}
          </Label>
          <Description> {t("cookieAnalyticsBody")}</Description>
        </Field>
        <Field>
          <Label htmlFor="socialMedia">
            <CheckBox id="socialMedia" checked={socialMedia} onClick={handleSocialMediaOnClick} type="checkbox" />
            {t("cookieSocial")}
          </Label>
          <Description dangerouslySetInnerHTML={{__html: t("cookieSocialBody")}} />
        </Field>
        <GreenButton onClick={() => doneOnClick(analytics, socialMedia)}> {t("done")}</GreenButton>
      </Wrapper>
    </Overlay>
  );
};

export default Settings;
