import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"


interface TermsProps {
    onBack: () => void
  }

const Terms: React.FC<TermsProps> =({
    onBack
  }) => {

  return (
    	<ArticleWrapper>
				<span className="back" onClick={onBack}>
						Back
					</span>
				<div className="container">
					<h1>
						TERMS AND CONDITIONS 
					</h1>
					<p>
						Participation for residents of The Netherlands, Germany, France and United Kingdom only. Void where prohibited by law. This promotion runs from September 2nd until September 7th. To enter this promotion you need to customize and submit your preferred designs for the t-shirt and hoodie no later than 7th of September 2020. The winner will be selected at random on or around the 14th of September 2020. The winner receives the final designed product, either t-shirt or hoodie, at chance. The winner(s) name(s) will be published on <a href="https://uk.tommy.com/flag/terms/comp" target="_blank">terms and conditions</a>. Participants have to be 18 years or older to participate. Go <a href="https://uk.tommy.com/flag/terms/comp" target="_blank">here</a> for more information and the full terms and conditions of this promotion.. 
					</p>
				</div>
			</ArticleWrapper>
  )
}

export default Terms


