import React, { useEffect } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { currentBadge, stepDirection, textureSettings } from "store/atoms"
import { FlexRow } from "theme"
import { InlineButton, Text } from "components"
import { Disclaimer, StepsWrapper } from "./styles"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { useTranslation } from "react-i18next"

const Step5: React.FC<RouteComponentProps> = () => {
  const direction = useRecoilValue(stepDirection)
  const settings = useRecoilValue(textureSettings)
  const currBadge = useRecoilValue(currentBadge)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const setDirection = useSetRecoilState(stepDirection)
  const { t } = useTranslation()

  useEffect(() => {
    const newBadge = { ...currBadge }
    newBadge.position = -1
    setCurrBadge(newBadge)
    if (currBadge.type < 0) {
      navigate(`${t("pathStep")}4`)
    }
  }, [])

  const goto = (url: string) => {
    const dir = url === `${t("pathStep")}6` ? "right" : "left"
    setDirection(dir)
    navigate(url)
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  return (
    <StepsWrapper
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
    >
      <Text textId="editorPatchLocation" type="h5" />
      <Disclaimer>
        <Text textId="editorPatchLocationDescription" type="p" />
      </Disclaimer>
      <FlexRow className="row center">
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={() => goto(`${t("pathStep")}4`)}
        />
      </FlexRow>
    </StepsWrapper>
  )
}

export default Step5
