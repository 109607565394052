import styled from "styled-components/macro"
import { rgba } from "polished"

export const ScrollContent = styled.div`
  height: 100%;
  right: 0 !important;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: visible;
  transition: 0.3s;
`

export const VerticalThumb = styled.div`
  width: 6px;
  border-radius: 3px;
  background: ${rgba("black", 0.3)};
  cursor: pointer;
  box-shadow: 0 2px 5px ${rgba("black", 0.1)} !important;
  pointer-events: all;
  @media (max-width: 640px) {
    display: none;
  }
`

export const VerticalTrack = styled.div`
  position: absolute;
  z-index: 100;
  top: 5px !important;
  right: 2px !important;
  bottom: 0 !important;
  background: transparent !important;
`
