import styled from 'styled-components';
import { colours } from '../../../theme';
import iconArrowRight from '../../../assets/images/icon-arrow-right-white.svg';

const FooterTopLinksWrapper = styled.ul`
  display: flex;
  margin: 0 auto;
  list-style-type: none;
  max-width: 1920px;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20px 10px;

  li.group {
    display: flex;
    flex-direction: column;
    
    ul.items li {
      visibility: hidden;
    }

    &.open {
      .header .icon-arrow-down {
        transform: rotate(-90deg);
      }

      ul.items {
        max-height: 999px;
        transition: all 0.5s ease-in-out;
        
        li {
          visibility: visible;
        }  
      }
    }

    .header {
      display: flex;
      appearance: none;
      background-color: transparent;
      border: none;
      margin: 12px 0 0;
      padding: 0;
      text-align: left;
      font-family: "Futura", Calibri, sans-serif;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.167em;
      color: ${colours.white};
      flex-direction: row !important;
      justify-content: space-between;

      &.last {
        border: none;
        justify-content: flex-start;
        margin-bottom: 40px;
      }

      .icon-arrow-down {
        background: url(${iconArrowRight}) no-repeat;
        background-position: 50%;
        background-size: contain;
        margin: 0 5px 0 0;
        width: 7px;
        height: 14px;
        display: block;
        transform: rotate(90deg);
      }
    }

    ul {
      max-height: 0;
      overflow: hidden;
      border-bottom: 1px solid ${colours.white};
    }
  }

  @media (min-width: 1280px) {
    flex-direction: row !important;;
    padding: 50px 40px 20px;
    
    li.group, div.group {
      width: 20%;
      
      .header {
        font-family: "Futura", Calibri, sans-serif;
        font-size: 12px;
        line-height: 1.2rem;
        font-weight: 700;
        font-style: normal; 
        letter-spacing: 1.2px;
        margin: 0 0 16px;
        padding: 0;
        border: none; 
      }
      
      ul {
        max-height: none;
        border: none;
        
        li {
          font-size: 15px;
          font-family: 'Futura';
          font-style: normal;
          font-weight: 400;
          line-height: 1.2rem;
          visibility: visible !important;
          
          a {
            font-size: unset;
          }
        }
      }
  }
`;

export default FooterTopLinksWrapper;
