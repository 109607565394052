import styled from "styled-components"
import { colours } from "theme"

const NavigationWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;

  .toggle {
    border: none;
    background: transparent;
    height: inherit;
    padding: 0 15px;
    position: absolute;

    span,
    span:before,
    span:after {
      display: block;
      background: ${colours.primary};
      height: 2px;
      width: 18px;
      border-radius: 2px;
      position: relative;
      opacity: 1;
      overflow: visible;
      transition: 0.25s ease-in-out;
      content: "";
    }

    span {
      &:before {
        position: absolute;
        transform: translateY(-5px);
      }
      &:after {
        position: absolute;
        transform: translateY(5px);
      }
    }

    &[aria-expanded="true"] {
      span {
        height: 0;
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(135deg);
        }
      }
    }

    &:focus {
      outline: 0;
    }
  }
`

export default NavigationWrapper
