import React, { useEffect, Suspense } from "react"
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil"
import { LocationProvider } from "@reach/router"
import { scrollY, viewport } from "./store/atoms"
import { Layout, Loader, Modal, Head } from "./components"
import { modal } from "store/atoms"
import { GlobalStyle } from "theme"
import { AnimatePresence } from "framer-motion"
import Header from "components/Header"
import GlobalHeader from "components/GlobalHeader"
import Footer from "components/Footer"
import GlobalFooter from "components/GlobalFooter"
import Cookie from "components/Cookie"
import GlobalCookie from "components/GlobalCookie"

const App: React.FC = () => {
  const [view, setViewPort] = useRecoilState(viewport)
  const setScrollY = useSetRecoilState(scrollY)
  const showModal = useRecoilValue(modal)

  const resizeApp = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    const isMobile = width < 992

    if (view.width !== width) {
      setViewPort({ width, height, isMobile })
    }
  }

  useEffect(() => {
    window.addEventListener("resize touchmove", resizeApp)
    window.addEventListener("resize", resizeApp)
    window.addEventListener("scroll", scrollPage)
    resizeApp()
    window.scrollTo(0, 1)
  }, [resizeApp])

  const scrollPage = () => {
    setScrollY(window.scrollY)
  }

  const wrapperMode = process.env.REACT_APP_WRAPPER_MODE || ""

  return (
    <>
      <Suspense fallback={null}>
        <Head />

        {wrapperMode === "main" && <Cookie />}
        {wrapperMode === "global" && <GlobalCookie />}
        {wrapperMode === "main" && <Header />}
        {wrapperMode === "global" && <GlobalHeader />}
      </Suspense>
      <GlobalStyle />
      <AnimatePresence>
        {showModal.show && <Modal content={showModal.content} />}
      </AnimatePresence>
      <Suspense fallback={<Loader relative />}>
        <LocationProvider>
          <Layout />
        </LocationProvider>
      </Suspense>
      {wrapperMode === "main" && <Footer />}
      {wrapperMode === "global" && <GlobalFooter />}
    </>
  )
}

export default App
