import React from 'react';
import { RecoilRoot } from 'recoil';

import Brand from './Brand';
import TommyBorder from './TommyBorder';
import GlobalHeaderWrapper from './styles';

const GlobalHeader: React.FC = () => (
  <RecoilRoot>
    <GlobalHeaderWrapper>
      <TommyBorder />
      <Brand />
    </GlobalHeaderWrapper>
  </RecoilRoot>
);

export default GlobalHeader;
