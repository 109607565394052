import styled from "styled-components"
import { colours, breakpoints } from "../../../theme"

const GlobalFooterWrapper = styled.div`
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow-x: hidden;
  background-color: #022145;
  transition: 0.5s;

  &.open {
    height: 100%;
  }

  .title {
    font-size: 1.6rem;
    text-align: center;
    color: ${colours.white};
    margin-top: 20px;
  }

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    color: ${colours.white};
    appearance: none;
    border: none;
    line-height: 1.4rem;
    background-color: transparent;
    font-weight: bold;
    padding: 0;
  }

  .content {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    color: ${colours.white};
    cursor: pointer;
    flex-direction: column;

    ${breakpoints.md`
      flex-direction: row !important;;
    `}
  }

  .item {
    padding: 20px;
    display: block;
    color: ${colours.white};
    font-size: 0.94rem;
    margin: 0.8rem 0;
  }
`

export default GlobalFooterWrapper
