import React from "react"
import { Scrollbar } from "react-scrollbars-custom";
import { ScrollContent, VerticalThumb, VerticalTrack } from "./style"

interface ScrollBarsProps {
  onScroll?: (prevPos:number, pos:number) => void
  children: any
}

const Scrollbars = React.forwardRef(({children,onScroll}:ScrollBarsProps, ref) => {

  const handleOnScroll = (
    scrollValues: any,
    prevScrollValues: any
  ) => {
    onScroll && onScroll(scrollValues,prevScrollValues)
  };

  return (
    <Scrollbar
      // @ts-ignore
      ref={ref}
      // @ts-ignore
      onScroll={handleOnScroll}
      noScrollX
      wrapperProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            //@ts-ignore
            <ScrollContent {...restProps} ref={elementRef} />
          );
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            //@ts-ignore
            <VerticalTrack {...restProps} ref={elementRef} />
          );
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef } = props;
          return <VerticalThumb ref={elementRef} />;
        },
      }}
    >
      {children}
    </Scrollbar>
  );
})

export default Scrollbars