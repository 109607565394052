import React from 'react'
import { navigate, RouteComponentProps, useLocation } from "@reach/router"


const ScrollToTop: React.FC<RouteComponentProps> = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname])
  return children as unknown as JSX.Element;
}

export default ScrollToTop