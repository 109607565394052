export default {
  // Core Monos
  black: `rgb(0, 0, 0)`,
  white: `rgb(255, 255, 255)`,
  transparent: `rgba(0, 0, 0, 0)`,
  grey1: "#FEFEFE",
  grey2: "#F0F0F0",
  grey3: "#E5E5E5",
  grey4: "#DDDDDD",
  grey5: "#D0D0D0",

  darkGrey1: "#4F5E64",
  darkGrey2: "#293033",
  darkGrey3: "#23292C",
  darkGrey4: "#202324",
  darkGrey5: "#222425",

  background: "#E6E8EA",
  desktopStepsBackground: "#FAFBFC",
  galleryBackground: "#EEF1F1",
  bodyCopy: "#293033",
  bodyCopyAlt: "#00123E",
  iconButtonBackground: "#F7F7F8",

  primary: "#00184F",
  primary2: "#BC2B36",
  primary3: "#00C9B1",
  primary4: "#E3A2CD",

  secondary: "#D1D5E0",
  secondary2: "#CB0027",
  secondary3: "#DB4142",

  cloth1: "#ffffff",
  cloth2: "#989FBE",

  hoodBlack: "#262627",
  navy: "#2D394B",
  red: "#A01D2D",
  blue: '#022245',
}
