import React, { Suspense, useEffect } from "react"
import { Link, navigate, RouteComponentProps } from "@reach/router"
import { AnimatedContent, InlineButton, Text } from "components"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { CreateWrapper, Divider, ItemWrapper } from "./styles"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import Shirt from "assets/images/shirtScreenshot.png"
import Hood from "assets/images/hoodScreenshot.png"
import Shadow from "assets/images/shadow.png"
import { useTranslation } from "react-i18next"
import { currentBadge, rootPath, textureSettings, viewport } from "store/atoms"
import { defaultBadge, defaultDesign } from "store/constants"
import DataLayer from "utils/dataLayer"

const Create: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const rootUrl = useRecoilValue(rootPath)
  const dimensions = useRecoilValue(viewport)
  const resetDesign = useRecoilValue(defaultDesign)
  const resetBadge = useRecoilValue(defaultBadge)
  const updateTexture = useSetRecoilState(textureSettings)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const root = rootUrl === "/" ? rootUrl : `/${rootUrl}/`

  useEffect(() => {
    updateTexture(resetDesign)
    setCurrBadge(resetBadge)
  }, [])

  const goto = (url: any) => {
    navigate(url)
  }

  const sendToDataLayer = () => {
    DataLayer.cta("Start_customizing")
  }

  return (
    <CreateWrapper>
      <InlineButton
        textId="buttonExit"
        icon={<ChevronLeft />}
        onClick={() => goto(`${root}`)}
      />
      <Text textId="createPickItem" type="h5" />
      <ItemWrapper dimensions={dimensions}>
        <Suspense fallback={null}>

        <Link
          className="item"
          to={`${root}${t("pathEditorShirt")}`}
          onClick={sendToDataLayer}
        >
          <AnimatedContent trigger delay={0.2}>
            <img src={Shirt} className="shirt" />
            <img src={Shadow} className="shadow" alt="shadow" />
          </AnimatedContent>
        </Link>
        <Link
          className="item"
          to={`${root}${t("pathEditorHood")}`}
          onClick={sendToDataLayer}
        >
          <AnimatedContent trigger delay={0.4}>
            <img src={Hood} className="hood" />
            <img src={Shadow} className="shadow" alt="shadow" />
          </AnimatedContent>
        </Link>
        </Suspense>
        <Divider />
      </ItemWrapper>
    </CreateWrapper>
  )
}

export default Create
