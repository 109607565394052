import React, { useRef, useEffect } from "react"
import { colours } from "theme"
import { transform } from "framer-motion"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  currentBadge,
  currentCanvas,
  currentOffset,
  textureSettings,
} from "store/atoms"
import { badgeSettings, hotspots } from "store/constants"
import { Badge } from "utils/types"
import * as debugUV from "assets/3d/TommyHoodGuide.png"
import * as Label from "assets/3d/TommyShirtLabel.jpg"
import * as Collar from "assets/3d/TommyShirtLabel2.png"
import HoodLabels from "assets/3d/TommyHoodLabels.png"
import Tommy0 from "assets/images/tommy0.svg"
import Tommy1 from "assets/images/tommy1.svg"
import Tommy2 from "assets/images/tommy2.svg"
import Tommy3 from "assets/images/tommy3.svg"
import Tommy4 from "assets/images/tommy4.svg"
import Tommy5 from "assets/images/tommy5.svg"
import TommySleeve0 from "assets/images/tommySleeve0.svg"
import TommySleeve1 from "assets/images/tommySleeve1.svg"
import TommySleeve2 from "assets/images/tommySleeve2.svg"
import TommySleeve3 from "assets/images/tommySleeve3.svg"
import TommySleeve4 from "assets/images/tommySleeve4.svg"
import TommySleeve5 from "assets/images/tommySleeve5.svg"
import TommySleeveHood0 from "assets/images/tommySleeveHood0.svg"
import TommySleeveHood1 from "assets/images/tommySleeveHood1.svg"
import TommySleeveHood2 from "assets/images/tommySleeveHood2.svg"
import TommySleeveHood3 from "assets/images/tommySleeveHood3.svg"
import TommySleeveHood4 from "assets/images/tommySleeveHood4.svg"
import TommySleeveHood5 from "assets/images/tommySleeveHood5.svg"

const badgeImages = [Tommy0, Tommy1, Tommy2, Tommy3, Tommy4, Tommy5]
const badgeSleeveShirtImages = [
  TommySleeve0,
  TommySleeve1,
  TommySleeve2,
  TommySleeve3,
  TommySleeve4,
  TommySleeve5,
]
const badgeSleeveHoodImages = [
  TommySleeveHood0,
  TommySleeveHood1,
  TommySleeveHood2,
  TommySleeveHood3,
  TommySleeveHood4,
  TommySleeveHood5,
]

interface CanvasProps {
  debug?: boolean
  designSettings?: any
  setTexture?: (texture: any) => void
  section?: number
  badges: object[]
  modelType: string
}

let duration: number = 1
const cSize = 2048

let loadedImages: any[] = []

const CanvasTexture: React.FC<CanvasProps> = ({
  badges,
  debug,
  designSettings,
  modelType,
  section,
  setTexture,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const setCanvas = useSetRecoilState(currentCanvas)
  const setOffset = useSetRecoilState(currentOffset)
  const currBadge = useRecoilValue(currentBadge)
  const bSettings = useRecoilValue(badgeSettings)
  const spots = useRecoilValue(hotspots)
  const settings = useRecoilValue(textureSettings)
  const offset = useRecoilValue(currentOffset)

  const sliceSettings = {
    shirt: {
      left: -140,
      right: 140,
      center: 0,
      leftDiagonal: -100,
      rightDiagonal: 100,
      centerDiagonal: 20,
      slant: 200,
    },
    hood: {
      left: -120,
      right: 120,
      center: 0,
      leftDiagonal: 0,
      rightDiagonal: 115,
      centerDiagonal: 80,
      slant: 250,
      backHoodOffset: 38,
    },
  }

  useEffect(() => {
    const canvas = canvasRef.current!
    let requestId: any = null
    if (canvas) {
      const ctx = canvas.getContext("2d")

      const renderImage = (
        image: any,
        x: number,
        y: number,
        width: number,
        height: number
      ) => {
        const imgIndex = loadedImages.findIndex(
          (val: any) => val.url === image.toString()
        )
        if (imgIndex > -1) {
          ctx && ctx.drawImage(loadedImages[imgIndex].data, x, y, width, height)
        } else {
          const img = new Image()
          img.src = image
          img.onload = () => {
            const imgObject = {
              url: image.toString(),
              data: img,
            }
            loadedImages.push(imgObject)
            ctx && ctx.drawImage(img, x, y, width, height)
          }
        }
      }

      const currSettings = designSettings ? designSettings : settings

      let animate = duration
      const { split } = currSettings
      const { slant, position } = split

      if (position !== offset.position || slant !== offset.slant) {
        let newOffset = { ...offset }
        newOffset = { position, slant }
        setOffset(newOffset)
        animate = 0
      }

      const previousSlant = offset.slant
        ? offset.position === "left"
          ? 310
          : sliceSettings[modelType as "shirt"].slant
        : 0
      const nextSlant = split.slant
        ? split.position === "left"
          ? 310
          : sliceSettings[modelType as "shirt"].slant
        : 0

      let previousSlice = slant
        ? sliceSettings[modelType as "shirt"].centerDiagonal
        : sliceSettings[modelType as "shirt"].center
      if (offset.position === "left") {
        previousSlice = slant
          ? sliceSettings[modelType as "shirt"].leftDiagonal
          : sliceSettings[modelType as "shirt"].left
      } else if (offset.position === "right") {
        previousSlice = slant
          ? sliceSettings[modelType as "shirt"].rightDiagonal
          : sliceSettings[modelType as "shirt"].right
      }

      let nextSlice = slant
        ? sliceSettings[modelType as "shirt"].centerDiagonal
        : sliceSettings[modelType as "shirt"].center
      if (split.position === "left") {
        nextSlice = slant
          ? sliceSettings[modelType as "shirt"].leftDiagonal
          : sliceSettings[modelType as "shirt"].left
      } else if (split.position === "right") {
        nextSlice = slant
          ? sliceSettings[modelType as "shirt"].rightDiagonal
          : sliceSettings[modelType as "shirt"].right
      }

      let hoodSlant = false
      if (modelType === "hood" && slant) {
        hoodSlant = true
      }
      const previousBackSlice =
        previousSlice - sliceSettings.hood.backHoodOffset
      const nextBackSlice = nextSlice - sliceSettings.hood.backHoodOffset

      if (ctx) {
        const render = () => {
          ctx.clearRect(0, 0, cSize, cSize)
          ctx.imageSmoothingQuality = "high"

          const slanted = transform(
            animate,
            [0, duration],
            [previousSlant, nextSlant]
          )

          const sliced = transform(
            animate,
            [0, duration],
            [previousSlice, nextSlice]
          )

          const backSliced = transform(
            animate,
            [0, duration],
            [previousBackSlice, nextBackSlice]
          )

          const leftColour = currSettings.leftColour
          const rightColour = currSettings.rightColour

          let centreY = cSize * 0.5

          if (modelType === "hood") {
            //@ts-ignore
            ctx.fillStyle = colours[`${rightColour}`]
            ctx.fillRect(0, 0, cSize, cSize)
            centreY = cSize * 0.55
          }

          //// front - left
          //@ts-ignore
          ctx.fillStyle = colours[`${leftColour}`]
          ctx.beginPath()
          ctx.moveTo(0, 0)
          ctx.lineTo(cSize * 0.5 + sliced + slanted, 0)
          ctx.lineTo(cSize * 0.5 + sliced - slanted, centreY)
          ctx.lineTo(0, centreY)
          ctx.closePath()
          ctx.fill()

          //// front - right
          //@ts-ignore
          ctx.fillStyle = colours[`${rightColour}`]
          ctx.beginPath()
          ctx.moveTo(cSize * 0.5 + sliced + slanted, 0)
          ctx.lineTo(cSize, 0)
          ctx.lineTo(cSize, centreY)
          ctx.lineTo(cSize * 0.5 + sliced - slanted, centreY)
          ctx.closePath()
          ctx.fill()

          //// back - left
          //@ts-ignore
          ctx.fillStyle = colours[`${rightColour}`]
          ctx.beginPath()
          ctx.moveTo(0, centreY)
          ctx.lineTo(
            cSize * 0.5 - (hoodSlant ? backSliced : sliced) - slanted,
            centreY
          )
          ctx.lineTo(
            cSize * 0.5 - (hoodSlant ? backSliced : sliced) + slanted,
            cSize
          )
          ctx.lineTo(0, cSize)
          ctx.closePath()
          ctx.fill()

          //// back - right
          //@ts-ignore
          ctx.fillStyle = colours[`${leftColour}`]
          ctx.beginPath()
          ctx.moveTo(
            cSize * 0.5 - (hoodSlant ? backSliced : sliced) - slanted,
            centreY
          )
          ctx.lineTo(cSize, centreY)
          ctx.lineTo(cSize, cSize)
          ctx.lineTo(
            cSize * 0.5 - (hoodSlant ? backSliced : sliced) + slanted,
            cSize
          )
          ctx.closePath()
          ctx.fill()

          if (animate < duration - 0.01) {
            animate += (duration - animate) * 0.3
            requestId = requestAnimationFrame(render)
          }

          if (modelType === "shirt") {
            renderImage(Collar, 48, 601, 538, 201)
            renderImage(Label, 66, 919, 480, 83)
          }

          if (modelType === "hood") {
            //@ts-ignore
            ctx.fillStyle = colours[rightColour]
            ctx.beginPath()
            ctx.moveTo(1480, 880)
            ctx.lineTo(1930, 880)
            ctx.lineTo(1930, 1130)
            ctx.lineTo(1480, 1130)
            ctx.closePath()
            ctx.fill()

            // POUCH SLICES

            let previousPouchSlice = slant ? 0 : 285
            if (offset.position === "left" || slant) {
              previousPouchSlice = slant ? 0 : 570
            }
            if (offset.position === "right") {
              previousPouchSlice = 0
            }

            let nextPouchSlice = slant ? 0 : 285
            if (split.position === "left" || slant) {
              nextPouchSlice = slant ? 0 : 570
            }
            if (split.position === "right") {
              nextPouchSlice = 0
            }

            const pouchSlice = transform(
              animate,
              [0, duration - 0.01],
              [previousPouchSlice, nextPouchSlice]
            )

            ctx.fillStyle = colours[leftColour as "black"]
            ctx.beginPath()
            ctx.moveTo(cSize * 0.7, cSize * 0.338)
            ctx.lineTo(cSize * 0.7 + pouchSlice, cSize * 0.338)
            ctx.lineTo(cSize * 0.7 + pouchSlice, cSize * 0.338 + 400)
            ctx.lineTo(cSize * 0.7, cSize * 0.338 + 400)
            ctx.closePath()
            ctx.fill()

            // ctx.fillRect(cSize * 0.7, cSize * 0.338, pouchSlice, 400)

            ///////////HOOD SLICES
            if (split.position !== "center" || slant) {
              let fillColour = colours[leftColour as "black"]
              if (split.position === "left") {
                fillColour = colours[rightColour as "black"]
              }
              if (slant) {
                fillColour = colours[leftColour as "black"]
              }
              ctx.fillStyle = fillColour
              ctx.beginPath()
              ctx.moveTo(cSize * 0.4, 0)
              ctx.lineTo(cSize * 0.605, 0)
              ctx.lineTo(cSize * 0.605, 200)
              ctx.lineTo(cSize * 0.5, 340)
              ctx.lineTo(cSize * 0.4, 210)
              ctx.closePath()
              ctx.fill()
            }

            ///////// RIB COLOURS

            if (slant) {
              ctx.fillStyle = colours[rightColour as "black"]
              ctx.beginPath()
              ctx.bezierCurveTo(
                cSize * 0.36,
                cSize * 0.515,
                cSize * 0.5,
                cSize * 0.498,
                cSize * 0.63,
                cSize * 0.514
              )
              ctx.lineTo(cSize * 0.63, cSize * 0.55)
              ctx.lineTo(cSize * 0.36, cSize * 0.55)
              ctx.closePath()
              ctx.fill()

              ctx.beginPath()
              ctx.bezierCurveTo(
                cSize * 0.36,
                cSize * 0.962,
                cSize * 0.5,
                cSize * 0.945,
                cSize * 0.63,
                cSize * 0.96
              )
              ctx.lineTo(cSize * 0.63, cSize * 0.995)
              ctx.lineTo(cSize * 0.36, cSize * 0.995)
              ctx.closePath()
              ctx.fill()
            }

            
          }

          

          ////////////// GET BADGE

          const getBadge = (badge: Badge) => {
            const { position, size, type } = badge

            if (type < 0 || position < 0) {
              return null
            }
            const liveSpot = spots[modelType as "shirt"][position]

            const { canvasPos, allowed } = liveSpot
            // @ts-ignore
            const renderSize = Number.isInteger(size) ? allowed[size] : size
            const liveBadge = bSettings[type]
            let renderDimensions = {
              width: liveBadge.width,
              height: liveBadge.height,
            }

            if (renderSize === "small") {
              renderDimensions = {
                width: liveBadge.width * 0.5,
                height: liveBadge.height * 0.5,
              }
            } else if (renderSize === "medium") {
              renderDimensions = {
                width: liveBadge.width * 0.65,
                height: liveBadge.height * 0.65,
              }
            }

            if (modelType === "hood") {
              renderDimensions.width = renderDimensions.width * 0.8
              renderDimensions.height = renderDimensions.height * 0.8
            }

            if (position === 4) {
              renderImage(
                badgeSleeveShirtImages[type],
                canvasPos.x - renderDimensions.height / 2,
                canvasPos.y - renderDimensions.width / 2,
                renderDimensions.height,
                renderDimensions.width
              )
            } else {
              renderImage(
                badgeImages[type],
                canvasPos.x - renderDimensions.width / 2,
                canvasPos.y - renderDimensions.height / 2,
                renderDimensions.width,
                renderDimensions.height
              )
            }
          }

          if (section && section > 5 && currBadge.size > -1) {
            getBadge(currBadge)
          }

          badges.forEach((val, i) => {
            // @ts-ignore
            getBadge(val)
          })

          if (debug) {
            renderImage(debugUV, 0, 0, cSize, cSize)
          }
          if (modelType === "hood") {
           renderImage(HoodLabels, 0, 0, 2048, 2048)
          }
        }

       

        render()
        if (setTexture) {
          setTexture(canvas)
        } else {
          setCanvas(canvas)
        }

        return () => {
          cancelAnimationFrame(requestId)
        }
      }
    }
  }, [settings, debug, currBadge])

  return (
    <canvas
      ref={canvasRef}
      className="textureCanvas"
      width={cSize}
      height={cSize}
    ></canvas>
  )
}

export default CanvasTexture
