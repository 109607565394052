import React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { currGalleryItem } from "store/atoms"

const Head: React.FC =()=> {
  const { t } = useTranslation()
  const item = useRecoilValue(currGalleryItem)
  return (
    <HelmetProvider>
      <Helmet>
      <title>{t("metaTitle")}</title>
      <meta name="description" content={t("metaSocial")} />
      <meta name="description" content={t("metaSocial")} />
      <meta property="og:title" content={t("metaTitle")}/>
      <meta property="og:description" content={t("metaSocial")}/>
      <meta property="og:url" content={window.location.href}/>
      <meta name="twitter:title" content={t("metaTitle")}/>
      <meta name="twitter:description" content={t("metaSocial")} />
    </Helmet>
  </HelmetProvider>
  )
}

export default Head
