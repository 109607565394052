import React, { useEffect } from "react"
import { Overlay, Spinner, Wipe1 } from "./styles"
import Lottie from "lottie-react-web"
import animation from "./animation.json"

import { ReactComponent as HomeFlag } from "assets/images/homeFlag.svg"

interface LoaderProps {
  className?: string
  inline?: boolean
  inverse?: boolean
  relative?: boolean
  wipe?: boolean
}

const spinnerVariants = {
  active: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.4,
    },
  },
  inactive: {
    opacity: 0,
    scale: 0.2,
    delay: 0.5,
  },
}

const overlayVariants = {
  active: {
    opacity: 1,
    transition:{
      duration: 0
    }
  },
  inactive: {
    opacity: 0,
    delay: 0.2,
    duration: 0.3,
  },
}

const wipe1Variants = {
  active: {
    top: "100%",
  },
  inactive: {
    top: "-500%",
    transition: {
      duration: 2,
    },
  },
}

const Loader: React.FC<LoaderProps> = ({ className, inline, inverse, relative, wipe }) => {
  useEffect(() => {
    if (!inline) {
      document.body.style.overflow = "hidden"
    }

    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  return (
    <>
      <Overlay
        className={className}
        inline={inline}
        relative={relative}
        initial="inactive"
        animate="active"
        exit="inactive"
        variants={overlayVariants}
      >
        <Spinner
          initial="inactive"
          animate="active"
          exit="inactive"
          variants={spinnerVariants}
          inverse={inverse}
        >
          <Lottie
            options={{
              animationData: animation,
            }}
          />
        </Spinner>
      </Overlay>
      {/* {wipe && (
        <Wipe1
          initial="active"
          animate="active"
          exit="inactive"
          variants={wipe1Variants}
        />
      )} */}
    </>
  )
}

export default Loader
