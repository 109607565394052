import styled from "styled-components/macro"
import Tick from "assets/images/tick.svg"

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;

  .input {
    flex: none;
    outline: none;
    position: relative;
    height: 1.9rem;
    width: 1.9rem;
    margin-right: 1rem;
    background-color: white;
    background-image: url(${Tick});
    background-position: center;
    background-repeat: no-repeat;
    &:focus {
      outline: 1px solid blue;
    }
    &:not(:checked) {
      background-size: 0% 0%;
    }
    &:checked {
      background-size: 50% auto;
    }
    &:focus,
    textarea {
      outline: none !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .label,
  .label p {
    color: white;
    margin: 0;
    width: auto;
    font-size: 0.8rem;
    white-space: normal;
    text-align: left;
  }

  .error {
    display: inline-block;
    position: absolute;
    bottom: -1.2em;
    font-size: 1em;
    color: red;
    text-transform: lowercase;
    animation: fadeNSlide 0.3s both;
  }

  @media (min-width: 768px) {
    .wrapper {
      &.invalid {
        //margin-bottom: 0.62em;
      }
    }
  }

  @keyframes fadeNSlide {
    from {
      opacity: 0;
      transform: translateX(-0.62em);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
