import styled from 'styled-components';
import { colours, breakpoints } from '../../../theme';

const BrandWrapper = styled.div`
         padding: 0 25px;
         background-color: ${colours.white};
         display: flex;
         justify-content: center;
         align-items: flex-start;
         a {
            width: 100%;
            display: flex;
            align-items: center;
            ${breakpoints.md`
              max-width: 260px;
            `}
         }
         svg {
           display: block;
           width: 100%;
           height: auto;
           margin: 0 auto;
           ${breakpoints.md`
            max-width: 17rem;
          `}
         }
       `;

export default BrandWrapper;
