// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ReactComponent as Facebook } from '../../../../assets/images/facebook.svg';
import { ReactComponent as Pinterest } from '../../../../assets/images/pinterest.svg';
import { ReactComponent as Twitter } from '../../../../assets/images/twitter.svg';
import { ReactComponent as Instagram } from '../../../../assets/images/instagram.svg';
import { ReactComponent as Youtube } from '../../../../assets/images/youtube.svg';

import SocialIconsWrapper from './styles';

type SocialMediaProps = {
  socialMediaLinks: {
    [platform: string]: {
      url: string
      icon: string
    }
  }
  title: string
}

const SocialIcons: React.FC<SocialMediaProps> = ({
  socialMediaLinks,
  title,
}) => (
  <SocialIconsWrapper>
    <h4 className="header last">{title}</h4>
    <div className="socials">
      {socialMediaLinks.facebook && (
        <a href={socialMediaLinks.facebook.url}>
          <Facebook />
        </a>
      )}
      {socialMediaLinks.pinterest && (
        <a href={socialMediaLinks.pinterest.url}>
          <Pinterest />
        </a>
      )}
      {socialMediaLinks.twitter && (
        <a href={socialMediaLinks.twitter.url}>
          <Twitter />
        </a>
      )}
      {socialMediaLinks.instagram && (
        <a href={socialMediaLinks.instagram.url}>
          <Instagram />
        </a>
      )}
      {socialMediaLinks.youtube && (
        <a href={socialMediaLinks.youtube.url}>
          <Youtube />
        </a>
      )}
    </div>
  </SocialIconsWrapper>
);

export default SocialIcons;
