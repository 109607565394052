import React, { useEffect, useState } from "react"
import { transform } from "framer-motion"
import { AnimatedContent, Loader, Text } from "components"
import { useTranslation } from "react-i18next"
import {
  IntroWrapper,
  Description,
  ExpandSection,
  IntroCopy,
  MovingForwardWrapper,
  TogetherWrapper,
  Vid,
} from "./styles"
import ReactPlayer from "react-player/lazy"
import TommyOutline from "./TommyOutline"
import { useRecoilValue } from "recoil"
import { homeImagesLoaded, locale } from "store/atoms"
import { ReactComponent as StartQuote } from "assets/images/startQuote.svg"
import { ReactComponent as EndQuote } from "assets/images/endQuote.svg"
import { ReactComponent as HomeFlag } from "assets/images/homeFlag.svg"
import Vimeo from "@u-wave/react-vimeo"

const tracks = [
  { lang: "en", url: "https://vimeo.com/447855479/5cd34ab54a" },
  { lang: "fr", url: "https://vimeo.com/448809899/7dd683681f" },
  { lang: "nl", url: "https://vimeo.com/452196234/1a606851c5" },
  { lang: "de", url: "https://vimeo.com/452227642/835218aa83" },
  { lang: "es", url: "https://vimeo.com/452231385/caf2e6abc4" },
  { lang: "it", url: "https://vimeo.com/452256759/93eb80e158" },
  { lang: "ru", url: "https://vimeo.com/452273370/5bc4f11c7c" },
  { lang: "pl", url: "https://vimeo.com/452266819/50ec3efade" },
]

https: interface IntroProps {
  scrollPct: number
  view: {
    width: number
    height: number
    isMobile: boolean
  }
  completed: boolean
}

const Intro: React.FC<IntroProps> = ({ completed, view, scrollPct }) => {
  const imagesLoaded = useRecoilValue(homeImagesLoaded)
  const region = useRecoilValue(locale)
  const [copyExpanded, setCopyExpanded] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [vidSettings, setVidSettings] = useState(null)
  const togetherX = transform(scrollPct, [0, 40], [-30, 20])
  const { t } = useTranslation()

  const expandCopy = () => {
    setCopyExpanded(!copyExpanded)
  }

  const expandVariants = {
    active: {
      height: "100%",
    },
    inactive: {
      transition: {
        delay: 0.1,
      },
      height: 0,
    },
  }
  const expandChildVariants = {
    active: {
      opacity: 1,
      transition: {
        delay: 0.1,
      },
    },
    inactive: {
      opacity: 0,
    },
  }

  const fadeInVariants = {
    active: {
      opacity: 1,
      transition: {
        delay: !view.isMobile ? (completed ? 1 : 5) : 0,
        duration: 3,
      },
    },
  }

  useEffect(() => {
    let lang: any = tracks[0]

    const checkLocale = tracks.filter((l: any) => l.lang === region.shortCode)

    if (checkLocale.length > 0) {
      lang = checkLocale[0]
    }

    setVidSettings(lang)
  }, [])

  const vidLoaded = () => {
    setVideoLoaded(true)
  }

  const videoReady = (player: any) => {
    // @ts-ignore
    if (vidSettings.lang.toLowerCase() === "en") return
    if (vidSettings) {
      // @ts-ignore
      player.enableTextTrack(vidSettings.lang)
    }
  }
  return (
    <IntroWrapper completed={completed} dimensions={view}>
      {imagesLoaded && (
        <>
          <MovingForwardWrapper dimensions={view}>
            <Text
              className="moving-forward"
              textId="landingMovingForward"
              type="h1"
            />
            <TommyOutline imagesLoaded={completed} />
          </MovingForwardWrapper>

          <Text className="together" textId="landingTogether" type="h1" />
        </>
      )}
      <IntroCopy
        animate={scrollPct > 1 || !view.isMobile ? "active" : "inactive"}
        variants={fadeInVariants}
      >
        <StartQuote />
        <Text textId="landingIntro" type="p" />
        <EndQuote />
      </IntroCopy>
      <TogetherWrapper
        dimensions={view}
        animate={{
          position: "relative",
          left: `${togetherX}%`,
          opacity: scrollPct > 5 ? 1 : 0,
          transition: {
            stiffness: 20,
            damping: 90,
          },
        }}
      >
        <Text textId="landingTogether" type="h2" />
        <Text textId="landingTogether" type="h2" />
      </TogetherWrapper>
      <Vid dimensions={view}>
        {vidSettings && (
          <Vimeo
            // @ts-ignore
            video={vidSettings.url}
            autoplay
            responsive
            muted
            controls={true}
            onReady={videoReady}
            onTimeUpdate={vidLoaded}
            id="0"
          />
        )}
        {!videoLoaded && <Loader className="loader" />}
      </Vid>
      <Description expanded={copyExpanded}>
        <AnimatedContent trigger>
          <HomeFlag className="flag" />
        </AnimatedContent>
        <Text textId="landingDescription" type="p" />
        {/* <ExpandSection
          animate={copyExpanded || !view.isMobile ? "active" : "inactive"}
          variants={expandVariants}
        >
          <motion.div
            variants={expandChildVariants}
            animate={copyExpanded || !view.isMobile ? "active" : "inactive"}
          > */}
        <Text textId="landingDescriptionExpanded" type="p" />
        {/* </motion.div>
        </ExpandSection> 
        <FlexRow className="row" onClick={() => expandCopy()}>
          <Text
            textId={copyExpanded ? "landingReadLess" : "landingReadMore"}
            type="a"
          />
          <ChevronDownCircle />
        </FlexRow> */}
      </Description>
    </IntroWrapper>
  )
}

export default Intro
