import React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { currentBadge, stepDirection, textureSettings } from "store/atoms"
import { FlexRow } from "theme"
import { IconButton, NextButton, Text } from "components"
import { StepsWrapper } from "./styles"
import * as ShirtSlice from "assets/images/shirtSliceVertical.png"
import * as ShirtSlant from "assets/images/shirtSliceDiagonal.png"
import * as HoodSlice from "assets/images/hoodSliceVertical.png"
import * as HoodSlant from "assets/images/hoodSliceDiagonal.png"
import { useTranslation } from "react-i18next"

const shirtSlices = [ShirtSlice, ShirtSlant]
const hoodSlices = [HoodSlice, HoodSlant]

const Step1: React.FC<RouteComponentProps> = () => {
  const settings = useRecoilValue(textureSettings)
  const updateTexture = useSetRecoilState(textureSettings)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const direction = useRecoilValue(stepDirection)
  const setDirection = useSetRecoilState(stepDirection)
  const { t } = useTranslation()

  const chooseSlice = (index: number) => {
    const newSettings = { ...settings }
    const newSplit = { ...newSettings.split }
    newSplit.slant = index === 0 ? false : true
    newSettings.split = newSplit
    newSettings.badges = []
    setCurrBadge({ type: -1, position: -1, size: -1 })
    updateTexture(newSettings)
  }

  const nextStep = () => {
    setDirection("right")
    navigate(`${t("pathStep")}2`)
  }

  const { slant } = settings.split

  const getButtons = (slices: any[]) => {
    return slices.map((val, i) => {
      return (
        <IconButton
          key={`iconButtonSlice${i}`}
          onClick={() => chooseSlice(i)}
          active={(slant ? 1 : 0) === i}
          size={{ width: 140, height: 130 }}
        >
          {<img src={val} />}
        </IconButton>
      )
    })
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  const { modelType } = settings

  return (
    <StepsWrapper
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
    >
      <Text textId="editorChooseSlice" type="h5" />
      <FlexRow className="scalable">
        {getButtons(modelType === "shirt" ? shirtSlices : hoodSlices)}
      </FlexRow>
      <FlexRow className="row center">
        <NextButton onClick={nextStep} />
      </FlexRow>
    </StepsWrapper>
  )
}

export default Step1
