import styled from "styled-components/macro"
import { colours } from "theme"
import { lighten } from "polished"

interface ButtonProps {
  onClick: () => void
  colour?: string
  size?: string
  outline?: boolean
  minWidth?: number
}

export const ButtonWrapper = styled.div<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: ${(props) => props.minWidth}px;
  margin: 2rem auto 0 0;
  padding: 0.7rem ${(props) => (props.minWidth ? 0 : "2rem")} 0.6rem;
  color: ${(props) =>
    props.outline
      ? props.colour
        ? colours[props.colour as "primary"]
        : colours.primary
      : props.colour === "secondary"
      ? colours.primary
      : props.colour === "white"
      ? colours.primary
      : "white"};

  font-size: 0.7rem;
  font-family: "FuturaBold", futura, arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: ${(props) =>
    props.outline
      ? "transparent"
      : props.colour
      ? colours[props.colour as "primary"]
      : colours.primary};
  cursor: pointer;
  user-select: none;
  transition: 0.3s background;

      box-shadow: ${(props) =>
        props.outline
          ? `inset 0 0 0 2px ${
              props.colour
                ? colours[props.colour as "primary"]
                : colours.primary
            }`
          : "none"};}
  border-radius: 0.2rem;
  &:hover {
    background: ${(props) => {
      let bgColour = colours.primary
      if (props.colour) {
        bgColour = colours[props.colour as "primary"]
      } else {
        bgColour = colours.primary
      }
      if (props.outline) {
        return "transparent"
      }
      return lighten(0.15, bgColour)
    }};
    transition: 0.2s;
    box-shadow: ${(props) =>
      props.outline
        ? `inset 0 0 0 3px ${
            props.colour ? colours[props.colour as "primary"] : colours.primary
          }`
        : "none"};}
  }
`
