import React from 'react';
import { Link } from '@reach/router';
import { useRecoilValue } from 'recoil';

import { ReactComponent as LogoSvg } from '../../../assets/images/tommyLogo.svg';
import BrandWrapper from './styles';
import { size } from '../../../theme/breakpoints';
import { viewport } from '../../../store/atoms';
import LogoVideo from '../../../assets/videos/tommy-logo.mp4';
import TommyFlagLogo from '../../../assets/images/tommyFlagLogo.svg';

const Brand: React.FC = () => {
  const viewPort = useRecoilValue(viewport);
  const isMobile = viewPort.width < size.md;

  return (
    <BrandWrapper className="brand-logo">
      <Link to="/">
        {isMobile ? <img src={TommyFlagLogo} alt="tommy" width="25" height="16" /> : (
          <video
            autoPlay
            muted
            playsInline
            width="260"
            height="60"
            poster="../../assets/images/tommyLogo.jpg"
          >
            <source src={LogoVideo} type="video/mp4" />
            <LogoSvg />
          </video>
        )}
      </Link>
    </BrandWrapper>
  );
};

export default Brand;
