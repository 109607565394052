import styled from "styled-components/macro"
import { motion } from "framer-motion"
import { colours } from "theme"

interface CheckPointProps {
  active: boolean
  progressWidth: number
  visited: boolean
}

interface TickProps {
  active: boolean
}

export const TimelineWrapper = styled.div`
  position: relative;
  justify-content: space-around;
  align-items: center;
  flex-direction: row !important;
  width: 100%;
  padding: 3rem 0 3rem;

  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    width: 74%;
    height: 2px;
    background: ${colours.secondary};
  }

  @media (max-width: 600px) {
    left: 0;
    top: 0;
    position: absolute;
    height: 8px;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    &:before {
      height: 8px;
      width: 100%;
    }
    h5 {
      display: none !important;
    }
  }

  @media (min-width: 601px) {
    background: ${colours.grey1};
    &.finished {
      flex-direction: column !important;
      &:before {
        transition: 0.3s;
        background: none;
      }
      .checkpoint {
        display: none !important;
      }
    }

    h5 {
      margin: 1rem 0;
      font-size: 1.4rem;
      z-index: 1;
      color: ${colours.primary};
    }
  }
  @media (min-width: 992px) {
    background: transparent;
  }
`

export const Checkpoint = styled.div<CheckPointProps>`
  position: relative;
  z-index: 5;
  justify-content: flex-start;
  align-items: center;
  p {
    position: absolute;
    text-transform: uppercase;
    top: 0.3rem;
    font-size: 0.8rem;
    font-family: "futuraMedium", futura, arial, sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
    color: ${(props) =>
      props.active
        ? colours.secondary2
        : (props) => (props.visited ? colours.primary : colours.secondary)};
  }
  &:before {
    content: "";
    z-index: 4;
    position: absolute;
    top: -1px;
    left: 50%;
    transition: 0.3s width ease-out;
    width: ${(props) => props.progressWidth}px;
    height: 2px;
    background: ${colours.secondary2};
  }
  &:after {
    content: "";
    z-index: 5;
    position: absolute;
    cursor-pointer;
    top: ${(props) => (props.active ? "-11px" : "-13px")};
    width: ${(props) => (props.active ? "15px" : "20px")};
    height: ${(props) => (props.active ? "15px" : "20px")};
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    background: ${(props) =>
      props.active ? colours.secondary2 : colours.grey1};
    box-shadow: inset 0 0 ${(props) => (props.active ? 0 : "1px 2px")}
      ${(props) => (props.visited ? colours.primary : colours.secondary)};
    border: ${colours.grey1} solid 4px;
  }
  @media (max-width: 600px) {
    flex: 1;
    p, &:after{
      display: none !important;
    }
    &:before{
      left: 0;
      height: 8px;
      top: -5px;
    }
    border-right: 1px solid rgba(0,0,0,0.3);
    // background: ${colours.secondary2};
  }
`

export const TickPoint = styled(motion.div)<TickProps>`
    position: relative;
    z-index: 6;
    width: 30px;
    height: 30px;
    justify-content: center;
  &:after {
    content: "";
    z-index: 0;
    position: absolute;
    cursor-pointer;
    top: -5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    background: ${(props) =>
      props.active ? colours.secondary2 : colours.grey2};
    
    border: ${colours.grey1} solid 4px;
  }
  svg{
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: scale(1.2);
    margin: -0.15rem 0 0 0.05rem;
    path {
      fill: ${(props) => (props.active ? colours.white : colours.secondary)};
      
    }
    
  }

   @media (max-width: 600px) {
     svg{
      display: none !important;
     }

      &:after {
       content: ""
       position: absolute;
       z-index: 10;
       top: 10px;
       left: 0;
       right: 0;
       width: 100%;
       bottom: 0;
       border-radius: 0;
       border: none;
       height: 8px;
        background:${(props) => (props.active ? colours.secondary2 : "none")};
      }
    
   }

  @media (min-width: 992px) {
    &:after {
      background: ${(props) =>
        props.active ? colours.secondary2 : colours.grey4};
      border: ${colours.desktopStepsBackground} solid 4px;
    }
    svg {
      path {
        fill: ${(props) => (props.active ? colours.white : colours.grey1)};
      }
    }
  }
`
