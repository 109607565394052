// @ts-nocheck
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { Wrapper } from './styles';
import { isMobile } from '../../../../store/atoms';

interface AvailableMarkets {
  [name: string]: {language: string, url: string, selected?: boolean}[]
}

interface SelectedMarket {
  title: string;
  language: string;
}

interface MarketSelectProps {
  marketLinks: {
    goLabel: string;
    selected: SelectedMarket;
    available: AvailableMarkets
  }
  title: string
}

const MarketSelect: React.FC<MarketSelectProps> = ({
  marketLinks,
  title,
}) => {
  const [selectedMarket, setMarket] = useState([]);
  const [selectedUrl, setUrl] = useState('');
  const [isOpen, setOpen] = useState(false);
  const isMobileView = useRecoilValue(isMobile);

  const marketSelectHandler = (event: any) => {
    setMarket((marketLinks.available as any)[event.target.value]);
    setUrl((marketLinks.available as any)[event.target.value][0]?.url || '');
  };

  const languageSelectHandler = (event: any) => {
    setUrl(event.target.value);
  };

  const submitHandler = () => {
    if (selectedUrl) {
      window.location.href = `${window.location.protocol}${selectedUrl}`;
    }
  };

  const Button = () => (
    <button type="button" className="header" onClick={() => setOpen(!isOpen)}>
      {title}
      <i className="icon-arrow-down" />
    </button>
  );

  useEffect(() => {
    const selectedItem = marketLinks
      && marketLinks.available
      && Object.keys(marketLinks.available).find(
        (key) => key === marketLinks.selected.title,
      );

      console.log(selectedItem, marketLinks, marketLinks?.available[selectedItem])

    selectedItem && marketLinks && marketLinks.available && setMarket(marketLinks.available[selectedItem]);
  }, [marketLinks]);

  

  return (
    <Wrapper className={`${isMobileView && 'group'} ${isOpen ? 'open' : ''}`}>
      {!isMobileView ? <h4 className="header last">{title}</h4> : <Button />}
      <ul className="items">
        <li>
          <select onChange={marketSelectHandler}>
            {marketLinks && marketLinks.available && Object.keys(marketLinks.available).map(
              (key, index) => <option selected={marketLinks.selected.title === key} disabled={index === 0} key={key}>{key}</option>,
            )}
          </select>
          {selectedMarket.length > 1
            ? (
              <select onChange={languageSelectHandler}>
                {selectedMarket.map(
                  (item) => <option selected={marketLinks.selected.language === item.language} value={item.url} key={item.language}>{item.language}</option>,
                )}
              </select>
            )
            : (
              <input
                className="market-text"
                readOnly
                type="text"
                value={selectedMarket[0]?.language || ''}
              />
            )}
          <button type="button" onClick={submitHandler}>{marketLinks?.goLabel}</button>
        </li>
      </ul>
    </Wrapper>
  );
};

export default MarketSelect;
