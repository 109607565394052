import React, { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import { navigate } from "@reach/router"
import { useSetRecoilState } from "recoil"
import { useTranslation } from "react-i18next"
import { startAnimCompleted } from "store/atoms"
import { AnimatedContent, Button, Text } from "components"
import {
  CoCreation,
  CoCreateCopy,
  CoCreateHero,
  ExpandSection,
  InspiredWrapper,
  Square,
} from "./styles"
import CoCreateVid from "assets/videos/landingCoCreation.mp4"
import { ReactComponent as ArrowDown } from "assets/images/arrowDown.svg"
import DataLayer from "utils/dataLayer"

interface CoCreateProps {
  scrollPct: number
  view: {
    width: number
    height: number
    isMobile: boolean
  }
}

let count = 0

const CoCreate: React.FC<CoCreateProps> = ({ scrollPct, view }) => {
  const { t } = useTranslation()
  const [copyExpanded, setCopyExpanded] = useState(false)
  const setCompleted = useSetRecoilState(startAnimCompleted)
  const [contentOffset, setContentOffset] = useState(0)
  const togetherCreate = useRef<HTMLDivElement>(null)

  const expandCopy = () => {
    setCopyExpanded(!copyExpanded)
  }

  const goto = (url: string) => {
    setCompleted(true)
    navigate(url)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (togetherCreate) {
      const createDiv = togetherCreate.current!
      const offset = createDiv.getBoundingClientRect().height
      setContentOffset(offset)
    }
  })

  const expandVariants = {
    active: {
      height: "100%",
    },
    inactive: {
      transition: {
        delay: 0.1,
      },
      height: 0,
    },
  }
  const expandChildVariants = {
    active: {
      opacity: 1,
      transition: {
        delay: 0.1,
      },
    },
    inactive: {
      opacity: 0,
    },
  }

  const fadeInVariants = {
    active: {
      opacity: 1,
    },
  }

  return (
    <CoCreation animate={scrollPct > 28 ? "active" : "inactive"}>
      <Square
        dimensions={view}
        variants={{
          active: {
            left: 0,
            transition: {
              type: "spring",
              damping: 50,
            },
          },
          inactive: {
            left: -500,
            transition: { delay: 0.5 },
          },
        }}
      >
        <div ref={togetherCreate}>
          <Text textId="landingCoCreate" type="h5" />
        </div>
      </Square>
      <motion.div
        style={{ position: "absolute" }}
        initial="inactive"
        variants={{
          active: {
            opacity: 1,
            top: 0,
            transition: {
              type: "spring",
              damping: 50,
              delay: 0.3,
            },
          },
          inactive: { opacity: 0, top: -50 },
        }}
      >
        <ArrowDown className="arrow" />
      </motion.div>
      <CoCreateCopy expanded={copyExpanded} offset={contentOffset}>
        <CoCreateHero
          variants={{
            active: {
              opacity: 1,
              left: 0,
              transition: {
                type: "spring",
                damping: 50,
                delay: 0.2,
              },
            },
            inactive: {
              opacity: 0,
              left: view.isMobile ? 500 : "50%",
            },
          }}
        >
          <video autoPlay loop muted playsInline>
            <source src={CoCreateVid} type="video/mp4"></source>
          </video>
        </CoCreateHero>

        <AnimatedContent trigger={scrollPct > 32} delay={0.4}>
          <Text textId="landingCoCreateDescription" type="p" />
          <Text textId="landingCoCreateDescriptionExpandedV2" type="p" />
          <Button
            textId="buttonGetCreative"
            onClick={() => {
              DataLayer.cta("Get_Creative")
              goto(t("pathCoCreate"))
            }}
            colour="secondary2"
          />
          <InspiredWrapper>
            <Text textId="winningDesigns" type="h6" />
            <Text textId="galleryWinningShopV2" type="p" />
          </InspiredWrapper>
        </AnimatedContent>
      </CoCreateCopy>
    </CoCreation>
  )
}

export default CoCreate
