import React from 'react';
import { RecoilRoot } from 'recoil';

import GlobalFooterWrapper from './styles';
import TommyBorder from './TommyBorder';
import GlobalFooterTopLinks from './GlobalFooterTopLinks';
import GlobalFooterBottomLinks from './GlobalFooterBottomLinks';

const GlobalFooter: React.FC = () => (
    <GlobalFooterWrapper>
      <GlobalFooterTopLinks />
      <GlobalFooterBottomLinks />
      <TommyBorder />
    </GlobalFooterWrapper>
);

export default GlobalFooter;
