import React, { createElement, useEffect } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { currentBadge, textureSettings, stepDirection } from "store/atoms"
import { hotspots } from "store/constants"
import { FlexRow } from "theme"
import { IconButton, InlineButton, NextButton, Text } from "components"
import { StepsWrapper } from "./styles"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { ReactComponent as Tommy0 } from "assets/images/tommy0.svg"
import { ReactComponent as Tommy1 } from "assets/images/tommy1.svg"
import { ReactComponent as Tommy2 } from "assets/images/tommy2.svg"
import { ReactComponent as Tommy3 } from "assets/images/tommy3.svg"
import { ReactComponent as Tommy4 } from "assets/images/tommy4.svg"
import { ReactComponent as Tommy5 } from "assets/images/tommy5.svg"
import { useTranslation } from "react-i18next"

const badges = [Tommy0, Tommy1, Tommy2, Tommy3, Tommy4, Tommy5]

const Step6: React.FC<RouteComponentProps> = () => {
  const currBadge = useRecoilValue(currentBadge)
  const currSettings = useRecoilValue(textureSettings)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const positions = useRecoilValue(hotspots)
  const direction = useRecoilValue(stepDirection)
  const setDirection = useSetRecoilState(stepDirection)
  const { t } = useTranslation()

  const goto = (url: string) => {
    const dir = url === `${t("pathStep")}7` ? "right" : "left"
    setDirection(dir)
    navigate(url)
  }

  const chooseBadge = (index: number) => {
    const newBadge = { ...currBadge }
    newBadge.size = index
    setCurrBadge(newBadge)
  }

  useEffect(() => {
    if (currBadge.position < 0) {
      navigate(`${t("pathStep")}4`)
    }
    if (currBadge.size < 0) {
      chooseBadge(0)
    }
  }, [])

  if (currBadge.position < 0) {
    return null
  }
  const position = positions[currSettings.modelType as "shirt"].filter(
    (val) => val.id === currBadge.position
  )

  const sizes: string[] = position[0].allowed

  const getButtons = () => {
    return sizes.map((val, i) => {
      return (
        <IconButton
          badge
          key={`iconButton${i}`}
          onClick={() => chooseBadge(i)}
          active={currBadge.size === i}
          size={{ width: 161, height: 130 }}
          badgeSize={i}
          portrait={currBadge.type === 5}
        >
          {createElement(badges[currBadge.type])}
        </IconButton>
      )
    })
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  return (
    <StepsWrapper
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
    >
      <Text textId="editorPatchSize" type="h5" />
      <FlexRow className="row center icon-buttons scalable">
        {getButtons()}
      </FlexRow>
      <FlexRow className="row center">
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={() => goto(`${t("pathStep")}4`)}
        />
        <NextButton onClick={() => goto(`${t("pathStep")}7`)} />
      </FlexRow>
    </StepsWrapper>
  )
}

export default Step6
