import styled from 'styled-components';
import { breakpoints, colours } from '../../../theme';

export const Overlay = styled.div`
  min-height: 100%;
  padding: 70px 0;
  justify-content: center;
  align-items: center;
  
  @media (min-width: 320px) {
    padding: 70px 5px;
  }
`


export const Wrapper = styled.div`
  border-radius: 5px;
  padding: 20px 20px 30px;
  margin: 0;
  flex-direction: column;
  overflow: auto;
  position: relative;
  color: #fff;
  background-color: rgba(0,23,79,.9);
  max-width: 700px;
  
  ${breakpoints.md`
    width: 90vw;
    padding: 40px 40px 30px;
  `}
  
  ${breakpoints.lg`
    width: 50vw;
  `}
`;

export const Title = styled.h4`
  margin: 0 0 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .86px;
  line-height: 24px !important;
  color: ${colours.white};
  flex-shrink: 0;
`;

export const Field = styled.div`
  margin: 0 0 8px;
  flex-shrink: 0;
`;

export const Description = styled.p`
  font-family: Futura,Calibri,sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: normal;
  display: inline-block;
  margin: 0 0 30px;
  color: ${colours.white};
  flex-shrink: 0;
  
  a {
    display: contents !important;
    color: ${colours.white} !important;
    text-decoration: underline !important;
    font-size: unset;
    :hover {
      color: unset;
    }
  }
`;

export const CheckBox = styled.input`
  && {
    margin-top: 0;
    margin-right: 10px;
    appearance: revert;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  height: 10px;
  font-family: Futura,Calibri,sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: .86px;
  line-height: 24px;
  margin-bottom: 10px;
  color: ${colours.white};
  flex-shrink: 0;
`;
