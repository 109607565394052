import React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { textureSettings, stepDirection } from "store/atoms"
import { FlexRow } from "theme"
import { InlineButton, NextButton, Text } from "components"
import { StepsWrapper, Swatch, SwatchWrapper, Switch } from "./styles"
import { ReactComponent as SwitchIcon } from "assets/images/switch.svg"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { useTranslation } from "react-i18next"

const slices = ["leftColour", "rightColour"]

const Step3: React.FC<RouteComponentProps> = () => {
  const currTextureSettings = useRecoilValue(textureSettings)
  const updateTexture = useSetRecoilState(textureSettings)
  const direction = useRecoilValue(stepDirection)
  const setDirection = useSetRecoilState(stepDirection)
  const { t } = useTranslation()

  const swapColour = () => {
    const newSettings = { ...currTextureSettings }
    newSettings.leftColour = currTextureSettings.rightColour
    newSettings.rightColour = currTextureSettings.leftColour
    updateTexture(newSettings)
  }

  const goto = (url: string) => {
    const dir = url === `${t("pathStep")}4` ? "right" : "left"
    setDirection(dir)
    navigate(url)
  }

  const getColours = () => {
    return slices.map((val, i) => {
      return (
        <Swatch
          key={`Swatch${i}`}
          //@ts-ignore
          colour={currTextureSettings[`${val}`]}
        />
      )
    })
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  return (
    <StepsWrapper
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
    >
      <Text textId="editorFabricColour" type="h5" />
      <FlexRow className="row">
        <SwatchWrapper onClick={swapColour}>
          {getColours()}
          <Switch>
            <SwitchIcon />
          </Switch>
        </SwatchWrapper>
      </FlexRow>
      <FlexRow className="swatch-labels">
        <Text textId="editorLeft" type="h6" />
        <Text textId="editorRight" type="h6" />
      </FlexRow>
      <FlexRow className="row center">
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={() => goto(`${t("pathStep")}2`)}
        />
        <NextButton onClick={() => goto(`${t("pathStep")}4`)} />
      </FlexRow>
    </StepsWrapper>
  )
}

export default Step3
