import styled from 'styled-components';
import { colours, breakpoints } from '../../../theme';

const GlobalFooterBottomLinksWrapper = styled.footer`
  background: #cc0c2f;
  padding: 2.5rem 3rem;
  font-size: .9rem;
  display: flex;
  overflow-x: hidden;

  > div {
    display: none;
    flex: 0 0 100%;
    max-width: 100%;

    &.left {
      display: none;
    }

    ${breakpoints.md`
      flex: 0 0 50%;
      max-width: 50%;

      
    `}

    &.right {
      display: flex;

      a {
        float: left;
      }
    }

    a {
      color: ${colours.white};
      font-size: .9rem;
      margin-left: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 375px){
    padding: 2rem;
  }

  @media (min-width:870px) {
    > div {
      &.left {
        display: block;
      }

      &.right {
        justify-content: flex-end;
        flex-direction: row;
      }
    }
  }
`;

export default GlobalFooterBottomLinksWrapper;
