import { atom } from "recoil"

export const hotspots = atom({
  key: "hotspots",
  default: {
    shirt: [
      {
        id: 0,
        x: 29,
        y: 11,
        allowed: ["small"],
        canvasPos: { x: 885, y: 200 },
        side: "front",
      },
      {
        id: 1,
        x: 65,
        y: 11,
        allowed: ["small"],
        canvasPos: { x: 1163, y: 200 },
        side: "front",
      },
      {
        id: 2,
        x: 48,
        y: 36,
        allowed: ["medium", "large"],
        canvasPos: { x: 1025, y: 450 },
        side: "front",
      },
      {
        id: 3,
        x: 62,
        y: 25,
        allowed: ["small", "medium"],
        canvasPos: { x: 1128, y: 350 },
        side: "front",
      },
      {
        id: 4,
        x: 91,
        y: 24,
        allowed: ["small"],
        canvasPos: { x: 1500, y: 250 },
        side: "front",
      },
      {
        id: 5,
        x: 29,
        y: 85,
        allowed: ["small", "medium"],
        canvasPos: { x: 885, y: 900 },
        side: "front",
      },
      {
        id: 6,
        x: 64,
        y: 85,
        allowed: ["small", "medium"],
        canvasPos: { x: 1165, y: 900 },
        side: "front",
      },

      {
        id: 7,
        x: 25,
        y: 11,
        allowed: ["small"],
        canvasPos: { x: 885, y: 1220 },
        side: "back",
      },
      {
        id: 8,
        x: 64,
        y: 11,
        allowed: ["small"],
        canvasPos: { x: 1163, y: 1220 },
        side: "back",
      },
      {
        id: 9,
        x: 46,
        y: 30,
        allowed: ["medium", "large"],
        canvasPos: { x: 1025, y: 1400 },
        side: "back",
      },
      {
        id: 10,
        x: 26,
        y: 85,
        allowed: ["small", "medium"],
        canvasPos: { x: 884, y: 1900 },
        side: "back",
      },
      {
        id: 11,
        x: 64,
        y: 85,
        allowed: ["small", "medium"],
        canvasPos: { x: 1163, y: 1900 },
        side: "back",
      },
    ],

    hood: [
      {
        id: 0,
        x: 29,
        y: 20,
        allowed: ["small"],
        canvasPos: { x: 904, y: 465 },
        side: "front",
      },
      {
        id: 1,
        x: 65,
        y: 20,
        allowed: ["small"],
        canvasPos: { x: 1144, y: 465 },
        side: "front",
      },
      {
        id: 2,
        x: 48,
        y: 40,
        allowed: ["medium", "large"],
        canvasPos: { x: 1024, y: 625 },
        side: "front",
      },
      {
        id: 3,
        x: 60,
        y: 30,
        allowed: ["small", "medium"],
        canvasPos: { x: 1110, y: 560 },
        side: "front",
      },
      {
        id: 4,
        x: 87,
        y: 28,
        allowed: ["small"],
        canvasPos: { x: 1365, y: 380 },
        side: "front",
      },
      {
        id: 5,
        x: 37,
        y: 77,
        allowed: ["small"],
        canvasPos: { x: 1640, y: 800 },
        side: "front",
      },
      {
        id: 6,
        x: 59,
        y: 76.7,
        allowed: ["small"],
        canvasPos: { x: 1795, y: 800 },
        side: "front",
      },

      {
        id: 7,
        x: 48.5,
        y: 40,
        allowed: ["medium", "large"],
        canvasPos: { x: 1024, y: 1550 },
        side: "back",
      },
      {
        id: 8,
        x: 30,
        y: 78,
        allowed: ["small", "medium"],
        canvasPos: { x: 904, y: 1850 },
        side: "back",
      },
      {
        id: 9,
        x: 66,
        y: 78,
        allowed: ["small", "medium"],
        canvasPos: { x: 1145, y: 1850 },
        side: "back",
      },
    ],
  },
})

export const badgeSettings = atom({
  key: "badgeSettings",
  default: [
    {
      id: 1,
      width: 250,
      height: 152,
      allowed: {
        shirt: {
          vertical: {
            left: [1, 2, 3, 4, 6, 7, 9, 10],
            center: [0, 1, 3, 4, 5, 6, 7, 8, 10, 11],
            right: [0, 2, 4, 5, 8, 9, 11],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 10, 11],
            center: [0, 4, 6, 8, 10],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
          },
        },
        hood: {
          vertical: {
            left: [1, 2, 3, 4, 5, 6, 7, 8],
            center: [0, 1, 3, 4, 5, 6, 8, 9],
            right: [0, 2, 4, 5, 6, 7, 9],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 9],
            center: [0, 4, 5, 6, 8],
            right: [0, 2, 4, 5, 6, 7, 8],
          },
        },
      },
    },
    {
      id: 2,
      width: 250,
      height: 142,
      allowed: {
        shirt: {
          vertical: {
            left: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            center: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 10, 11],
            center: [0, 4, 6, 8, 10],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
          },
        },
        hood: {
          vertical: {
            left: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            center: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 9],
            center: [0, 4, 5, 6, 8],
            right: [0, 2, 4, 5, 6, 7, 8],
          },
        },
      },
    },

    {
      id: 3,
      width: 250,
      height: 52,
      allowed: {
        shirt: {
          vertical: {
            left: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            center: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8],
            center: [0, 4, 6, 8, 10],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9],
          },
        },
        hood: {
          vertical: {
            left: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            center: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 9],
            center: [0, 4, 5, 6, 8],
            right: [0, 2, 4, 5, 6, 7, 8],
          },
        },
      },
    },
    {
      id: 4,
      width: 250,
      height: 61,
      allowed: {
        shirt: {
          vertical: {
            left: [1, 2, 3, 4, 6, 7, 9, 10],
            center: [0, 1, 3, 4, 5, 6, 7, 8, 10, 11],
            right: [0, 2, 4, 5, 8, 9, 11],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 10, 11],
            center: [0, 4, 6, 8, 10],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
          },
        },
        hood: {
          vertical: {
            left: [1, 2, 3, 4, 5, 6, 7, 8],
            center: [0, 1, 3, 4, 5, 6, 8, 9],
            right: [0, 2, 4, 5, 6, 7, 9],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 9],
            center: [0, 4, 5, 6, 8],
            right: [0, 2, 4, 5, 6, 7, 8],
          },
        },
      },
    },
    {
      id: 5,
      width: 250,
      height: 167,
      allowed: {
        shirt: {
          vertical: {
            left: [1, 2, 3, 4, 6, 7, 9, 10],
            center: [0, 1, 3, 4, 5, 6, 7, 8, 10, 11],
            right: [0, 2, 4, 5, 8, 9, 11],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 10, 11],
            center: [0, 4, 6, 8, 10],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
          },
        },
        hood: {
          vertical: {
            left: [1, 2, 3, 4, 5, 6, 7, 8],
            center: [0, 1, 3, 4, 5, 6, 8, 9],
            right: [0, 2, 4, 5, 6, 7, 9],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 9],
            center: [0, 4, 5, 6, 8],
            right: [0, 2, 4, 5, 6, 7, 8],
          },
        },
      },
    },
    {
      id: 6,
      width: 165,
      height: 250,
      allowed: {
        shirt: {
          vertical: {
            left: [1, 2, 3, 4, 6, 7, 9, 10],
            center: [0, 1, 3, 4, 5, 6, 7, 8, 10, 11],
            right: [0, 2, 4, 5, 8, 9, 11],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 10, 11],
            center: [0, 4, 6, 8, 10],
            right: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
          },
        },
        hood: {
          vertical: {
            left: [1, 2, 3, 4, 5, 6, 7, 8],
            center: [0, 1, 3, 4, 5, 6, 8, 9],
            right: [0, 2, 4, 5, 6, 7, 9],
          },
          slant: {
            left: [0, 3, 4, 5, 6, 8, 9],
            center: [0, 4, 5, 6, 8],
            right: [0, 2, 4, 5, 6, 7, 8],
          },
        },
      },
    },
  ],
})

export const defaultDesign = atom({
  key: "defaultDesign",
  default: {
    split: {
      slant: false,
      position: "center",
    },
    leftColour: "",
    rightColour: "",
    modelType: "shirt",
    badges: [],
    imageUrl: "",
  },
})

export const defaultBadge = atom({
  key: "defaultBadge",
  default: { type: -1, position: -1, size: -1 },
})
