import React, { Suspense } from "react"

import { Html } from "drei"
import {Loader} from "components"
import { AnimatePresence } from "framer-motion"
//@ts-ignore
import { a } from "react-spring/three"
import { HotSpotsProps } from "utils/types"
import Model from "./Model"
import { Hotspot } from "./styles"
import { Vector2 } from "three"

interface ObjectProps {
  anisotropy: number
  bump?: any
  dimensions?: {
    width: number
    height: number
  }
  loadedScene: () => void
  mode?: string
  model: string
  normal?: any
  texture?: any
  hotspots?: HotSpotsProps[]
  isZoom?: boolean
  position?: number[]
  rotation?: number[]
  selectHotSpot?: (id: number, allowed: string[]) => void
  scale: number[]
  spin?: number
  view?: number
  screenshot?: boolean
}

const Object: React.FC<ObjectProps> = ({
  anisotropy,
  bump,
  dimensions,
  loadedScene,
  model,
  mode,
  normal,
  hotspots,
  isZoom,
  position,
  rotation,
  screenshot,
  selectHotSpot,
  scale,
  spin,
  texture,
  view,
}) => {
  let meshRotation = 0

  if (!screenshot) {
    switch (view) {
      case 1:
        meshRotation = Math.PI / 2
        break
      case 2:
        meshRotation = Math.PI
        break
      default:
        meshRotation = 0
    }
    if (spin) {
      meshRotation = meshRotation + spin
    }
  }
  const handleLoadedScene = () => {
    loadedScene()
  }

  const getHotSpots = () => {
    const modifier = view === 2 ? 6 : 0
    return (
      hotspots &&
      hotspots.map((val, i) => {
        return (
          <Hotspot
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                delay: 0.5 + 0.03 * i,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.01,
              },
            }}
            key={`hs${val.id}`}
            position={val}
            // @ts-ignore
            onClick={() => selectHotSpot(val.id, val.allowed)}
          >
            +
          </Hotspot>
        )
      })
    )
  }

  if (bump) {
    bump.flipY = false
  }

  if (normal) {
    normal.flipY = false
  }

  return (
    <a.group>
      <Suspense fallback={null}>
        <Model
          gltfModel={model}
          loadedScene={handleLoadedScene}
          meshRotation={meshRotation}
          position={position}
          rotation={rotation}
          scale={scale}
          mode={mode}
        >
          <meshStandardMaterial
            attach="material"
            side={2}
            normalMap={normal}
            normalScale={new Vector2(0.2, 0.2)}
          >
            <canvasTexture
              anisotropy={anisotropy}
              attach="map"
              image={texture}
              onUpdate={(s) => (s.needsUpdate = true)}
              flipY={false}
            />
          </meshStandardMaterial>

          {!isZoom && hotspots && hotspots.length > 0 && (
            <Html fullscreen className="hotspot-holder">
              <AnimatePresence>{getHotSpots()}</AnimatePresence>
            </Html>
          )}
        </Model>
      </Suspense>
    </a.group>
  )
}

export default Object
