import React from "react"
import { useTranslation } from "react-i18next"
import { ButtonWrapper } from "./styles"

interface ButtonProps {
  textId: string
  onClick: () => void
  children?: any
  colour?: string
  minWidth?: number
  size?: string
  outline?: boolean
}

const Button: React.FC<ButtonProps> = ({
  colour,
  minWidth,
  onClick,
  outline,
  size,
  textId,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonWrapper
      className="button"
      onClick={onClick}
      colour={colour}
      outline={outline}
      size={size}
      minWidth={minWidth}
    >
      {t(textId)}
    </ButtonWrapper>
  )
}

export default Button
