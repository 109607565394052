// @ts-nocheck
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import UTILS from '../utils';
import { locale, isMobile } from '../../../store/atoms';
import SocialIcons from './SocialIcons';
import FooterTopLinksWrapper from './styles';
import MarketSelect from './MarketSelect';
import Stores from '../../../utils/stores/stores'

interface FooterProps {
  itemName: string
}

const FooterTopLinks = () => {
  const { longCode: localeCode } = useRecoilValue(locale);
  const [isOpen, setOpen] = useState('');
  const [items, setItems] = useState<any>([]);
  const [socialMediaState, setSocialMedia] = useState<any>({});
  const [marketState, setMarketState] = useState<any>({});

  const isMobileView = useRecoilValue(isMobile);

  useEffect(() => {
    const {
      middle, socialMedia, bottom, market
    } = UTILS.getDataFromLang(localeCode).footer;
    const { langSelect } = Stores.getStoreInfo(localeCode);

    const onlyNamed = middle.filter(({ name }: { name: any }) => name);
    const socialMediaTitle = bottom.find((item: any) => item.type === 'socialMedia');
    const languageSelectTitle = 'COUNTRY / LANGUAGE';

    console.log(langSelect)

    setMarketState({
      market : {...market, selected: langSelect},
      title:languageSelectTitle
    });

    setSocialMedia({
      socialMedia,
      title: socialMediaTitle.name,
    });

    setItems(onlyNamed);
  }, [localeCode]);

  return (
    <FooterTopLinksWrapper>
      {items.map((item: any) => {
        const { name, nav } = item;
        const toggleItem = isOpen !== name ? name : '';
        const Header = ({ itemName }: FooterProps) => <h4 className="header">{itemName}</h4>;

        const Button = ({ itemName }: FooterProps) => (
          <button type="button" className="header" onClick={() => setOpen(toggleItem)}>
            {itemName}
            <i className="icon-arrow-down" />
          </button>
        );

        return (
          <li className={`group ${isOpen === name ? 'open' : ''}`} key={`footer__${name}`}>
            {isMobileView ? <Button itemName={name} /> : <Header itemName={name} />}
            <ul className="items">
              {nav.map((navColumn: any) => {
                const { name: navName, url } = navColumn;

                let checkedNavName = navName;
                let checkedUrl = url;
                if (navName === 'Corporate Responsibility') {
                  checkedNavName = 'Sustainability';
                  checkedUrl = 'https://global.tommy.com/en_int/about-us-corporate-sustainability';
                }

                return (
                  <li
                    className="item"
                    key={`footer__${checkedNavName}`}
                  >
                    <a className="link" href={checkedUrl} rel="noopener noreferrer">
                      {checkedNavName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
      {isMobileView && <MarketSelect title={marketState.title} marketLinks={marketState.market} />}
      <div className="group">
        {!isMobileView && <MarketSelect title={marketState.title} marketLinks={marketState.market} />}
        <SocialIcons title={socialMediaState.title} socialMediaLinks={socialMediaState?.socialMedia || {}} />
      </div>
    </FooterTopLinksWrapper>
  );
};

export default FooterTopLinks;
