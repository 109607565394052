import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { size } from '../../../theme/breakpoints';
import { viewport } from '../../../store/atoms';
import NavigationItems from './NavigationItems';
import NavigationWrapper from './styles';

const Navigation: React.FC = () => {
  const [open, setOpen] = useState(false);
  const viewPort = useRecoilValue(viewport);
  const isMobile = viewPort.width < size.md;

  return (
    <NavigationWrapper>
      {isMobile && (
        <button
          className="toggle"
          aria-expanded={open}
          onClick={() => setOpen(!open)}
          aria-controls="navigation__categories"
        >
          <span className="bar" />
        </button>
      )}
      <NavigationItems open={open} />
    </NavigationWrapper>
  );
};

export default Navigation;
