import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil"
import {
  currentBadge,
  design3DCanvas,
  justCreated,
  modal,
  textureSettings,
  stepDirection,
  submittedDesign,
} from "store/atoms"
import { hotspots } from "store/constants"
import { Scene, Loader, Button, Text } from "components"
import { LoaderWrapper, StepsWrapper } from "./styles"
import DesignAPIService from "utils/designs/service"
import dataToBlob from "utils/dataToBlob"
import { useTranslation } from "react-i18next"
import DataLayer from "utils/dataLayer"

let init = false

const Step8: React.FC<RouteComponentProps> = () => {
  const [saving, setSaving] = useState(false)
  const [takeScreenshot, setTakeScreenshot] = useState(false)
  const [settings, updateSettings] = useRecoilState(textureSettings)
  const spots = useRecoilValue(hotspots)
  const canvas3D = useRecoilValue(design3DCanvas)
  const setCreated = useSetRecoilState(justCreated)
  const currBadge = useRecoilValue(currentBadge)
  const setModal = useSetRecoilState(modal)
  const direction = useRecoilValue(stepDirection)
  const setDirection = useSetRecoilState(stepDirection)
  const setSubmittedDesign = useSetRecoilState(submittedDesign)
  const [sHot, setsHot] = useState("")
  const { t } = useTranslation()

  const goto = (url: string) => {
    const dir = "left"
    setDirection(dir)
    navigate(url)
    setTakeScreenshot(false)
  }

  useEffect(() => {
    if (currBadge.type < 0) {
      navigate(`${t("pathStep")}1`)
    }
  }, [])

  const submitDesign = () => {
    setSaving(true)
    saveDesign()
  }

  const convertBadge = () => {
    const { position, size } = currBadge
    if (position < 0) {
      return currBadge
    }

    const liveSpot = spots[settings.modelType as "shirt"][position]
    const { allowed } = liveSpot
    return { ...currBadge, size: allowed[size] }
  }

  const saveDesign = () => {
    const design = JSON.parse(JSON.stringify(settings))
    const finalTexture = JSON.parse(JSON.stringify(settings))

    const doesBadgeExist =
      settings.badges.filter(
        // @ts-ignore
        (b) => b.type === currBadge.type
      ).length > 0

    if (
      !doesBadgeExist &&
      currBadge.type > -1 &&
      currBadge.position > -1 &&
      currBadge.size > -1
    ) {
      const newBadge = convertBadge()

      design.badges.push(newBadge)
      finalTexture.badges.push(currBadge)
      updateSettings(finalTexture)
    }

    // @ts-ignore
    //const sShot = canvas3D.toDataURL("image/png")
    //const blob = dataToBlob(sShot)

    // @ts-ignore
    // DesignAPIService.uploadFile(blob)
    //   //@ts-ignore
    //   .then((res) => {
    //     setSaving(false)
    //     design.imageUrl = res.url
    //     // @ts-ignore
    //     DesignAPIService.saveDesign(design).then((d) => {
    //       setSubmittedDesign({ designId: d.designId })
    //     })
    //     setCreated(true)
    //     setModal({ ...modal, show: true, content: 2 })
    //     DataLayer.cta("Submit_Design")
    //   })
    //   .catch(() => {
    //     setSaving(false)
    //     setModal({ ...modal, show: true, content: 1 })
    //   })

    setCreated(true)
    setModal({ ...modal, show: true, content: 2 })
    DataLayer.cta("Submit_Design")
  }

  const abandon = () => {
    setModal({ ...modal, show: true, content: 0 })
    DataLayer.cta("Abandon")
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  return (
    <StepsWrapper
      className="finish"
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
    >
      <Text textId="editorReviewReady" type="h5" />
      <Text textId="editorReviewDisclaimer" type="p" />
      {saving && (
        <LoaderWrapper>
          <Loader inline />
        </LoaderWrapper>
      )}
      {!saving && (
        <>
          <Button
            colour="secondary2"
            textId="buttonSubmit"
            onClick={submitDesign}
          />
          <Button
            colour="primary"
            outline
            textId="buttonBackEdit"
            onClick={() => {
              goto(`${t("pathStep")}7`)
              DataLayer.cta("Back_to_editing")
            }}
          />
          <Text textId="editorReviewAbandon" type="a" onClick={abandon} />
        </>
      )}
      <Scene height="700px" screenshot />
    </StepsWrapper>
  )
}

export default Step8
