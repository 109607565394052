import styled from "styled-components/macro"
import { rgba } from "polished"
import colours from "./colours"

export { default as colours } from "./colours"
export { default as layout } from "./layout"
export { default as breakpoints } from "./breakpoints"

export { GlobalStyle } from "./global"

export const ButtonGroup = styled.div`
  flex-direction: row !important;
  .button {
    margin-right: 1rem;
  }
`

export const FlexRow = styled.div`
  position: relative;
  flex-direction: row !important;

  &.row {
    &.center {
      justify-content: center;
    }
    &.around {
      justify-content: space-around;
    }
    &.between {
      justify-content: space-between;
    }
  }
`

export const Flare = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  z-index: 0;
  background: radial-gradient(
    ${rgba(colours.secondary2, 0.9)} 0%,
    ${rgba(colours.secondary2, 0)} 70%
  );
`
