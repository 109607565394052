import styled from 'styled-components';
import { colours, breakpoints } from '../../../theme';
import iconArrowDown from '../../../assets/images/icon-global-arrow-down.svg';

const GlobalFooterTopLinksWrapper = styled.footer`
  padding: 2rem 3rem; 
  background: #042144;
  display: flex;
  font-family: "FuturaBook", arial, sans-serif;
  flex-wrap: wrap;

  ${breakpoints.md`
    display: flex;
  `}

  .footer {
    &-logo {
      width: 100%;

      ${breakpoints.md`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      `}
    }

    &-heading {
      font-weight: bold;
      color: ${colours.white};
    }

    &-links {
      flex: 0 0 50%;
      max-width: 50%;
      margin: 1rem 0 3rem 0;

      ${breakpoints.md`
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      `}
    }

    &-link {
      margin: .8rem 0;
      color: ${colours.white};
      &:hover {
        color: ${colours.white};
        text-decoration: underline !important;
      }
    }
    
    &-button {
      appearance: none;
      border: none;
      line-height: 1.4rem;
      background-color: transparent;
      color: ${colours.white};
      font-weight: bold;
      padding: 0;
      text-align: left;
      display: flex;

      i {
        background: url(${iconArrowDown}) no-repeat;
        background-position: 50%;
        background-size: 16px;
        width: 16px;
        height: 12px;
        margin-top: 4px;
        margin-left: 4px;
        display: block;
      }
    }

    &-language {
      color: ${colours.white};
      margin: .8rem 0;
    }
  }

`;

export default GlobalFooterTopLinksWrapper;
