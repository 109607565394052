import React from "react"
import { BadgeButtonWrapper } from "./styles"
import { useTranslation } from "react-i18next"

interface BadgeButtonProps {
  big?: boolean
  circle?: boolean
  className?: string
  icon: any
  onClick: () => void
  textId?: string
}

const BadgeButton: React.FC<BadgeButtonProps> = ({
  big,
  circle,
  className,
  icon,
  onClick,
  textId,
}) => {
  const { t } = useTranslation()

  return (
    <BadgeButtonWrapper
      className={`BadgeButton ${className}`}
      onClick={onClick}
      big={big}
      circle={circle}
    >
      {React.createElement(icon)}
      {textId && <div dangerouslySetInnerHTML={{ __html: t(textId) }} />}
    </BadgeButtonWrapper>
  )
}

export default BadgeButton
