import styled from "styled-components/macro"
import { motion } from "framer-motion"
import { colours } from "theme"
import { lighten } from "polished"

interface IconButtonProps {
  active: boolean
  badge?: boolean
  badgeSize?: number | null
  portrait?: boolean
  size?: {
    width: number
    height: number
  }
}

export const IconButtonWrapper = styled.div<IconButtonProps>`
  position: relative;
  justify-content: center;
  align-items: center;
  transition: 0.2s box-shadow ease-out;
  width: ${(props) => (props.size ? props.size.width : 100)}px;
  height: ${(props) => (props.size ? props.size.height : 100)}px;
  box-shadow: inset 0 0 ${(props) => (props.active ? "1px" : 0)}
      ${(props) => (props.active ? "2px" : 0)} ${colours.primary},
    0 2px ${(props) => (props.active ? "15px" : "10px")}
      rgba(0, 0, 0, ${(props) => (props.active ? 0.2 : 0.05)});
  background: ${(props) =>
    props.active ? colours.white : "rgba(255,255,255,0.75)"};
  border-radius: 0.4rem;
  margin: 0 0.5rem;
  cursor: pointer;

  > svg,
  > img {
    width: ${(props) =>
      props.badge ? (props.portrait ? "initial" : "100px") : "initial"};
    height: ${(props) =>
      props.badge ? (props.portrait ? "90px" : "initial") : "initial"};
    transform: scale(
      ${(props) =>
        props.badgeSize === 0 ? 0.8 : props.badgeSize === 1 ? 1.2 : 1}
    );
  }

  .image-split {
    height: 99px;
    width: auto;
  }
`

export const IconBadge = styled(motion.div)`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background: ${colours.secondary2};
  justify-content: center;
  align-items: center;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
`
