import React, { useContext, useState, useEffect } from "react"
import { Overlay } from "./styles"
import utils from "./utils"
import CookieFooter from "./CookieFooter"
import Settings from "./Settings"
import { useRecoilValue } from "recoil"
import { locale } from "store/atoms"
import Div100vh from "react-div-100vh";

const DIALOG_COOKIE = "PVH_COOKIES_GDPR"
const ANALYTICS_COOKIE = "PVH_COOKIES_GDPR_ANALYTICS"
const SOCIAL_MEDIA_COOKIE = "PVH_COOKIES_GDPR_SOCIALMEDIA"

const setCookie = (name: string, value: string, days = 365, path = "/") => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=${path}`
}

const getCookie = (name: string) =>
  document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=")
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, "")

export const Cookie: React.FC = () => {
  const { shortCode: localeCode } = useRecoilValue(locale)
  const [translations, setTranslations] = useState<any>(false)
  const [showCookieOverlay, setShowCookieOverlay] = useState<boolean>(
    getCookie(DIALOG_COOKIE) !== "Accept"
  )
  const [showSettings, setShowSettings] = useState<boolean>(false)

  const setAnalyticsPreferences = (
    analytics: boolean,
    socialMedia: boolean
  ) => {
    setCookie(DIALOG_COOKIE, "Accept")
    setCookie(ANALYTICS_COOKIE, analytics ? "Accept" : "Reject")
    setCookie(SOCIAL_MEDIA_COOKIE, socialMedia ? "Accept" : "Reject")
    setShowCookieOverlay(false)
  }
  useEffect(() => {
    setTranslations(utils.getDataFromLang(localeCode).cookieNotice)
  }, [locale])

  useEffect(() => {
    if (showCookieOverlay) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [showCookieOverlay])

  if (showCookieOverlay) {
    return (
      <Div100vh style={{position: 'absolute'}}>
        <Overlay>
          {translations && !showSettings && (
            <CookieFooter
              translations={translations}
              settingsOnClick={() => setShowSettings(true)}
              agreeOnClick={setAnalyticsPreferences}
            />
          )}
          {translations && showSettings && (
            <Settings
              translations={translations}
              doneOnClick={setAnalyticsPreferences}
            />
          )}
        </Overlay>
      </Div100vh>
    )
  }
  return null
}

export default Cookie
