import React, {useRef} from 'react';
import {BasketIcon, SearchField, SearchIcon, SignIn, WishlistIcon, Wrapper} from './styles';
import {useRecoilValue} from "recoil";
import { viewport } from '../../../store/atoms';
import {size} from "../../../theme/breakpoints";
import {useTranslation} from "react-i18next";

interface SideIconsProps {
  onSearchClick: () => void;
}

const SideIcons: React.FC<SideIconsProps> = ({onSearchClick}) => {
  const viewPort = useRecoilValue(viewport);
  const isMobile = viewPort.width < size.md;
  const inputEl = useRef(null);
  const { t } = useTranslation()

  const doSearch = () => {
    // @ts-ignore
    const searchTerm = inputEl.current.value.trim();

    if (searchTerm)
      window.location = `/search?searchTerm=${searchTerm}` as any;
  }

  return (
    <Wrapper>
      {!isMobile && <SearchField ref={inputEl} onKeyDown={(e) => (e.keyCode == 13) && doSearch()} />}
      <SearchIcon onClick={() => isMobile ? onSearchClick() : doSearch()} />
      {!isMobile && <SignIn href={t('myaccountLink')}>{t('signIn')}</SignIn>}
      <WishlistIcon href={t('wishlistLink')} />
      <BasketIcon href={t('shoppinbagLink')} />
    </Wrapper>
  );
}

export default SideIcons;