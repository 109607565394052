import React from "react"
import { navigate } from "@reach/router"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { modal } from "store/atoms"
import { ModalContent } from "./styles"
import { currentBadge, rootPath, textureSettings } from "store/atoms"
import { defaultDesign, defaultBadge } from "store/constants"
import { Button, Text } from "components"
import { useTranslation } from "react-i18next"

const Abandon: React.FC = () => {
  const setModal = useSetRecoilState(modal)
  const resetDesign = useRecoilValue(defaultDesign)
  const resetBadge = useRecoilValue(defaultBadge)
  const updateTexture = useSetRecoilState(textureSettings)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const rootUrl = useRecoilValue(rootPath)
  const { t } = useTranslation()
  
  const abandon = () => {
    // @ts-ignore
    setModal({ ...modal, show: false })
    updateTexture(resetDesign)
    setCurrBadge(resetBadge)

    const root = rootUrl === "/" ? rootUrl : `/${rootUrl}/`

    navigate(`${root}${t("pathCoCreate")}`)
  }

  const close = () => {
    // @ts-ignore
    setModal({ ...modal, show: false })
  }

  return (
    <ModalContent>
      <Text textId="modalAbandonHead" type="h3" />
      <Text textId="modalAbandonBody" type="p" />
      <Button colour="secondary2" textId="buttonAbandon" onClick={abandon} />
      <Button
        colour="primary"
        outline
        textId="buttonBackToDesign"
        onClick={close}
      />
    </ModalContent>
  )
}

export default Abandon
