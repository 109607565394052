import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { isOpen, locale } from '../../../store/atoms';
import { ReactComponent as LogoSvg } from '../../../assets/images/tommyLogo-blue.svg';
import GlobalFooterTopLinksWrapper from './styles';
import Overlay from '../Overlay';
import Translations from "../translations/data.json"


const GlobalFooterTopLinks = () => {
  const [open, setOpen] = useRecoilState(isOpen);
  const { shortCode, longCode } = useRecoilValue(locale);

  const t = Translations.filter(t => t.key === "en-hk")
  const l = Translations.filter(t => t.key === "en-hk-link")

  return (
    <GlobalFooterTopLinksWrapper>
      <div className="footer-logo">
        <LogoSvg />
      </div>
      <div className="footer-links">
        <div className="footer-heading">Overview</div>
        <a href="https://global.tommy.com/en_int/collection" className="footer-link">Collection</a>
        <a href="https://global.tommy.com/en_int/about-us" className="footer-link">About Us</a>
        <a href="https://global.tommy.com/en_int/newsroom" className="footer-link">Newsroom</a>
        <a href="https://global.tommy.com/en_int/careers" className="footer-link">Careers</a>
      </div>
      <div className="footer-links">
        <div className="footer-heading">Follow us</div>
        <a href="https://www.facebook.com/tommyhilfiger/?ref=br_rs" className="footer-link">Facebook</a>
        <a
          href="https://twitter.com/TommyHilfiger?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
          className="footer-link"
        >
          Twitter
        </a>
        <a href="https://www.instagram.com/tommyhilfiger/" className="footer-link">Instagram</a>
      </div>
      <div className="footer-links">
        <button
          type="button"
          className="footer-button"
          onClick={() => setOpen(!open)}
        >
          Change Language
          <i className="icon-arrow-down" />
        </button>
        <div className="footer-language">English</div>
      </div>
      <Overlay />
    </GlobalFooterTopLinksWrapper>
  );
};

export default GlobalFooterTopLinks;
