import styled, { keyframes } from "styled-components/macro"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const CreateYourOwnButton = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 5000;
  background: rgba(0, 23, 79, 0.3);
  transform-origin: 50% 40%;
  border: red solid 1px;
  flex-direction: column-reverse;
`
