import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as YoutubeIcon } from "assets/images/iconBtnYoutube.svg"
import ReactPlayer from "react-player"
import { Button, Loader, Text } from "components"
import {
  CollaboratorCopy,
  CollaboratorImages,
  CollaboratorVideo,
  CollaboratorWrapper,
  CollaboratorYoutubeButton,
  CenterButton,
} from "./styles"

interface CollaboratorProps {
  portrait: any
  poster: any
  design: any
  index: number
  scrollPos: number
  viewHeight: number
  showBidButton: boolean
}

const Collaborator: React.FC<CollaboratorProps> = ({
  portrait,
  poster,
  design,
  index,
  scrollPos,
  viewHeight,
  showBidButton
}) => {
  const [vidLoaded, setVideoLoaded] = useState(false)
  const [isOnPage, setIsOnPage] = useState(true)
  const { t } = useTranslation()
  const collabRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (collabRef) {
      const cRef = collabRef.current!
      const cDims = cRef.getBoundingClientRect()
      const { top } = cDims

      let playVid = false
      if (top < viewHeight + 300) {
        playVid = true
      }

      setIsOnPage(playVid)
    }
  }, [scrollPos])


    const goto = (url: string) => {
      const anchor = document.createElement("a")
      anchor.href = t(url)
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }
  

  const bidOnItem = () => {
   goto("auctionLink")
  }

  const clickYoutube = () => {
    goto("auctionYoutube")
  }

  const onLoaded = () => {
    setVideoLoaded(true)
  }

  return (
    <CollaboratorWrapper ref={collabRef}>
      <CollaboratorImages>
        <img src={portrait} alt={t(`auctionCollab${index}Name`)} />
        <CollaboratorVideo>
          {!vidLoaded && (
            <>
              <Loader inline />
              <img src={poster} alt={t(`auctionCollab${index}Name`)} />
            </>
          )}

          <ReactPlayer
            onReady={onLoaded}
            url={design}
            muted
            loop
            playsinline
            playing={vidLoaded && isOnPage}
          />
        </CollaboratorVideo>
      </CollaboratorImages>
      <CollaboratorCopy>
        <Text textId={t(`auctionCollab${index}Name`)} type="h2" />
        <Text textId={t(`auctionCollab${index}Copy`)} type="p" />

        <CenterButton>
          <CollaboratorYoutubeButton onClick={clickYoutube}>
            <YoutubeIcon />
            <Text textId={t(`auctionCollabYoutubeButton`)} type="p" />
          </CollaboratorYoutubeButton>
          <Text
            className="description"
            textId={t(`auctionCollabYoutubeDesc`)}
            type="p"
          />
        </CenterButton>
        {showBidButton && 
          <CenterButton>
            <Button
              textId={t(`auctionCollabBidButton`)}
              onClick={bidOnItem}
              colour="secondary2"
            />
            <Text
              className="description"
              textId={t(`auctionCollabBidDesc`)}
              type="p"
            />
          </CenterButton>}
      </CollaboratorCopy>
    </CollaboratorWrapper>
  )
}

export default Collaborator
