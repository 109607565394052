import React, { useContext, useState, useEffect } from "react"
import { Overlay } from "./styles"
import utils from "./utils"
import CookieContent from "./CookieContent"
import { useRecoilValue } from "recoil"
import { locale } from "store/atoms"
import Div100vh from "react-div-100vh";

const DIALOG_COOKIE = "PVH_COOKIES_GDPR"

const setCookie = (name: string, value: string, days = 365, path = "/") => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=${path}`
}

const getCookie = (name: string) =>
  document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=")
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, "")

export const GlobalCookie: React.FC = () => {
  const localeCode = useRecoilValue(locale)
  const [translations, setTranslations] = useState<any>(false)
  const [showCookieOverlay, setShowCookieOverlay] = useState<boolean>(
    getCookie(DIALOG_COOKIE) !== "Accept"
  )

  const setAnalyticsPreferences = () => {
    setCookie(DIALOG_COOKIE, "Accept")
    setShowCookieOverlay(false)
  }

  useEffect(() => {
    setTranslations(utils.getDataFromLang(localeCode.shortCode).cookieNotice)
  }, [locale])

  useEffect(() => {
    if (showCookieOverlay) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [showCookieOverlay])

  if (showCookieOverlay && translations) {
    return (
      <Div100vh style={{position: 'absolute'}}>
        <Overlay>
          <CookieContent translations={translations} agreeOnClick={setAnalyticsPreferences} />
        </Overlay>
      </Div100vh>
    )
  }

  return null
}

export default GlobalCookie
