import React, { lazy, Suspense, useEffect, useRef, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { justCreated, viewport, rootPath } from "store/atoms"
import { navigate, RouteComponentProps } from "@reach/router"
// import Finalists from "./Finalists"
import { useTranslation } from "react-i18next"
import { CreateYourOwnButton, GalleryWrapper } from "./styles"
import { InlineButton, Text } from "components"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { ReactComponent as Shirt } from "assets/images/iconShirt.svg"

const FinalistsLazy = lazy(() => import("./Finalists"))

let scrollPct = 0

const Gallery: React.FC<RouteComponentProps> = () => {
  const wrapper = useRef<HTMLDivElement>(null)
  const setCreated = useSetRecoilState(justCreated)
  const view = useRecoilValue(viewport)
  const rootUrl = useRecoilValue(rootPath)
  const { t } = useTranslation()

  const wrapperMode = process.env.REACT_APP_WRAPPER_MODE || ""

  useEffect(() => {
    setCreated(false)
  }, [])

  const goto = (url: any) => {
    navigate(url)
  }

  return (
    <GalleryWrapper ref={wrapper} dimensions={view}>
      <InlineButton
        textId="buttonBack"
        icon={<ChevronLeft />}
        onClick={() => goto(rootUrl === "/" ? rootUrl : `/${rootUrl}/`)}
      />
      <Suspense fallback={null}>
        <FinalistsLazy dimensions={view} />
      </Suspense>
      <CreateYourOwnButton
        dimensions={view}
        onClick={() => goto(`${t("pathCoCreate")}`)}
      >
        <span>
          <Shirt />
        </span>
        <Text textId="buttonCreateLook" type="p" />
      </CreateYourOwnButton>
    </GalleryWrapper>
  )
}

export default Gallery
