import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,23,79,.3);
  z-index: 1000;
  transform-origin: 50% 40%;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

export default Overlay;
