export default {
    setupDataLayer(url: string, country: string, isMobile: boolean, acceptCookie: boolean, acceptSocialCookie: boolean): void {
       const utag = (window as any).utag;
       const dlObj = {
        storeCountry: country,
        pageName: url, 
        storeBrand: "tommyhilfiger",
        campaignType: "fall20",
        campaignID: "flag",
        siteDeviceVersion: isMobile ? "mobile" : "desktop",
        userAcceptAnalytics: acceptCookie ? "1" : "0",
        userAcceptSocial: acceptSocialCookie ? "1" : "0"
       }
        if(utag) {
            utag.view(dlObj)
        }
    },
    cta(label: string): void {
      const utag = (window as any).utag;
      const dlObj = {
        event_name:"click_cta",
        eventLabel: label,
        event_category: "engagement"
      };
    
       if(utag) {
           utag.link(dlObj)
       }
   },

   event(label: string, name: string, action: string): void {
    const utag = (window as any).utag;
    const dlObj = {
      event_name:name,
      eventLabel: label,
      event_category:action
    };
  
     if(utag) {
         utag.link(dlObj)
     }
 },
  
    handleError(error: any): Promise<any> {
      console.error("An error occurred", error)
      return Promise.reject(error.message || error)
    },
  }
  