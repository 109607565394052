import React, { useEffect, useState } from "react"
import Router, { navigate, RouteComponentProps } from "@reach/router"
import { BadgeButton, Loader, Scene, InlineButton, Text } from "components"
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil"
import { useTranslation } from "react-i18next"
import {
  currGalleryItem,
  justCreated,
  modal,
  rootPath,
  viewport,
} from "store/atoms"
import {
  ActionButtons,
  GalleryItemContent,
  GalleryItemMain,
  GalleryItemWrapper,
} from "./styles"
import { FlexRow } from "theme"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { ReactComponent as Download } from "assets/images/iconDownload.svg"
import { ReactComponent as Share } from "assets/images/iconShare.svg"
import { ReactComponent as Icon360 } from "assets/images/iconRotate360.svg"
import Shadow from "assets/images/shadow.png"
import DesignAPIService, { DesignView } from "utils/designs/service"
import { motion } from "framer-motion"
import DataLayer from "utils/dataLayer"

export interface GalleryItemProps
  extends Router.RouteComponentProps<{
    itemId?: string
  }> {}

const GalleryItem: React.FC<GalleryItemProps> = ({ itemId }) => {
  const { t } = useTranslation()
  const [currentDesign, setCurrentDesign] = useState<DesignView | null>(null)
  const [contentLoaded, setContentLoaded] = useState(false)
  const [created, setCreated] = useRecoilState(justCreated)
  const item = useRecoilValue(currGalleryItem)
  const setItem = useSetRecoilState(currGalleryItem)
  const view = useRecoilValue(viewport)
  const setModal = useSetRecoilState(modal)
  const params = new URLSearchParams(window.location.search)
  const rootUrl = useRecoilValue(rootPath)
  const root = rootUrl === "/" ? rootUrl : `/${rootUrl}/`

  useEffect(() => {
    window.scrollTo(0, 0)
    let designId = itemId
    console.log(designId)
    if (params.has("id")) {
      // @ts-ignore
      const idString: string = params.get("id")
      designId = idString.replace("/", "")
    }

    if (designId) {
      DesignAPIService.getDesignView(designId).then((res) => {
        setCurrentDesign(res)
        setContentLoaded(true)
        setItem({
          ...item,
          type: res.design.modelType,
          id: res.id,
          number: res.leaderboardPos - 1,
          imageUrl: res.design.imageUrl,
        })
      })
    } else {
      goto(`${root}${t("pathGallery")}`)
    }
  }, [])

  const goto = (url: any) => {
    navigate(url)
  }

  const downloadDesign = () => {
    DataLayer.cta("Download")
    const anchor = document.createElement("a")
    // @ts-ignore
    anchor.href = currentDesign.imageUrl
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }

  const shareDesign = () => {
    DataLayer.cta("Share")
    setModal({ ...modal, show: true, content: 3 })
  }

  const getIntros = () => {
    if (created) {
      return <Text textId="GalleryReviewPopular" className="intro" type="p" />
    } else {
      return (
        <>
          <Text textId="winningDesignsVotedByYou" className="intro" type="p" />
          <Text textId="#1" type="h1" />
        </>
      )
    }
  }

  return (
    <GalleryItemWrapper>
      <GalleryItemContent>
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={() => goto(`${root}${t("pathGallery")}`)}
        />
        {getIntros()}
        <GalleryItemMain>
          {currentDesign && (
            <Scene
              mode="galleryItem"
              height="400px"
              dimensions={view}
              // @ts-ignore
              designSettings={[currentDesign.design]}
            />
          )}
          {!currentDesign && <Loader inline className="loader" />}
          <ActionButtons>
            <BadgeButton
              className="download"
              textId="buttonDownload"
              circle
              icon={Download}
              onClick={downloadDesign}
            />
            <BadgeButton
              className="share"
              textId="buttonShare"
              circle
              icon={Share}
              onClick={shareDesign}
            />
          </ActionButtons>
          <FlexRow className="row center vote-container">
            <Icon360 id="icon-360" />
            <img src={Shadow} />
            {contentLoaded && item.number >= 0 && (
              <motion.div
                className="scroll-text"
                initial={{ x: "100%" }}
                animate={{ x: "-100%" }}
                transition={{
                  loop: Infinity,
                  ease: "linear",
                  duration: 15,
                }}
              >
                <Text textId="galleryNumber1" type="h2" />
              </motion.div>
            )}
          </FlexRow>
        </GalleryItemMain>
      </GalleryItemContent>
    </GalleryItemWrapper>
  )
}

export default GalleryItem
