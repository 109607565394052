import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"

interface TermsProps {
  onBack: () => void
}

const TermsUK: React.FC<TermsProps> = ({ onBack }) => {
  return (
    <ArticleWrapper>
      <span className="back" onClick={onBack}>
        Back
      </span>
      <div className="container">
        <h1>TERMS AND CONDITIONS</h1>
        <ol>
          <li>
            <p>
              "Together we create" (the "<b>Promotion</b>") is organized by
              <b>Tommy Hilfiger Europe B.V.</b> a private limited liability
              company construed under the laws of the Netherlands (the "
              <b>Promoter</b>") and will be governed by these terms and
              conditions (the "<b>Terms</b>"). A consumer entering the Promotion
              (hereinafter called "<b>Participant</b>") agrees to have read and
              understood the Terms and will be bound by them. These Terms apply
              to the territory of The Netherlands, Germany, France and United
              Kingdom. Participation in this Promotion is for residents of The
              Netherlands, Germany, France and United Kingdom only. The
              Promotion shall be void where prohibited or restricted by law. By
              participating in this Promotion between September 2nd 2020 and
              September 7th 2020 (the "Promotional Period"), Participants have a
              chance to win the final designed product, either t-shirt or
              hoodie, at chance. The prize which can be won is further specified
              under Clause 6 in these Terms.
            </p>
            <p>
              What do you need to do for a chance to win? You need to enter your
              submission by customizing one of our favorite Tommy pieces using
              leftover fabric, add graphic details, then submit your design. You
              could see your product come to life and be worn by other Tommy
              fans.
            </p>
            <p>
              Participate by entering your preferred design options. You can
              choose to customize by adding branding and logo details, selecting
              the splicing direction, and the colour combinations of the
              leftover fabrics. When you're done, submit your design leave your
              email address and size. The winner will be selected at random on
              September 14th 2020. Participation and submission of preferred
              design options will not give rise to any intellectual property
              rights for the Participant. All intellectual property rights over
              the design options and graphics including the final designed
              product will remain with the Promoter.
            </p>
            <p>By participating you are deemed to have accepted these Terms.</p>
            <p>
              The 8 winners (each a “Winner”) will be informed of the fact that
              they won shortly after September 14th 2020 via their e-mail
              address and the names of the winner(s) will be published on
              <a href="https://uk.tommy.com/flag">https://uk.tommy.com/flag</a>
            </p>
          </li>
          <li>
            <p>
              This Promotion runs within the Promotional Period in The
              Netherlands, Germany, France and United Kingdom only.
            </p>
          </li>
          <li>
            <p>
              Participants have to be at least eighteen (18) years of age at the
              date of entry of the Promotion. This Promotion is not open –
              directly nor indirectly – to employees and immediate relatives of
              employees of the Promoter, its subsidiaries or affiliated
              companies, or any other person professionally connected with the
              Promotion. Details of the Promoter are mentioned under clause 7 of
              these Terms.
            </p>
          </li>
          <li>
            <p>
              The Winner needs to respond to the Promoter within 48 hours after
              the first notification to confirm his/her acceptance of the prize.
              If such response has not been received by the Promoter within the
              said time, the Promoter has the right to choose another Winner
              applying the same criteria as that used to select the original
              Winner.
            </p>
          </li>
          <li>
            <p>
              The Promotion shall be void where prohibited or restricted by law.
            </p>
          </li>
          <li>
            <p>
              Prize: The prize which can be won by participation shall comprise
              of either 50 EURO retail value for the t-shirt and 100 EURO retail
              value for the hoodie. The prize award is non-transferable and no
              cash redemption or prize substitution allowed, except at
              Promoter’s sole discretion or as provided herein. No cash
              alternatives for the Prize will be offered. If due to
              circumstances beyond the Promoter’s control the Promoter is unable
              to provide the Prize or any part of it, the Promoter reserves the
              right to award a substitute prize of equal or greater value.
            </p>
          </li>
          <li>
            <p>
              Any queries or complaints regarding the Promotion should specify
              the name of the Promotion and be sent by email to:
            </p>
              <p>
              Tommy Hilfiger Europe B.V. <br />
              Danzigerkade 165
              <br />
              1013 AP Amsterdam
              <br />
              The Netherlands
              <br />
              e-mail: <a href="mailto:MYTOMMY@tommy.com">MYTOMMY@tommy.com</a>
              </p>
          </li>
          <li>
            <p>
              The Promoter shall not be responsible for technical errors in
              telecommunications networks, internet access or otherwise
              preventing entry to this Promotion.
            </p>
          </li>
          <li>
            <p>
              The Promoter reserves the right to select an alternative winner in
              the event that the Promoter has reasonable grounds for believing
              that a selected Winner has contravened any of these Terms. Any
              alternative Winner will be selected applying the same criteria as
              that used to select the original Winner.
            </p>
          </li>
          <li>
            <p>
              No waiver of any rights by the Promoter or any Participant will be
              taken as a waiver of any other rights it may have. For example, if
              the Promoter waives its right to complain about a particular
              breach of the Terms by a Participant, it does not prevent the
              Promoter from complaining about other breaches.
            </p>
          </li>
          <li>
            <p>
              Any personal data collected by Promoter from Participants (e.g.
              name of Winner), will be used solely in connection with this
              Promotion.
            </p>
          </li>
          <li>
            <p>
              The Terms shall be solely governed by and construed in accordance
              with the laws of the Netherlands, unless mandatory local laws
              prevail and any disputes shall be subject to the exclusive
              jurisdiction of the competent courts in Amsterdam, the
              Netherlands, unless mandatory local laws prevail.
            </p>
          </li>
        </ol>
      </div>
    </ArticleWrapper>
  )
}

export default TermsUK
