import React, { useEffect, useRef } from "react"
import { useFrame, useLoader, useThree } from "react-three-fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
//@ts-ignore
import { a, useSpring } from "react-spring/three"
//@ts-ignore
import shirt from "assets/3d/TommyShirt.glb"
//@ts-ignore
import hood from "assets/3d/TommyHood.glb"
import {BufferGeometryUtils} from "./bufferUtils"

interface MeshProps {
  gltfModel: any
  meshRotation?: number
  mode?: string
  position?: number[]
  rotation?: number[]
  scale: number[]
  loadedScene: () => void
}
let count = 0
let rot = 0


const CheckDoubleSideIssue = (object3D:any, gl:any) => {
  
  var gpuHasFrontFacingDoubleSidedBug = false;
  var debugInfo = gl.getContext().getExtension('WEBGL_debug_renderer_info');
  if (debugInfo !== null)
  {
    var gpu = gl.getContext().getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    gpuHasFrontFacingDoubleSidedBug = gpu.match(/adreno.+(5|6)[0-9][0-9]/gi) !== null;
  }
  
  if(gpuHasFrontFacingDoubleSidedBug) {
    BufferGeometryUtils.computeTangents(object3D.geometry);
    object3D.material.vertexTangents = true;
    object3D.material.needsUpdate = true;
  }
}

//@ts-ignore
const Model: React.FC<MeshProps> = ({
  children,
  mode,
  gltfModel,
  loadedScene,
  meshRotation,
  position,
  rotation,
  scale,
}) => {
  const meshObject = useRef(null)
  const gltf = useLoader(GLTFLoader, gltfModel === "shirt" ? shirt : hood)
  let props = useSpring({ rotationZ: meshRotation ? meshRotation : 0 })
  const { gl } = useThree()
  
  useEffect(() => {
    loadedScene()
    CheckDoubleSideIssue(meshObject.current, gl)
  }, [gltf])
  
  useFrame(() => {
    if (mode === "gallery") {
      count++
      rot = count * 0.01
      if (rot > Math.PI * 2) {
        count = 0
      }
      if (meshObject) {
        const mesh: any = meshObject.current!
        mesh.rotation.set(1.57, rotation && rotation[1], rot)
        if (position) {
          mesh.position.set(
            position[0],
            Math.sin(rot * 3) * 3 - position[1],
            position[2]
          )
        }
      }
    }
  })

  return (
    <a.mesh
      ref={meshObject}
      {...gltf.scene.children[0]}
      position={position ? position : [0, 0, 0]}
      scale={scale}
      castShadow
      //@ts-ignore
      rotation-y={rotation ? rotation[2] : 0}
      rotation-z={props.rotationZ}
    >
      {children}
    </a.mesh>
  )
}

export default Model
