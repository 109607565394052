import React from "react";
import { SelectWrapper} from "./styles"
interface SelectProps {
	options: any[],
	id: string
	value: string,
	name: string,
	width: number,
	onChange: (event: any) => void
  }

  const SelectInput: React.FC<SelectProps> = ({ 
	  options,
	  id, 
	  value, 
	  name,
	  width,
	  onChange
  }) => {

	return(
		<SelectWrapper width={width}>
			<select
				id={id} 
				className="select"
				onChange={onChange}
				value={value}
				name={name}
			>
				{options.map((option, index) => (
					<option
						className="option"
						value={option.value}
						key={`option__${id}__${index}`}
					>
						{option.label}
					</option>
				))}
			</select>
			<div className="arrow" />
		</SelectWrapper>
	);
}// SelectInput

export default SelectInput;