import styled, { keyframes } from "styled-components/macro"
import { lighten } from "polished"
import { colours, layout } from "theme"
import GalleryBackground from "assets/images/galleryBackground.png"
import GalleryBackgroundDesktop from "assets/images/galleryBackgroundDesktop.jpg"

interface GalleryWrapperProps {
  dimensions: {
    width: number
    height: number
  }
}

interface RunnerUpProps {
  rotate1: number
  rotate2: number
  top1: number
  top2: number
  dimensions: {
    width: number
    height: number
  }
}

interface ModelHolderProps {
  positionX: string
}

interface CreateYourOwnProps {
  dimensions: {
    width: number
    height: number
  }
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const movingAnim = keyframes`
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const GalleryWrapper = styled.div<GalleryWrapperProps>`
  position: relative;
  background: ${colours.white};
  overflow: hidden;
  flex: auto;
  padding-bottom: 4rem;

  h3 {
    font-size: 2rem;
    line-height: 1;
  }

  .inline-button {
    position: absolute;
    z-index: 100;
    top: 1rem;
    left: 1rem;
  }

  @media (min-width: 992px) {
    padding-bottom: 0;
  }
`

export const AnimatedLandingWrapper = styled.div<GalleryWrapperProps>`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: ${(props) => props.dimensions.height - 57}px;
  margin-bottom: 8rem;
  > div {
    position: absolute;
  }
  img {
    max-width: 600px;
    width: ${(props) => props.dimensions.width * 0.7}px;
  }
  .heading {
    border: 270px;
    position: absolute;
    top: ${(props) => props.dimensions.height / 3}px;
    left: 0;
    right: 0;
    opacity: 0;
    letter-spacing: 2px;
    color: ${colours.secondary2};
    text-align: center;
    font-size: 4.4rem;
    animation: ${movingAnim} forwards 4s 1s cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 20;
  }

  .view-more {
    position: absolute;
    z-index: 100;
    bottom: 1%;
    left: 50%;
    margin-left: -0.5rem;
    h6 {
      position: absolute;
      width: 100px;
      text-transform: uppercase;
      color: ${colours.secondary2};
      line-height: 1.2;
      text-align: center;
      top: -4.4rem;
      left: 50%;
      margin-left: -230%;
    }

    svg {
      path,
      line {
        stroke: ${colours.secondary2};
      }
    }
  }
  @media (max-height: 811px) {
    .heading {
      margin-top: 5rem;
    }
  }
  @media (min-width: 376px) {
    img {
      width: ${(props) => props.dimensions.width * 0.6}px;
    }
  }
  @media (min-width: 500px) {
    .heading {
      font-size: 5rem;
    }
    img {
      width: ${(props) => props.dimensions.width * 0.45}px;
    }
  }
  @media (min-width: 650px) {
    max-width: 2000px;
    height: 90vh;
    width: 100%;
    margin: 0 auto;
    .heading {
      font-size: ${(props) => props.dimensions.width * 0.1}px;
    }
    img {
      width: ${(props) => props.dimensions.width * 0.3}px;
    }
  }
`

export const FinalistsWrapper = styled.div<GalleryWrapperProps>`
  position: relative;
  justify-content: flex-start;
  text-align: center;

  h3 {
    color: ${colours.secondary2};
  }

  p {
    font-size: 1.05rem;
    max-width: 276px;
    margin: 0 auto;
  }

  .scene-wrapper {
    position: relative;
    top: -1.5rem;
    left: -4.5rem;
    margin: 0 auto;
    width: 500px !important;
    height: 500px !important;
    > div {
      width: 500px !important;
      height: 500px !important;
    }
  }

  @media (min-width: 500px) {
    .scene-wrapper {
      left: initial;
    }
  }

  @media (min-width: 992px) {
    p {
      margin-bottom: 2rem;
    }

    background: ${colours.white};
    .scene-wrapper {
      left: 0;
      width: 1000px !important;
      height: 600px !important;
      > div {
        width: 1000px !important;
        height: 600px !important;
      }
      margin: -6rem auto;
    }
  }
`

export const Winners = styled.div<GalleryWrapperProps>`
  position: relative;
  background: ${colours.white};
  margin: 4rem 1.3rem 0;
  min-height: 15rem;
  padding-top: 3rem;

  h1 {
    position: absolute;
    top: -2rem;
    white-space: pre-line;
    transform: translateX(-50%);
    font-size: ${(props) => {
      let fontSize = props.dimensions.width * 0.15
      // if (fontSize > 50) fontSize = 50
      return fontSize
    }}px;
    left: 50%;
    -webkit-text-stroke: 1px ${colours.secondary2};
    color: transparent;
    -ms-color: ${colours.secondary2};

    opacity: 0.2;
    margin: 0 auto;
  }

  .top-ten {
    flex-direction: row !important;
    h1 {
      margin: 0 10rem;
    }
  }

  @media (min-width: 375px) {
    height: 35rem;
  }

  @media (min-width: 992px) {
    min-height: 38rem;
    .top-ten {
      margin-top: -2rem;
      position: absolute;
    }
    h1 {
      white-space: nowrap;
      font-size: ${(props) => {
        let fontSize = props.dimensions.width * 0.08
        if (fontSize > 180) fontSize = 180
        return fontSize
      }}px;
    }
  }
`

export const ModelHolder = styled.div<ModelHolderProps>`
  position: relative;
  width: 80%;
  height: 250px;
  left: ${(props) => props.positionX};
`
export const CanvasHolder = styled.div`
  display: none;
`

export const GalleryItemWrapper = styled.div`
  touch-action: pan-y;
  overflow: hidden;
  position: relative;
  padding: 1rem ${layout.gutters};
  background: url(${GalleryBackground}) no-repeat top left;
  background-size: cover;
  min-height: 812px;
  height: 100vh;
  flex: none;
  margin: 0 !important;
  h1,
  h2 {
    position: absolute;
    font-size: 8rem;
    -webkit-text-stroke: 1px ${colours.secondary2};
    color: transparent;
    -ms-color: ${colours.secondary2};
    top: -6.5rem;
    right: -2rem;
    opacity: 0;
    animation: ${fadeIn} 2s forwards;
  }

  .inline-button {
    z-index: 10;
  }

  .scroll-text {
    position: absolute;
    top: -5rem;
    opacity: 0.3;
    h2 {
      font-size: 6rem;
      position: relative;
    }
  }

  .scene-wrapper {
    position: relative;
    left: -${layout.gutters};

    height: 375px;
    width: 375px;
    z-index: 1;
    > div {
      height: 375px !important;
      width: 375px !important;
    }
  }

  .vote-container {
    position: relative;
    flex: 1;
    padding-top: 1rem;
    max-height: 300px;
  }
  h2 {
    font-size: 4.5rem;
    white-space: nowrap;
    opacity: 0.4;
    top: -5rem;
    left: -2rem;
    z-index: 2;
  }
  p {
    margin-top: 2.5rem;
    font-size: 1.125rem;
    line-height: 1.4;
    opacity: 0;
    animation: ${fadeIn} 2s forwards;
    max-width: 200px;
    position: absolute;
  }
  #icon-360 {
    position: absolute;
    top: -3rem;
  }
  .row {
    img {
      position: absolute;
      top: -1rem;
      left: 20%;
      right: 20%;
      width: 60%;
      opacity: 0.5;
      z-index: 0;
    }
  }

  @media (max-width: 375px) and (max-height: 811px) {
    min-height: 667px;
  }

  @media (min-width: 480px) {
    .scene-wrapper {
      height: 550px !important;
      width: 550px !important;
      > div {
        height: 550px !important;
        width: 550px !important;
      }
    }
    .scroll-text {
      top: -12rem;
      h2 {
        font-size: 8rem;
      }
    }
  }

  @media (min-width: 992px) {
    background: url(${GalleryBackgroundDesktop}) no-repeat top left;
    background-size: cover;
    padding: 1rem 20% 5rem;
    margin-bottom: 2rem;
    p {
      width: 30%;
    }

    .scene-wrapper {
      left: 0;
      margin: -3rem auto 0;
      height: 500px;
      width: 500px;
    }

    .row {
      img {
        top: -1rem;
      }
    }
  }
`

export const GalleryItemContent = styled.div`
  position: relative;
  height: 100%;

  h1 {
    top: -3rem;
    right: -0.5rem;
  }

  max-width: 500px;
  margin: 0 auto;

  @media (min-width: 992px) {
    max-width: none;
    margin: 0;
    padding: 1rem 0;
    p {
      margin-left: 1.5rem;
    }

    h1 {
      top: -5rem;
      right: 0;
    }

    h2 {
      width: initial;
      font-size: 6rem;
      white-space: nowrap;
      right: initial;
      top: 4rem;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.3;
      margin: 0 auto;
    }
  }
`

export const GalleryItemMain = styled.div`
  position: relative;
  margin: auto 0;
  margin-top: 5rem;

  .loader {
    position: relative !important;
    z-index: 0;
    height: 500px;
    > div {
      width: 6rem;
      margin: auto;
    }
  }
`

export const ActionButtons = styled.div`
  position: absolute;
  right: 3rem;
  top: 7rem;
  > div {
    margin-bottom: 3rem;
    > div {
      width: 4.5rem;
      white-space: normal;
    }
  }
  @media (min-width: 376px) {
    right: 1rem;
  }
  @media (min-width: 992px) {
    top: 3rem;
  }
`

export const RunnerUpWrapper = styled.div<RunnerUpProps>`
  position: relative;
  height: 18rem;
  padding-top: 1rem;
  width: 375px;
  margin: 0 auto;
  justify-content: center;
  > div {
    position: absolute;
    z-index: 2;
    pointer-events: none;

    img {
      pointer-events: auto;
      position: relative;
      width: 52%;
      max-width: 400px;
      transform: rotate(${(props) => props.rotate1}deg);
      top: ${(props) => props.top1}px;
    }
    &:last-of-type {
      align-items: flex-end;
      img {
        transform: rotate(${(props) => props.rotate2}deg);
        top: ${(props) => props.top2}px;
      }
    }
  }
  h1 {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    font-size: 5rem;
    -webkit-text-stroke: 1px ${colours.primary};
    color: transparent;
    -ms-color: ${colours.primary};
    margin: -2.5rem 0 0 -2.5rem;
  }
  &:nth-child(odd) {
    h1 {
      -webkit-text-stroke: 1px ${colours.secondary2};
      color: transparent;
      -ms-color: ${colours.secondary2};
    }
    > div {
      align-items: flex-end;
     
        &:last-of-type {
          align-items: flex-start;
          
        }
      }
    }
  }

  @media (min-width: 992px) {
    background: ${colours.galleryBackground};
    width: 100%;
    height: ${(props) => props.dimensions.width * 0.25}px;
    > div {
      margin: auto 0;
      width: ${(props) => props.dimensions.width * 0.02}%;
      align-items: center;
      left: 50%;
      width: 20%;
      max-width: 600px;
      margin-left: -20%;
      img {
        width: 100%;
        
      }
      &:last-of-type {
        align-items: flex-start;
        margin-left: 2%;
      }
    }
    transition: 0.3s top ease-out;
    &:nth-child(odd) {
    > div {
          align-items: flex-start;
      margin-left: 2%;
        &:last-of-type {
          margin-left: -20%;
          
        }
      }
    }
  }
    
    
  }
`

export const RatingWrapper = styled.div`
  position: absolute;

  top: 7rem;
  left: 65%;
  h1 {
    margin: 0;
    opacity: 1;
    font-size: 4.5rem;
  }
  &:last-of-type {
    top: 20rem;
    left: 4%;
  }
  h6 {
    margin: 0;
    color: ${colours.secondary2};
    font-size: 1.2rem;
  }
  @media (min-width: 992px) {
    top: 20rem;
    left: 50%;
    margin-left: -7rem;
    &:last-of-type {
      top: 10rem;
      margin-left: 23rem;
      left: 50%;
    }
  }
`

export const InvisibleButton = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: 40%;
  &.invisible2 {
    top: 40%;
  }
  @media (min-width: 992px) {
    top: 0;
    height: 100%;
    width: 50%;
    &.invisible2 {
      top: 0;
      left: 50%;
      height: 100%;
    }
  }
`

export const CreateYourOwnButton = styled.div<CreateYourOwnProps>`
  position: absolute;
  z-index: 20;
  // @ts-ignore
  bottom: 0.5rem;
  left: 50%;
  transition: 0.15s ease-out;
  margin: 1rem auto 2rem;
  transform: translateX(-50%);
  flex-direction: row !important;
  height: 2rem;
  background: ${colours.primary};
  border-radius: 1rem;
  align-items: center;
  cursor: pointer;

  span {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 50%;
    height: 1.7rem;
    width: 1.7rem;
    margin-left: 0.15rem;
    svg {
      width: 0.9rem;
    }
  }
  p {
    color: white;
    text-transform: uppercase;
    font-family: "futuraMedium", futura, arial, sans-serif;
    font-size: 0.9rem;
    padding: 0.2rem 1.5rem 0 1rem;
    margin: 0;
    white-space: nowrap;
  }

  &:hover,
  &:active {
    background: ${lighten(0.1, colours.primary)};
  }
  transform-origin: 50% 50%;

  &:active {
    transform: translateX(-50%) scale(0.9);
  }

  @media (min-width: 992px) {
    bottom: 3rem;
    margin: 3rem auto;
    transform: translateX(-50%) translateY(50px);
    &:active {
      transform: translateX(-50%) translateY(50px) scale(0.9);
    }
  }
`

export const TopFiveWrapper = styled.div`
  position: relative;
  padding-top: 4rem;
  overflow: hidden;
  background: ${colours.galleryBackground};
`

export const TopTenTicker = styled.div`
  flex-direction: row !important;
  position: absolute;
  top: 40rem;
  bottom: -200%;
  height: 8rem;
  right: 50%;
  overflow: hidden;
  transform-origin: 50% 50%;
  transform: translateX(50%) translateY(50%) rotate(-90deg);
  align-items: center;
  div {
    h3 {
      font-size: 6rem;
      opacity: 0.5;
      -webkit-text-stroke: 1px ${colours.secondary2};
      color: transparent;
      -ms-color: ${colours.secondary2};
      margin: 0 1rem;
      white-space: nowrap;
    }
  }
  @media (min-width: 992px) {
    div {
      h3 {
        margin-top: 2rem;
        font-size: 8rem;
      }
    }
  }
`
