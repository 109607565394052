import React, { Suspense, useEffect, useRef, useState } from "react"
import { navigate } from "@reach/router"
import { Canvas, useLoader } from "react-three-fiber"
import { TextureLoader } from "three"
import { CanvasTexture, Loader } from "components"
// @ts-ignore
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil"
import Object from "./Object"
import {
  design3DCanvas,
  currentBadge,
  galleryImagesLoaded,
  hotspotsVisible,
  invertLights,
  isLoading,
  isZoomed,
  meshView,
  textureSettings,
} from "store/atoms"
import { badgeSettings, hotspots } from "store/constants"
import { CanvasHolder, SceneWrapper } from "./styles"
import { HotSpotsProps } from "utils/types"
import ShirtNormal from "assets/3d/TommyShirtNormal.jpg"
import HoodNormal from "assets/3d/TommyHoodNormal.jpg"
import Shadow from "assets/images/shadow.png"
import { useTranslation } from "react-i18next"
import DataLayer from "utils/dataLayer"

interface SceneProps {
  dimensions?: {
    width: number
    height: number
    isMobile: boolean
  }
  mode?: string
  height?: string
  scrollPct?: number
  section?: number
  designSettings?: any
  screenshot?: boolean
}

let canvases = [{}, {}]

const models = [
  {
    model: "shirt",
    position: [-40, 25, 20],
    rotation: [0, 0.12, -0.15],
    scale: [2.2, 2.2, 2.2],
  },
  {
    model: "hood",
    position: [50, 200, 0],
    rotation: [0, 0.12, 0.15],
    scale: [2.2, 2.2, 2.2],
  },
]

const modelsDesktop = [
  {
    model: "shirt",
    position: [-85, 115, 20],
    rotation: [0, -0.1, 2.4],
    scale: [2.2, 2.2, 2.2],
  },
  {
    model: "hood",
    position: [80, 120, 0],
    rotation: [0, 0.12, 0.15],
    scale: [2.2, 2.2, 2.2],
  },
]

const galleryModels = [
  {
    model: "shirt",
    position: [-40, -140, 20],
    rotation: [0, 0, 0.12],
    scale: [4, 4, 4],
  },
  {
    model: "hood",
    position: [-40, -140, 20],
    rotation: [0, 0, 0.12],
    scale: [3.7, 3.7, 3.7],
  },
]

const galleryModelsDesktop = [
  {
    model: "shirt",
    position: [0, -145, 30],
    rotation: [0, 0, 0],
    scale: [4.2, 4.2, 4.2],
  },
  {
    model: "hood",
    position: [0, -155, -30],
    rotation: [0, 0, 0],
    scale: [4, 4, 4],
  },
]

const Scene: React.FC<SceneProps> = ({
  designSettings,
  dimensions,
  height,
  mode,
  screenshot,
  section,
}) => {
  // @ts-ignore
  const setCanvas3D = useSetRecoilState(design3DCanvas)
  const canvas3D = useRecoilValue(design3DCanvas)
  const view = useRecoilValue(meshView)
  const isZoom = useRecoilValue(isZoomed)
  const currBadge = useRecoilValue(currentBadge)
  const bSettings = useRecoilValue(badgeSettings)
  const spots = useRecoilValue(hotspots)
  const invert = useRecoilValue(invertLights)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const currSettings = useRecoilValue(textureSettings)
  const galleryLoading = useRecoilValue(galleryImagesLoaded)
  const [isLoading, setLoading] = useState(true)
  const [showHotspots, setShowHotspots] = useRecoilState(hotspotsVisible)
  const HoodNormalMap = useLoader(TextureLoader, HoodNormal)
  const ShirtNormalMap = useLoader(TextureLoader, ShirtNormal)
  const [startSpin, setStartSpin] = useState(0)
  const [anisotropy, setAnisotropy] = useState(16)
  const [spin, setSpin] = useState(0)
  const { t } = useTranslation()
  const directionalLight = useRef(null)

  const editor = [
    {
      model: currSettings.modelType,
      scale: [1, 1, 1],
      position: [0, 0, 0],
      rotation: [0, 0, 0],
    },
  ]

  let sceneHeight = "600px"
  if (height) {
    sceneHeight = height
  } else if (dimensions && !height) {
    sceneHeight = `${dimensions.width * 1.2}px`
  }

  useEffect(() => {
    setSpin(0)
  }, [view])

  useEffect(() => {
    if (section === 4) {
      setSpin(0)
    }
  }, [section])

  useEffect(() => {
    if (showHotspots) {
      setSpin(0)
    }
  }, [showHotspots])

  const onMount = ({ camera, gl, scene }: any) => {
    let zPos = 550
    let xPos = 0
    let yPos = 0
    if (currSettings.modelType === "shirt" && !mode) {
      zPos = 120
      yPos = 35
      xPos = 0.5
    } else if (currSettings.modelType === "hood" && !mode) {
      zPos = 140
      yPos = 40
    }

    camera.position.x = xPos
    camera.position.y = yPos
    camera.position.z = zPos

    camera.rotation.x = -0.1
    camera.setFocalLength(50)

    gl.toneMapping = 1
    gl.toneMappingExposure = 0.6

    setAnisotropy(gl.getMaxAnisotropy())

    if (screenshot) {
      // @ts-ignore
      setCanvas3D(gl.domElement)
    }
  }

  let activeSpots: HotSpotsProps[] = []
  const { modelType, split } = currSettings
  const { position, slant } = split

  const checkTakenPositions = (spot: number) => {
    if (spot === 2 || spot === 3) {
      //@ts-ignore
      return (
        currSettings.badges.findIndex(
          //@ts-ignore
          (b) => b.position === 2 || b.position === 3
        ) < 0
      )
    }

    if (spot === 1 || spot === 3) {
      //@ts-ignore
      return (
        currSettings.badges.findIndex(
          //@ts-ignore
          (b) => b.position === 1 || b.position === 3
        ) < 0
      )
    }

    //@ts-ignore
    return currSettings.badges.findIndex((b) => b.position === spot) < 0
  }

  const isAllowed = (spot: number) => {
    const currentBadgeSettings = bSettings.filter(
      (b) => b.id === currBadge.type + 1
    )
    if (currentBadgeSettings[0]) {
      const allowedPositions =
        currentBadgeSettings[0].allowed[modelType as "shirt"][
          slant ? "slant" : "vertical"
        ][position as "center"]
      return allowedPositions.includes(spot)
    }
  }

  if (section === 4 && showHotspots) {
    if (view === 0) {
      activeSpots =
        modelType === "shirt"
          ? spots.shirt.filter(
              (val) =>
                val.side === "front" &&
                checkTakenPositions(val.id) &&
                isAllowed(val.id)
            )
          : spots.hood.filter(
              (val) =>
                val.side === "front" &&
                checkTakenPositions(val.id) &&
                isAllowed(val.id)
            )
    } else if (view === 2) {
      activeSpots =
        modelType === "shirt"
          ? spots.shirt.filter(
              (val) =>
                val.side === "back" &&
                checkTakenPositions(val.id) &&
                isAllowed(val.id)
            )
          : spots.hood.filter(
              (val) =>
                val.side === "back" &&
                checkTakenPositions(val.id) &&
                isAllowed(val.id)
            )
    }
  }

  const handleLoadedScene = () => {
    setLoading(false)
  }

  const getModels = (type: any[]) => {
    return type.map((val, i) => {
      const { scale, model, position, rotation } = val

      return (
        <Object
          key={`object${i}`}
          dimensions={dimensions}
          isZoom={isZoom}
          loadedScene={handleLoadedScene}
          model={model}
          mode={mode}
          texture={canvases[i]}
          normal={model === "shirt" ? ShirtNormalMap : HoodNormalMap}
          position={position}
          rotation={rotation}
          hotspots={activeSpots}
          anisotropy={anisotropy}
          scale={scale}
          spin={spin}
          screenshot={screenshot}
          view={view}
          selectHotSpot={selectHotSpot}
        />
      )
    })
  }

  const selectHotSpot = (id: number, allowed: string[]) => {
    const newBadge = { ...currBadge }
    newBadge.position = id
    newBadge.size = 0
    setCurrBadge(newBadge)
    let multipleSizes = true
    if (modelType === "shirt" && position === "right" && slant && id === 2) {
      multipleSizes = false
    }
    if (position === "left" && slant && id === 3) {
      multipleSizes = false
    }
    if (modelType === "hood" && position === "left" && slant && id === 9) {
      multipleSizes = false
    }
    if (modelType === "shirt" && position === "right" && slant && id === 5) {
      multipleSizes = false
    }
    if (
      modelType === "shirt" &&
      position === "right" &&
      slant &&
      (id === 5 || id === 9 || id === 11)
    ) {
      multipleSizes = false
    }
    if (
      modelType === "hood" &&
      position === "right" &&
      slant &&
      (id === 2 || id === 7)
    ) {
      multipleSizes = false
    }
    setShowHotspots(false)
    navigate(
      allowed.length > 1 && multipleSizes
        ? `${t("pathStep")}6`
        : `${t("pathStep")}7`
    )
  }

  const handleSetCanvas = (index: number, texture: any) => {
    canvases[index] = texture
  }

  const getCanvases = () => {
    return canvases.map((val, i) => {
      if (mode === "galleryItem" && i > 0) {
        return false
      }

      return (
        <CanvasTexture
          key={`canvas${i}`}
          setTexture={(texture: any) => handleSetCanvas(i, texture)}
          badges={mode ? designSettings[i].badges : currSettings.badges}
          designSettings={mode ? designSettings[i] : currSettings}
          modelType={
            mode ? designSettings[i].modelType : currSettings.modelType
          }
          section={section}
        />
      )
    })
  }

  let modelSection = editor
  if (designSettings) {
    if (mode === "gallery") {
      modelSection = dimensions && dimensions.isMobile ? models : modelsDesktop
    } else {
      if (dimensions && dimensions.isMobile) {
        // @ts-ignore
        modelSection = galleryModels.filter(
          (val: any) => val.model === designSettings[0].modelType
        )
      } else {
        // @ts-ignore
        modelSection = galleryModelsDesktop.filter(
          (val: any) => val.model === designSettings[0].modelType
        )
      }
    }
  }

  const panScene = (event: any, info: any) => {
    if (!showHotspots && section !== 4) {
      event.preventDefault()
      setSpin(startSpin - info.offset.x * 0.02)
    }
  }

  const startPan = () => {
    setStartSpin(spin)
    DataLayer.event("Move_3D", "Click_Flip_Product", "engagement")
  }

  return (
    <SceneWrapper
      height={sceneHeight}
      className={`scene-wrapper ${screenshot ? "screenshot" : ""}`}
      onPanStart={startPan}
      onPan={panScene}
    >
      {isLoading && !screenshot && <Loader inline className="shirt-loader" />}
      <Canvas
        onCreated={onMount}
        shadowMap
        gl={{
          preserveDrawingBuffer: true,
        }}
      >
        <perspectiveCamera />
        <scene>
          {/* key light */}
          <directionalLight
            ref={directionalLight}
            castShadow
            color={0xffffff}
            intensity={0.6}
            position={invert ? [400, -200, -400] : [-400, 200, 400]}
          />
          {/* fill light */}
          <spotLight
            castShadow
            color={0xffffff}
            intensity={1}
            position={invert ? [-500, -600, -500] : [500, 600, 500]}
          />
          <ambientLight intensity={0.5} />
          {getModels(modelSection)}
        </scene>
      </Canvas>
      {!mode && <img src={Shadow} className="shadow" alt="shadow" />}
      <CanvasHolder>{getCanvases()}</CanvasHolder>
    </SceneWrapper>
  )
}

export default Scene
