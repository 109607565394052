import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { CheckboxWrapper } from "./styles"

interface CheckboxProps {
  checked: boolean
  name: string
  onClick: (name: string, checked: boolean) => void
  children: string | ReactNode
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  name,
  onClick,
  children,
}) => {
  const { t } = useTranslation()

  const handleOnClicked = () => {
    onClick(name, !checked)
  }

  return (
    <CheckboxWrapper className="checkbox-wrapper">
      <input
        id={name}
        type="checkbox"
        className="input"
        onChange={handleOnClicked}
        checked={checked}
        name={name}
      />
      <label className="label" htmlFor={name}>
        {children}
      </label>
    </CheckboxWrapper>
  )
}

export default Checkbox
