import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"


interface TermsProps {
    onBack: () => void
  }

const Terms: React.FC<TermsProps> =({
    onBack
  }) => {

  return (
    	<ArticleWrapper>
				<span className="back" onClick={onBack}>
						Back
					</span>
				<div className="container">
					<h1>
					ALGEMENE VOORWAARDEN
					</h1>
					<p>
					Deelname uitsluitend voorbehouden aan inwoners van Nederland, Duitsland, Frankrijk en het Verenigd Koninkrijk. Ongeldig daar waar wettelijk verboden. Deze actie loopt van 2 september tot en met 7 september. Voor deelname aan deze actie kun je tot uiterlijk 7 september 2020 je favoriete ontwerpen voor het T-shirt en de hoodie customizen en indienen. De winnaar wordt willekeurig geselecteerd op of rond 14 september 2020. De winnaar ontvangt het uiteindelijke ontworpen product, willekeurig ofwel het T-shirt ofwel de hoodie. De naam/namen van de winnaar(s) worden <a href="https://nl.tommy.com/flag/terms/comp" target="_blank">hier</a> gepubliceerd. Deelnemers moeten 18 jaar of ouder zijn om deel te mogen nemen. Kijk <a href="https://nl.tommy.com/flag/terms/comp" target="_blank">hier</a> voor meer informatie en de volledige Algemene Voorwaarden van deze actie.
					</p>
				</div>
			</ArticleWrapper>
  )
}

export default Terms


