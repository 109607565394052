import styled from "styled-components"
import { motion } from "framer-motion"
import { colours } from "theme"

interface OverlayProps {
  inline?: boolean
  relative? : boolean
}

interface SpinnerProps {
  inverse?: boolean
}

export const Overlay = styled(motion.div)<OverlayProps>`
  position: ${(props) => (props.relative ? "relative" : (props.inline ? "absolute" : "fixed"))};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: ${props => props.relative ? "80vh" : "100px"};
  background: ${(props) => (props.inline ? "transparent" : colours.grey2)};
  justify-content: center;
  align-items: center;
  z-index: 40;
  overflow: hidden;
`

export const Spinner = styled(motion.div)<SpinnerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;

  @keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg);
  }
  
  
`

export const Wipe1 = styled(motion.div)`
  position: absolute;
  top: 400vh;
  height: 400%;
  left: 0;
  right: 0;
  width: 100%;
  background: ${colours.primary};
  z-index: 50;
`
