import styled from "styled-components"

import { breakpoints } from "theme"

const NavigationLinkWrapper = styled.div`
  flex-wrap: wrap;
  flex-direction: initial !important;
  justify-content: space-between;

  ${breakpoints.md`
    flex-direction: column !important;    
  `}

  li.list__link {
    width: calc(50% - 10px);

    ${breakpoints.md`
      width: auto;
    `}

    > a {
      font-weight: normal;
      font-size: inherit;
      text-transform: none;
      letter-spacing: 1px;
      padding: 0 4px;
      height: 75px;
      justify-content: center;
      text-align: center;

      ${breakpoints.md`
          padding: 4px 0;
          height: auto;
          text-align: left;
          font-size: 0.85rem;
          justify-content: flex-start;
        `}
    }
  }
`

export default NavigationLinkWrapper
