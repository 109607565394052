import styled, { keyframes } from "styled-components/macro"
import { colours } from "theme"
import DownArrow from "assets/images/down-arrow.svg"

interface SelectProps {
  width: number
}

export const SelectWrapper = styled.div<SelectProps>`
  width: ${(props) => props.width}px;
  position: relative;
  margin: 1.5rem 0 2rem 0;
  padding: 0;
  width: 100%;

  select {
    height: 3rem;
    padding: 0 1rem;
    border-radius: 0.2rem;
  }

  .select {
    &:hover {
      cursor: pointer;
    }

    padding: 0.5rem 1rem;
    appearance: none;
    border-bottom: 1px solid white;
    color: black;
    transition: border-color 0.15s, color 0.3s;
    color: ${colours.darkGrey2};

    outline: 0;
    background-color: white;

    &.selected,
    &.active,
    &:focus,
    &:active {
      outline: 0;
      border-bottom-color: black;
    }
    &:focus,
    &:active {
      & ~ .arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &::-ms-expand {
      display: none !important;
    }
  }
  option {
    color: black;
  }

  .arrow {
    pointer-events: none;
    width: 1em;
    height: 0.9em;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    transition: transform 0.3s;
    background: url(${DownArrow}) center / contain no-repeat;
    opacity: 0.8;
  }
`
