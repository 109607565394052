import React, {useState} from 'react';

import Brand from './Brand';
import Navigation from './Navigation';
import {FindAStore, HeaderWrapper} from './styles';
import SideIcons from "./SideIcons";
import SearchForm from "./SearchForm";
import {viewport} from "../../store/atoms";
import {size} from "../../theme/breakpoints";
import {useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";

const Header: React.FC = () => {

const [searchFormOpen, toggleSearchForm] = useState(false);
const { t } = useTranslation()
const handleSearchClick = () => {toggleSearchForm(!searchFormOpen)}
const viewPort = useRecoilValue(viewport);
const isMobile = viewPort.width < size.md;

return (
    <HeaderWrapper>
      <Navigation />
      {!isMobile && <FindAStore href={t('storeLocatorLink')}>{t('findaStore')}</FindAStore>}
      <Brand />
      <SideIcons onSearchClick={handleSearchClick} />
      {searchFormOpen && <SearchForm />}
    </HeaderWrapper>
)};

export default Header;
