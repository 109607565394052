import React from "react"
import {
  Wrapper,
  Title,
  BodyText,
  Action,
  GreenButton,
  SettingsButton, Content, Buttons,
} from "./styles"
import { useTranslation } from "react-i18next"

interface FooterProps {
  translations: any
  agreeOnClick: (analytics: boolean, socialMedia: boolean) => void
  settingsOnClick: () => void
}

export const CookieFooter: React.FC<FooterProps> = ({
  translations,
  settingsOnClick,
  agreeOnClick,
}) => {
  const { t } = useTranslation()
  return (
  <Wrapper>
    <Content>
      <Title>{t("cookiePreferences")}</Title>
      <BodyText dangerouslySetInnerHTML={{__html : t("cookieNotice")}}/>
    </Content>
    <Buttons>
      <Action>
        <GreenButton onClick={() => agreeOnClick(true, true)}>
          {t("cookieAgree")}
        </GreenButton>
        <SettingsButton onClick={() => settingsOnClick()}>
        {t("cookieSettings")}
        </SettingsButton>
      </Action>
    </Buttons>
  </Wrapper>
)}

export default CookieFooter
