import React from "react"
import { useSetRecoilState } from "recoil"
import { modal } from "store/atoms"
import { ModalContent } from "./styles"

import { Button, Text } from "components"

const VoteSubmitted: React.FC = () => {
  const setModal = useSetRecoilState(modal)

  const close = () => {
    // @ts-ignore
    setModal({ ...modal, show: false })
  }

  return (
    <ModalContent>
      <Text textId="modalVoteSubmittedHead" type="h3" />
      <Text textId="modalVoteSubmittedBody" type="p" />
      <Button colour="secondary2" textId="buttonOK" onClick={close} />
    </ModalContent>
  )
}

export default VoteSubmitted
