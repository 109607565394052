import React from 'react';

import TommyBorderWrapper from './styles';

const TommyBorder: React.FC = () => (
  <TommyBorderWrapper className="top-borders">
    <div className="border" />
    <div className="border" />
    <div className="border" />
    <div className="border" />
  </TommyBorderWrapper>
);

export default TommyBorder;
