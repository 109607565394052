import React, { useRef, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { modal } from "store/atoms"
import { viewport, locale } from "store/atoms"
import { configSettings } from "store/selectors"
import Router, { navigate } from "@reach/router"
import { TermsWrapper } from "./styles"
import * as TermsContent from "./body"

export interface Props extends Router.RouteComponentProps<{
  termsType: string
}> {}

declare global {
  namespace JSX {
      interface IntrinsicElements {
          'longTerms': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
          'shortTerms': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
  }
}

const Terms: React.FC<Props> = ({termsType}) => {
  const { longCode: localeCode } = useRecoilValue(locale)
  let LongTerms = TermsContent.enUKComp
  let ShortTerms = TermsContent.enUKShort 

  switch(localeCode) {
    case "fr-FR" : {
      LongTerms = TermsContent.frFrComp
      ShortTerms = TermsContent.frFrShort
      break 
    }

    case "de-DE" : {
      LongTerms = TermsContent.deDeComp
      ShortTerms = TermsContent.deDeShort
      break 
    }

    case "nl-NL" : {
      LongTerms = TermsContent.nlNlComp
      ShortTerms = TermsContent.nlNlShort
      break 
    }

    default: break
  }

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <TermsWrapper>
        {termsType === "comp" && <LongTerms onBack={handleBack}/>}
        {termsType === "short" && <ShortTerms onBack={handleBack}/>} 
    </TermsWrapper>
  )
}

export default Terms
