import colours from "./colours"
import layout from "./layout"
import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

 
html{
  height: 100vh;
    overflow-x: hidden;

}
body {
  height: -webkit-fill-available;
  
}
html,
body {
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  color: ${colours.bodyCopy};
  background: ${colours.background};
  font-family: FuturaMedium, Arial, sans-serif;
  letter-spacing: 0;    
  overscroll-behavior: none;
  
}

.flag_root {
  position: relative;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    tap-highlight-color: transparent;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  &:root {
    font-size: 16px;
  }

  min-height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  display: flex;
  font-size: 1rem;
  line-height: 1.4rem;
  color: ${colours.primary};
    b, span{
    display: contents;

    &.underline{
      text-decoration: underline;
    }
  }
  div,span,a,p,h1,h2,h3,h4,h5,h6, input{
    flex-direction: column;
    display: flex;

    b, span{
      display: contents;

      &.underline{
        text-decoration: underline;
      }
    }
  }

  input[type="text"] {
    outline: none;
    border: none;
    background: ${colours.white};
    border-radius: 0.3rem;
    height: 3rem;
    box-shadow: inset 1px 0 5px rgba(0,0,0,0.3);
    padding: 0 1rem;
    color: ${colours.bodyCopy};
    -webkit-user-select: auto;
  }

  input {
    &:focus,
    textarea {
      outline: none !important;
    }
  }


  h1,h2,h3,h4,h5,h6 {
    font-family: FuturaBold, Arial, san-serif;
    text-transform: uppercase;
    font-weight: 100;
    line-height: 1;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
  strong {
    font-weight: 600;
    color: {colours.black} !important;
  }

  p {
    line-height: 1.6;
  }

  input, select {
    -webkit-appearance: none;
    appearance: none;
  }
}


`
