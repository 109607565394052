import styled from 'styled-components';
import { colours } from '../../../theme';

const TommyBorderWrapper = styled.div`
  .border {
    height: .3675rem;

    &:nth-child(1) {
      background: ${colours.blue};
    }

    &:nth-child(2) {
      background: ${colours.white};
    }

    &:nth-child(3) {
      background: ${colours.red};
    }

    &:nth-child(4) {
      background: ${colours.blue};
    }
  }
`;

export default TommyBorderWrapper;
