import styled from 'styled-components';
import { colours } from '../../theme';

const GlobalHeaderWrapper = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  height: 78px;
  background: ${colours.white};

  .brand-logo {
    align-items: center;
  }

`;

export default GlobalHeaderWrapper;
