import { atom, selector } from "recoil"
import { size } from "../theme/breakpoints"

export type Gender = "man" | "woman"
export type Category = "outwear" | "bottoms" | "t-shirts"

export const init = atom({
  key: "init",
  default: false,
})

export const viewport = atom({
  key: "viewport",
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < 922,
  },
})

export const isMobile = selector({
  key: "isMobile",
  get: ({ get }) => {
    const viewportState = get(viewport)
    return viewportState.width < size.sm
  },
})

export const isOpen = atom({
  key: "isOpen",
  default: false,
})

export const startAnimCompleted = atom({
  key: "startAnimCompleted",
  default: false,
})

export const scrollY = atom({
  key: "scrollY",
  default: 0,
})

export const editorStep = atom({
  key: "editorStep",
  default: 1,
})

export const isLoading = atom({
  key: "isLoading",
  default: {
    status: false,
    wipe: false,
  },
})

export const meshView = atom({
  key: "meshView",
  default: 0,
})

export const design3DCanvas = atom({
  key: "design3DCanvas",
  default: null,
})

export const currentCanvas = atom({
  key: "currentCanvas",
  default: {},
})

export const isZoomed = atom({
  key: "isZoomed",
  default: false,
})

export const stepDirection = atom({
  key: "stepDirection",
  default: "none",
})

export const homeImagesLoaded = atom({
  key: "homeImagesLoaded",
  default: false,
})

export const auctionImagesLoaded = atom({
  key: "auctionImagesLoaded",
  default: false,
})

export const galleryImagesLoaded = atom({
  key: "galleryImagesLoaded",
  default: { loaded: false, loading: false },
})

export const showCreateYourOwn = atom({
  key: "showCreateYourOwn",
  default: false,
})

export const hotspotsVisible = atom({
  key: "hotspotsVisible",
  default: false,
})

export const currentOffset = atom({
  key: "currentOffset",
  default: { position: "center", slant: false },
})

export const currentBadge = atom({
  key: "currentBadge",
  default: { type: -1, position: -1, size: -1 },
})

export const invertLights = atom({
  key: "invertLights",
  default: false,
})

export const textureSettings = atom({
  key: "textureSettings",
  default: {
    split: {
      slant: false,
      position: "center",
    },
    leftColour: "",
    rightColour: "",
    modelType: "shirt",
    badges: new Array(),
    imageUrl: "",
  },
})

export const modal = atom({
  key: "modal",
  default: { show: false, content: -1 },
})

export const submittedDesign = atom({
  key: "submittedDesign",
  default: { designId: "" },
})

export const currGalleryItem = atom({
  key: "currGalleryItem",
  default: {
    type: "hood",
    number: 0,
    id: "no id found",
    imageUrl: "",
  },
})

export const shirtFinalists = atom({
  key: "shirtFinalists",
  default: new Array(),
})

export const hoodFinalists = atom({
  key: "hoodFinalists",
  default: new Array(),
})

export const rootPath = atom({
  key: "rootPath",
  default: "/",
})

export const locale = atom({
  key: "locale",
  default: { shortCode: "en", longCode: "en-UK", pathLocale: "" },
})

export const justCreated = atom({
  key: "justCreated",
  default: false,
})
