import styled from "styled-components"
import { breakpoints, colours } from "../../../theme"

export const Wrapper = styled.div`
  display: flex;
  outline: none;
  box-sizing: border-box;
  animation: fade-in-backdrop 0.4s linear both;
  background: rgba(0, 23, 79, 0.9);
  overflow: auto;
  margin: 0;
  color: ${colours.white};
  padding: 20px;
  position: relative;

  ${breakpoints.lg`
     flex-direction: row !important;
     padding: 30px 50px;
  `}
`

export const Content = styled.div`
  margin-bottom: 30px;
  
${breakpoints.lg`
    justify-content: flex-end;
    flex: 77;
    margin-bottom: 0;
  `}
`

export const Buttons = styled.div`
  ${breakpoints.lg`
    justify-content: flex-end;
    flex: 23;
    padding-left: 30px;
  `}
`

export const Title = styled.h5`
  font-family: Futura, Calibri, sans-serif;
  text-transform: uppercase;
  font-size: 11px !important;
  font-weight: 700 !important;
  letter-spacing: 0.86px;
  line-height: 24px !important;
  margin: 0 0 10px;
  color: ${colours.white};
`

export const BodyText = styled.div`
  && {
    display: inline-block;
    font-family: Futura, Calibri, sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: normal;
    color: ${colours.white};
  
    a {
      display: inline-block;
      text-decoration: underline;
      color: ${colours.white};
      
      ${breakpoints.lg`
        font-size: 15px;
        line-height: 24px;
      `}
    }
  
    ${breakpoints.lg`
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 0;
    `}
  }
`

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const GreenButton = styled.button`
  display: block;
  flex-shrink: 0;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 24px;
  font-family: Futura, Calibri, sans-serif;
  background-color: #14be82;
  border: #14be82 solid 2px;
  color: ${colours.white};
  outline: none;
  cursor: pointer;
  /* font-family: Futura,Calibri,sans-serif; */
  max-width: 100%;
  height: 35px;
  min-width: 0;
  padding: 0 22px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition-property: background-color, border-color, color;
  transition-timing-function: ease-in-out;

  :hover {
    background-color: transparent;
    border-color: #14be82;
    color: #14be82;
  }

  ${breakpoints.md`
    max-width: 280px;
  `}
`

export const SettingsButton = styled.button`
  font-family: Futura, Calibri, sans-serif;
  text-decoration: underline;
  color: ${colours.white};
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  background: none;
  outline: none;
  cursor: pointer;
  flex-shrink: 0;
  border: 0;

  ${breakpoints.md`
    max-width: 280px;
  `}

  ${breakpoints.lg`
    font-size: 15px;
    line-height: 24px;
  `}
`
