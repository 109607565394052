import styled from "styled-components/macro"
import { motion } from "framer-motion"
import { colours } from "theme"
import { lighten } from "polished"

interface AddorEditProps {
  size: number
}

export const AddorEditWrapper = styled.div<AddorEditProps>`
  position: relative;
  justify-content: center;
  align-items: center;
  background: ${colours.primary};
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin: 0 ${(props) => props.size / 10}px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  &.badge {
    background: ${colours.white};
    svg {
      width: 60%;
      height: auto;
    }

    &.portrait {
      svg {
        height: 60%;
        width: auto;
      }
    }
  }

  &:active {
    transition: 0.1s ease-out;
    transform: scale(0.9);
  }
`

export const EditIcon = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${colours.primary};
  justify-content: center;
  align-items: center;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  svg {
    transform: scale(0.7);
  }
`
