export default {
  detectLanguageFromUrl: (config, localeOptions) => {
    if (config.useURLsForLanguages === false) return

    const urlLocaleRegex = config.urlLocaleRegex
    const urlCountryRegex = config.urlCountryRegex

    let countryCode = config.defaultCountryCode
    let locale = config.defaultLanguage
    let pathCode = ""

    if (urlCountryRegex) {
      let matches = window.location.href.toLowerCase().match(urlCountryRegex)
      if (matches && matches.length > 1) {
        countryCode = matches[1]
        locale = localeOptions[countryCode] || localeOptions["default"]
      }
    }

    if (urlLocaleRegex) {
      let matches = window.location.href.toLowerCase().match(urlLocaleRegex)
      if (matches && matches.length > 1) {
        locale = matches[1]
        pathCode = matches[1]
      }
    }

    if (
      locale !== config.defaultLanguage ||
      countryCode !== config.defaultCountryCode
    ) {
      // console.log(`switching to... ${locale}-${countryCode?.toUpperCase()}`);
      return {
        newLang: `${locale}-${countryCode?.toUpperCase()}`,
        locale,
        pathCode,
      }
    }

    return null
  },

  getBaseUrl: (config, localeCode) => {
    if (!config.baseUrl[localeCode]) {
      return config.baseUrl["default"]
    }
    return config.baseUrl[localeCode]
  },

  getRootPath: (baseUrl, path) => {
    let rootPath = "/"
    const pathRegex = `\/(.*${baseUrl}).*`
    let matches = path.match(pathRegex) || []
    if (matches.length > 0) rootPath = matches[1]
    return rootPath
  },

  addSlashToUrl: () => {
    //If there is no trailing shash after the path in the url add it
    if (window.location.pathname.endsWith("/") === false) {
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "/" +
        window.location.search

      window.history.replaceState(null, document.title, url)
    }
  },
}
