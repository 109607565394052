import styled, { keyframes } from "styled-components/macro"
import { colours } from "theme"
import { rgba } from "polished"

interface ItemWrapperProps {
  dimensions: {
    width: number
    height: number
  }
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const CreateWrapper = styled.div`
  position: relative;
  padding: 1.5rem;
  width: 100%;
  flex: 1;

  h5 {
    margin: 1rem 0 1rem;
    font-size: 1.125rem;
  }

  .button {
    margin-left: auto;
  }
  @media (min-width: 600px) {
    h5 {
      font-size: 2rem;
      margin: 2rem auto -3rem;
    }
  }
`

export const ItemWrapper = styled.div<ItemWrapperProps>`
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: auto;

  .item {
    &:last-of-type {
      > div {
        align-items: flex-end;
      }
    }
  }

  .shadow,
  .shirt,
  .hood {
    width: ${(props) => props.dimensions.width * 0.37}px;
  }

  .shadow {
    opacity: 0.4;
  }

  @media (min-width: 600px) {
    flex: none;
    flex-direction: row !important;
    justify-content: space-between;
    width: 80%;
    max-width: 800px;
    margin: auto;

    .item {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      height: initial;
    }

    .shadow,
    .shirt,
    .hood {
      width: ${(props) => props.dimensions.width * 0.28}px;
      max-width: 300px;
    }
  }

  @media (min-width: 992px) {
    position: relative;
    top: -1rem;
    .item {
      transition: 0.2s ease-out;
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transition: 0.05s ease-out;
        transform: scale(0.95);
      }
    }
  }
`

export const Divider = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -200px;
  width: 400px;
  height: 1px;
  opacity: 0;
  transform-origin: center center;
  transform: rotate(-45deg);
  background: ${rgba(colours.primary, 0.2)};
  animation: ${fadeIn} 2s 1s forwards;
  @media (min-width: 600px) {
    transform: rotate(-70deg);
  }
`
