import React from 'react';

import GlobalFooterBottomLinksWrapper from './styles';

const GlobalFooterBottomLinks = () => (
  <GlobalFooterBottomLinksWrapper>
    <div className="left">
      <a href="/live">Tommy Hilfiger Licensing, LLC. All rights reserved 2020</a>
    </div>
    <div className="right">
      <a href="https://uk.tommy.com/terms-and-conditions#PRIVACY_NOTICE">Privacy Policy</a>
      <a href="/live"> Cookie Policy</a>
      <a href="https://global.tommy.com/en_int/live-contest"> Terms &amp; Conditions</a>
    </div>
  </GlobalFooterBottomLinksWrapper>
);

export default GlobalFooterBottomLinks;
