import React from "react"
import { motion, useMotionValue, useTransform } from "framer-motion"

interface TommyOutlineProps {
  imagesLoaded: boolean
}

const TommyOutline: React.FC<TommyOutlineProps> = ({ imagesLoaded }) => {
  const pathLength = useMotionValue(0)
  const opacity = useTransform(pathLength, [0.05, 0.5], [0, 1])

  const line1Variants = {
    active: {
      pathLength: 1,
      transition: {
        duration: 2,
        delay: imagesLoaded ? 0 : 0.7,
      },
    },
    inactive: { pathLength: 0 },
  }

  const line2Variants = {
    active: {
      pathLength: 1,
      transition: {
        duration: 2.5,
        delay: imagesLoaded ? 0 : 0.5,
      },
    },
    inactive: { pathLength: 0 },
  }
  const line3Variants = {
    active: {
      pathLength: 1,
      transition: {
        duration: 2,
        delay: imagesLoaded ? 0 : 0.1,
      },
    },
    inactive: { pathLength: 0 },
  }

  return (
    <motion.svg
      viewBox="0 0 375 147"
      preserveAspectRatio="none"
      initial="inactive"
      animate={imagesLoaded ? "active" : "inactive"}
    >
      <g strokeWidth="1" stroke="#FFFFFF">
        <motion.path
          d="M0,146.5 L375,146.5"
          variants={line3Variants}
          style={{ pathLength, opacity }}
          custom={imagesLoaded}
        />
        <motion.path
          d="M187,0.5 L187,146.5"
          variants={line2Variants}
          style={{ pathLength, opacity }}
          custom={imagesLoaded}
        />
        <motion.path
          d="M0,0.5 L375,0.5"
          variants={line1Variants}
          style={{ pathLength, opacity }}
          custom={imagesLoaded}
        />
      </g>
    </motion.svg>
  )
}

export default TommyOutline
