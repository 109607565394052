import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"


interface TermsProps {
    onBack: () => void
  }

const Terms: React.FC<TermsProps> =({
    onBack
  }) => {

  return (
    	<ArticleWrapper>
				<span className="back" onClick={onBack}>
						Back
					</span>
				<div className="container">
					<h1>
						ALLGEMEINE GESCHÄFTSBEDINGUNGEN
					</h1>
					<p>
						Teilnahme nur für Personen mit Wohnsitz in den Niederlanden, Deutschland, Frankreich oder im Vereinigten Königreich. Das Gewinnspiel ist nichtig, wo es gesetzlich verboten ist. Das Gewinnspiel geht vom 2. September bis zum 7. September. Um an diesem Gewinnspiel teilzunehmen, darfst du deine gestalteten und bevorzugten Designs für das T-Shirt und den Hoodie nicht später als am 7. September 2020 einreichen. Der Gewinner wird nach Zufallsprinzip am oder um den 14. September 2020 ausgewählt. Der Gewinner erhält das designte Produkt, entweder T-Shirt oder Hoodie, per Zufall. Der/die Name(en) des/der Gewinner werden auf <a href="https://de.tommy.com/flag/terms/comp" target="_blank">allgemeinen Geschäftsbedingungen</a> veröffentlicht. Teilnehmer müssen 18 Jahre alt oder älter sein, um teilzunehmen. Weitere Informationen findest du in unseren <a href="https://de.tommy.com/flag/terms/comp" target="_blank">allgemeinen Geschäftsbedingungen</a> zu diesem Gewinnspiel.
					</p>
				</div>
			</ArticleWrapper>
  )
}

export default Terms


