// eslint-disable-next-line no-use-before-define
import React from 'react';

import FooterBottomLinks from './FooterBottomLinks';
import FooterTopLinks from './FooterTopLinks';
import FooterWrapper from './styles';

const Footer: React.FC = () => (
  <FooterWrapper>
    <FooterTopLinks />
    <FooterBottomLinks />
  </FooterWrapper>
);

export default Footer;
