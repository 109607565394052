import React, { createElement, useEffect, useRef, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil"
import {
  currentBadge,
  hotspotsVisible,
  textureSettings,
  stepDirection,
  viewport,
} from "store/atoms"
import { FlexRow } from "theme"
import { IconButton, InlineButton, NextButton, Text } from "components"
import {
  DesktopPatchHolder,
  Disclaimer,
  Dot,
  DragContainer,
  DragRow,
  StepsWrapper,
} from "./styles"
import { ReactComponent as Tommy0 } from "assets/images/tommy0.svg"
import { ReactComponent as Tommy1 } from "assets/images/tommy1.svg"
import { ReactComponent as Tommy2 } from "assets/images/tommy2.svg"
import { ReactComponent as Tommy3 } from "assets/images/tommy3.svg"
import { ReactComponent as Tommy4 } from "assets/images/tommy4.svg"
import { ReactComponent as Tommy5 } from "assets/images/tommy5.svg"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import { useTranslation } from "react-i18next"

const badges = [
  { id: 0, img: Tommy0 },
  { id: 1, img: Tommy1 },
  { id: 2, img: Tommy2 },
  { id: 3, img: Tommy3 },
  { id: 4, img: Tommy4 },
  { id: 5, img: Tommy5 },
]

const Step4: React.FC<RouteComponentProps> = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const view = useRecoilValue(viewport)
  const [showHotspots, setShowHotspots] = useRecoilState(hotspotsVisible)
  const currBadge = useRecoilValue(currentBadge)
  const settings = useRecoilValue(textureSettings)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const direction = useRecoilValue(stepDirection)
  const setDirection = useSetRecoilState(stepDirection)
  const dragCont = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    const newBadge = { ...currBadge }
    newBadge.type = -1
    setCurrBadge(newBadge)
  }, [])

  const filteredBadges = badges.filter(
    // @ts-ignore
    (b) => settings.badges.findIndex((sb) => sb.type === b.id) < 0
  )

  const chooseBadge = (id: number, index: number) => {
    const newBadge = { ...currBadge }
    newBadge.type = id
    setSelectedIndex(index)
    setCurrBadge(newBadge)
    setShowHotspots(true)
  }

  const goto = (url: string) => {
    if (url === `${t("pathStep")}5` && currBadge.type === -1) {
      return false
    }

    const dir = url === "step5" ? "right" : "left"
    setDirection(dir)
    navigate(url)
  }

  const getButtons = () => {
    return filteredBadges.map((val, i) => {
      return (
        <IconButton
          badge
          key={`iconButton${i}`}
          onClick={() => chooseBadge(val.id, i)}
          active={currBadge.type === val.id}
          size={{ width: 200, height: 130 }}
          portrait={val.id === 5}
        >
          {createElement(val.img)}
        </IconButton>
      )
    })
  }

  const getDots = () => {
    return filteredBadges.map((val, i) => {
      return (
        <Dot
          key={`dot${i}`}
          onClick={() => chooseBadge(val.id, i)}
          active={currBadge.type === val.id}
        />
      )
    })
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  return (
    <StepsWrapper
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
      className="step4"
    >
      <Text
        textId={showHotspots ? "editorPatchLocation" : "editorChoosePatch"}
        type="h5"
      />
      {view.isMobile && (
        <DragContainer ref={dragCont} className="scalable">
          <DragRow
            animate={{ x: -selectedIndex * 190 }}
            drag="x"
            dragConstraints={{ left: -filteredBadges.length * 166, right: 0 }}
            dragElastic
          >
            {getButtons()}
          </DragRow>
        </DragContainer>
      )}
      {!view.isMobile && (
        <DesktopPatchHolder>{getButtons()}</DesktopPatchHolder>
      )}
      <FlexRow className="row center dots">{getDots()}</FlexRow>
      {showHotspots && (
        <Disclaimer>
          <Text textId="editorPatchLocationDescription" type="p" />
        </Disclaimer>
      )}
      <FlexRow className="row center">
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={() => goto(`${t("pathStep")}3`)}
        />
        {/* <NextButton
          onClick={() => goto(`${t("pathStep")}5`)}
          disabled={currBadge.type === -1}
        /> */}
      </FlexRow>
    </StepsWrapper>
  )
}

export default Step4
