import React, { useState, useEffect, useContext } from 'react';
import { useRecoilValue } from 'recoil';

import NavigationItemCategory from './NavigationItemCategory';
import { viewport, locale, rootPath } from '../../../../store/atoms';
import { size } from '../../../../theme/breakpoints';

import UTILS from './utils';
import NavigationItemsWrapper from './styles';

interface NavigationItemsProps {
  open?: boolean;
}

const NavigationItems: React.FC<NavigationItemsProps> = ({ open }) => {
  const { longCode: localeCode, shortCode: urlCode, pathLocale } = useRecoilValue(locale)

  const [items, setItems] = useState<any>([]);
  const [expanded, setExpanded] = useState('');

  const viewPort = useRecoilValue(viewport);
  const isMobile = viewPort.width < size.md;

  const updateLanguageData :any = async () => {
    const data = await UTILS.getDataFromLang(localeCode);
    return data;
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await updateLanguageData();
      setItems(result.data);
    };

    fetchData();
  }, [localeCode]);

  const expandCategory = (name: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setExpanded(name);
  };

  // useEffect(updateLanguageData, [localeCode]);

  return (
    <NavigationItemsWrapper aria-hidden={open}>
      <ul className="items">
        {items.map((item: any) => {
          const {
            url: relativePath, name, id, children,
          } = item;

          const domain = window.location.host
          const isActive = id === expanded;
          const baseUrl = `//${domain}${pathLocale ? "/" + pathLocale.toUpperCase() : ""}`
          const url = `${baseUrl}/${relativePath}`;
          const linkProps = !isMobile && { href: url, rel: 'noopener' };

          return (
            <li
              className="item"
              key={id}
              onClick={expandCategory.bind(true, id)}
            >
              <a
                className={`link ${expanded && isActive ? 'active' : ''}`}
                {...linkProps}
              >
                {name}
                {isMobile && <i className="icon icon-arrow-right" />}
              </a>
              <ul className="category" aria-expanded={isActive}>
                {isMobile && (
                  <>
                    <button className="back" onClick={expandCategory.bind(true, '')}>
                      <i className="icon icon-arrow-left" />
                      Home
                    </button>
                    <h4 className="category__h4">{name}</h4>
                  </>
                )}
                <NavigationItemCategory
                  parentName={name}
                  itemChildren={children}
                  baseUrl={baseUrl}
                  isMobile={isMobile}
                />
              </ul>
            </li>
          );
        })}
      </ul>
    </NavigationItemsWrapper>
  );
};

export default NavigationItems;
