import React from "react"
import { navigate } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import {
  currentBadge,
  modal,
  textureSettings,
  submittedDesign,
} from "store/atoms"
import { defaultBadge, defaultDesign } from "store/constants"
import { ModalContent } from "./styles"
import { configSettings } from "store/selectors"
import { Button, Text } from "components"
import { useTranslation } from "react-i18next"
import { rootPath } from "store/atoms"

const Submitted: React.FC = () => {
  const setModal = useSetRecoilState(modal)
  const resetDesign = useRecoilValue(defaultDesign)
  const resetBadge = useRecoilValue(defaultBadge)
  const updateTexture = useSetRecoilState(textureSettings)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const config = useRecoilValue(configSettings)
  const { t } = useTranslation()
  const rootUrl = useRecoilValue(rootPath)
  const design = useRecoilValue(submittedDesign)
  const close = () => {
    // @ts-ignore
    setModal({ ...modal, show: false })
    const root = rootUrl === "/" ? rootUrl : `/${rootUrl}/`

    updateTexture(resetDesign)
    setCurrBadge(resetBadge)

    if (config.useCRM === true) {
      navigate(`${root}${t("pathSignup")}`)
    } else {
      navigate(`${root}${t("pathGalleryItem")}/${design.designId}`)
    }
  }

  return (
    <ModalContent>
      <Text textId="modalSubmittedHead" type="h3" />
      <Text textId="modalSubmittedBodyv2" type="p" />
      <Button colour="secondary2" textId="buttonOK" onClick={close} />
    </ModalContent>
  )
}

export default Submitted
