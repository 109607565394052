import styled from 'styled-components';
import { colours } from '../../theme';

const FooterWrapper = styled.footer`
  font-family: FuturaMedium,Arial,sans-serif;
  font-sze: 16px;
  background-color: #cc0c2f;
  color: ${colours.white};
  -webkit-font-smoothing: antialiased;
  
  .items {
    list-style-type: none;
    padding-bottom: 12px;

    .item {
      margin-top: 10px;
    }
  }

  .link {
    font-size:14px;
    
    &:link,
    &:visited {
      color: ${colours.white};
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
  }
  
  @media (min-width: 1280px) {
    .items {
      padding: 0;
      
      .item:first-child {
        margin-top: 0;
      }
    }
  }
`;

export default FooterWrapper;
