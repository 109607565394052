import styled from "styled-components/macro"
import { colours } from "theme"
import { darken } from "polished"

interface BadgeButtonProps {
  big?: boolean
  circle?: boolean
}

export const BadgeButtonWrapper = styled.div<BadgeButtonProps>`
  position: relative;
  z-index: 5;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.big ? "4.2rem" : "2.7rem")};
  width: ${(props) => (props.big ? "4.2rem" : "2.7rem")};
  border-radius: 50%;

  svg {
    transform: scale(${(props) => (props.circle ? 0.8 : 1)});
  }

  transition: 0.2s ease-out;
  background: ${colours.white};
  cursor: pointer;
  &:active {
    transform: scale(0.8);
  }
  div {
    font-family: ${(props) => (props.circle ? "futuraBold" : "futuraMedium")},
      futura, arial, sans-serif;
    position: absolute;
    top: ${(props) => (props.circle ? "120%" : "100%")};
    font-size: ${(props) => (props.circle ? "0.5rem" : "0.7rem")};
    text-align: center;
    line-height: 1.1;
    white-space: nowrap;
    text-transform: ${(props) => (props.circle ? "uppercase" : "none")};
    color: ${colours.primary};
  }

  &.voted {
    background: ${darken(0.05, colours.white)};
    &:active {
      transform: scale(1);
    }
    div {
      opacity: 0.5;
    }
  }

  @media (min-width: 992px) {
    height: ${(props) => (props.big ? "6rem" : "3.2rem")};
    width: ${(props) => (props.big ? "6rem" : "3.2rem")};

    svg {
      transform: scale(
        ${(props) => (props.circle ? (props.big ? 1 : 0.9) : 1)}
      );
    }
    div {
      font-size: ${(props) => (props.circle ? "0.7rem" : "0.7rem")};
    }
  }
`
