import React from "react"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { BadgeButton, Text } from "components"
import { FlexRow } from "theme"
import { currGalleryItem, modal } from "store/atoms"
import { ModalContent } from "./styles"
import { ReactComponent as Twitter } from "assets/images/iconTwitter.svg"
import { ReactComponent as Facebook } from "assets/images/iconFacebook.svg"
import { ReactComponent as WeChat } from "assets/images/iconWeChat.svg"
import { ReactComponent as Email } from "assets/images/iconEmail.svg"
import { ReactComponent as Weibo } from "assets/images/iconWeibo.svg"
import { ReactComponent as CopyUrl } from "assets/images/iconCopyUrl.svg"
import { ReactComponent as Close } from "assets/images/iconClose.svg"
import { useTranslation } from "react-i18next"
import copy from "copy-to-clipboard"

const clickLink = (url: string) => {
  const anchor = document.createElement("a")
  // @ts-ignore
  anchor.href = url
  anchor.target = "_blank"
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

const Share: React.FC = () => {
  const setModal = useSetRecoilState(modal)
  const item = useRecoilValue(currGalleryItem)
  const { t } = useTranslation()
  const shareText = t("metaSocial")
  const shareTitle = t("metaTitle")

  const shareToTwitter = () => {
    clickLink(
      `https://twitter.com/intent/tweet?text=${shareText}&url=${window.location.href}`
    )
  }

  const shareToFacebook = () => {
    FB.ui(
      {
        method: "share",
        href: window.location.href,
      },
      function (response) {}
    )
  }

  // Need the correct text
  const shareToEmail = () => {
    clickLink(`mailto:?subject=${shareTitle}&body=${window.location.href}`)
  }

  // Need the correct text
  const shareToUrl = () => {
    copy(window.location.href)
  }

  const rows = [
    [
      { img: Twitter, name: "Twitter", submit: shareToTwitter },
      { img: Facebook, name: "Facebook", submit: shareToFacebook },
     
    ],
    [ 
      { img: Email, name: "Email", submit: shareToEmail },
      { img: CopyUrl, name: "CopyUrl", submit: shareToUrl }],
  ]

  const close = () => {
    // @ts-ignore
    setModal({ ...modal, show: false })
  }

  const handleSocialItem = (socialSrc: any) => {
    socialSrc.submit()
  }

  const getSocialButtons = (rowNum: number) => {
    return rows[rowNum].map((val, i) => {
      return (
        <BadgeButton
          key={val.name}
          className={val.name}
          textId={val.name}
          icon={val.img}
          onClick={() => handleSocialItem(val)}
        />
      )
    })
  }

  return (
    <ModalContent className="share">
      <Text textId="modalShareHead" type="h3" />
      <FlexRow className="row around">{getSocialButtons(0)}</FlexRow>
      <FlexRow className="row around">{getSocialButtons(1)}</FlexRow>
      <FlexRow className="row center">
        <Close onClick={close} />
      </FlexRow>
    </ModalContent>
  )
}

export default Share
