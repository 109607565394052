import React, {useRef} from 'react';
import {SearchField, SearchIcon, Wrapper} from './styles';
import {useTranslation} from "react-i18next";

const SearchForm: React.FC = () => {
  const inputEl = useRef(null);
  const { t } = useTranslation()

  const doSearch = () => {
    // @ts-ignore
    const searchTerm = inputEl.current.value.trim();

    if (searchTerm)
      window.location = `/search?searchTerm=${searchTerm}` as any;
  }

  return (
    <Wrapper>
      <SearchField
        type="text"
        placeholder={t('search')}
        name="searchTerm"
        ref={inputEl}
        onKeyDown={(e) => (e.keyCode == 13) && doSearch()}
      />
      <SearchIcon onClick={doSearch} />
    </Wrapper>
  );
}

export default SearchForm;