import React from "react"
import { useTranslation } from "react-i18next"

interface TextProps {
  textId: string
  type: string
  className?: string
  onClick?: any
}

const Text: React.FC<TextProps> = ({ className, onClick, textId, type }) => {
  const { t } = useTranslation()
  const TextComponent = React.createElement(type, {
    className,
    dangerouslySetInnerHTML: { __html: t(textId) },
    onClick,
  })

  return TextComponent
}

export default Text
