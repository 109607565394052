import styled from "styled-components/macro"

export const InlineButtonWrapper = styled.div`
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  svg {
    margin: 0 0.5rem 0 0;
    height: 0.8rem;
  }
  span {
    text-transform: uppercase;
    font-size: 0.8rem;
  }
`
