import styled from 'styled-components';
import { colours } from '../../../theme';

const FooterBottomLinksWrapper = styled.ul`
  display: flex;
  padding: 0 5px 20px;
  list-style-type: none;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom:0;

  li {
    &.item {
      text-align: left;
    }

    &:nth-child(1) {
      order: 5;
      margin-top: 20px;
    }
  }

  .copyright {
    margin: 0;
    width: 240px;
    font-size: 15px;
    text-align: left;
    color: ${colours.white};
  }
  
  @media (min-width: 1280px) {
    flex-direction: row !important;
    padding: 0 5px 25px;
    
    li {
      &.item {
        width: 12.5%;
        text-align: left;

        a {
          font-size: 0.7rem;
        }
      }
      
      &:nth-child(1) {
        order: 0;
        margin: 0;
      }
    }
    
    .copyright {
      font-size: 11px;
      width: unset;
      margin-left: 40px;
    }
  }
`;

export default FooterBottomLinksWrapper;
