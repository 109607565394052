import React, { useEffect } from "react"
import { Overlay, ModalWrapper } from "./styles"
import { AnimatedContent } from "components"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { navigate } from "@reach/router"
import { modal,rootPath, submittedDesign } from "store/atoms"
import { configSettings } from "store/selectors"
import Abandon from "./Abandon"
import Error from "./Error"
import Share from "./Share"
import Submitted from "./Submitted"
import { useTranslation } from "react-i18next"
import VoteSubmitted from "./VoteSubmitted"

interface ModalProps {
  content: number
}

const Modal: React.FC<ModalProps> = ({ content }) => {
  const setModal = useSetRecoilState(modal)
  const config = useRecoilValue(configSettings)
  const { t } = useTranslation()
  const rootUrl = useRecoilValue(rootPath)
  const design = useRecoilValue(submittedDesign)

  useEffect(() => {
    document.body.style.overflow = "hidden"

    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  const overlayVariants = {
    active: {
      opacity: 1,
    },
    inactive: {
      opacity: 0,
      delay: 0.2,
    },
  }

  const close = () => {

    if(content === 2){
      const root = rootUrl === "/" ? rootUrl : `/${rootUrl}/`
      if (config.useCRM === true) {
        navigate(`${root}${t("pathSignup")}`)
      } else {
        navigate(`${root}${t("pathGalleryItem")}/${design.designId}`)
      }
    }

    // @ts-ignore
    setModal({ ...modal, show: false })
  }

  const contentPages = [
    <Abandon />,
    <Error />,
    <Submitted />,
    <Share />,
    <VoteSubmitted />,
  ]

  return (
    <Overlay
      initial="inactive"
      animate="active"
      exit="inactive"
      variants={overlayVariants}
      //onClick={close}
    >
      <AnimatedContent trigger>
        <ModalWrapper>{contentPages[content]}</ModalWrapper>
      </AnimatedContent>
    </Overlay>
  )
}

export default Modal
