import styled from "styled-components/macro"
import { motion } from "framer-motion"
import { colours } from "theme"

interface TabsWrapperProps {
  ref?: any
}

interface TabProps {
  selected: Boolean
}

export const TabsWrapper = styled.div<TabsWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;
  margin: 3rem 0 3rem;
  overflow: hidden;
  cursor: pointer;
`

export const Tab = styled.div<TabProps>`
  transition: 0.3s;
  text-transform: uppercase;
  font-family: Fjalla;
  font-size: 1rem;
  cursor: pointer;
  color: ${(props) => (props.selected ? colours.primary : colours.grey5)};
  height: 3.5rem;
  display: flex;
  flex: 1;
  justify-content: center;
  &:hover {
    color: ${(props) => (props.selected ? colours.primary : colours.grey5)};
  }
`

export const TabScrub = styled(motion.div)`
  position: absolute;
  bottom: 0;
  pointer-events: none;
  height: 3px;
  background: ${colours.primary};
`
