import styled from "styled-components/macro"

interface LayoutProps {
  modalOpen: boolean
}

export const LayoutWrapper = styled.div<LayoutProps>`
  flex: none;
  overflow: auto;
  overflow-x: hidden;

  .router {
    flex: 1;
    min-height: 70vh;

    @media (min-width: 992px) {
      // min-height: 90vh;
    }
  }
  @media (min-width: 992px) {
    min-height: 50vh;
  }
  //transition: filter 0.3s;
  //filter: blur(${(props) => (props.modalOpen ? "3px" : 0)});
`
