import styled from 'styled-components';

const SocialIconsWrapper = styled.div`
  .header {
    font-family: "Futura", Calibri, sans-serif;
    font-size: 12px;
  }

  .socials {
    flex-direction: row !important;
    justify-content: flex-start;
    display:flex;

    a {
      color: white;
      width: 20px;
      height: 20px;
      margin-right: 20px;
      
      svg {
        width: 100%;
        height: 100%;
      }
    }

    @media (min-width: 1280px) {
      flex-direction: row !important;;
      justify-content: flex-start;
    }
  }
`;

export default SocialIconsWrapper;
