import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"

interface TermsProps {
  onBack: () => void
}

const TermsUK: React.FC<TermsProps> = ({ onBack }) => {
  return (
    <ArticleWrapper>
      <span className="back" onClick={onBack}>
        Back
      </span>
      <div className="container">
        <h1>ALGEMENE VOORWAARDEN</h1>
        <ol>
          <li>
            <p>
              De "Together we create" (de "<b>Actie</b>") wordt georganiseerd
              door <b>Tommy Hilfiger Europe B.V.</b>, een besloten vennootschap
              met beperkte aansprakelijkheid, in overeenstemming met de
              Nederlandse wet (de "<b>Organisator</b>") en de algemene
              voorwaarden (de "<b>Voorwaarde</b>"). Een consument die deelneemt
              aan de Actie (hierna &quot;<b>Deelnemer</b>&quot; genoemd) stemt
              ermee in de Voorwaarden te hebben gelezen en begrepen en is
              hieraan gebonden. Deze voorwaarden zijn van toepassing op het
              grondgebied van Nederland, Duitsland, Frankrijk en het Verenigd
              Koninkrijk. Deelname aan deze actie is alleen voor inwoners van
              Nederland, Duitsland, Frankrijk en het Verenigd Koninkrijk. De
              actie is ongeldig indien wettelijk verboden of beperkt. Door deel
              te nemen aan deze actie tussen 2 september 2020 en 7 september
              2020 (de &quot;Actieperiode&quot;), maken deelnemers kans om het
              uiteindelijke ontworpen product, een T-shirt of hoodie, te winnen.
              De prijs die kan worden gewonnen, wordt nader gespecificeerd in
              clausule 6 van deze voorwaarden.
            </p>
            <p>
              Wat moet je doen voor een kans om te winnen? Customize één van
              onze favoriete Tommy-items met gebruik van reststoffen, voeg
              graphics toe, en upload je ontwerp. Zie hoe jouw product tot leven
              komt en hoe andere Tommy-fan het dragen.
            </p>
            <p>
              Je doet mee door je favoriete ontwerpopties toe te passen. Je kunt
              ervoor kiezen om te personaliseren door merk- en logodetails toe
              te voegen, de richting van de scheidingslijn tussen de kleuren te
              kiezen, de kleurencombinaties en de reststoffen te selecteren. Als
              je klaar bent dien je je ontwerp in, en laat je je e-mailadres en
              maat achter. De winnaar wordt willekeurig gekozen op 14 september
              2020. Deelname en indiening van de gekozen ontwerpopties geven
              geen aanleiding tot intellectuele eigendomsrechten voor de
              Deelnemer. Alle intellectuele eigendomsrechten op de ontwerpopties
              en afbeeldingen, inclusief het uiteindelijke ontworpen product,
              blijven in eigendom van de organisator.
            </p>
            <p>
              Bij deelname word je geacht deze Algemene voorwaarden te
              accepteren.
            </p>
            <p>
              De 8 winnaars (elk te noemen &quot;Winnaar&quot;) worden kort na
              14 september 2020 via hun e-mailadres op de hoogte gebracht van
              het feit dat ze hebben gewonnen, en de namen van de winnaar(s)
              zullen worden gepubliceerd op
              <a href="https://uk.tommy.com/flag">https://uk.tommy.com/flag</a>
            </p>
          </li>
          <li>
            <p>
              Deze actie loopt, binnen de gestelde Actieperiode, alleen in
              Nederland, Duitsland, Frankrijk en het Verenigd Koninkrijk.
            </p>
          </li>
          <li>
            <p>
              Deelnemers moeten minimaal achttien (18) jaar oud zijn op de dag
              van deelname aan de Actie. Deze Actie staat niet open – direct of
              indirect – voor werknemers of naaste familieleden van werknemers
              van de Promotor, dochterondernemingen of gelieerde ondernemingen,
              of voor anderen die een professionele relatie hebben met de Actie.
              In clausule 7 van de Algemene Voorwaarden worden de details van de
              Promotor genoemd.
            </p>
          </li>
          <li>
            <p>
              De Winnaar moet binnen 48 uur na het eerste bericht een antwoord
              sturen naar de Promotor om te bevestigen dat hij/zij de prijs
              aanvaardt. Als er binnen deze periode geen antwoord is gekomen,
              heeft de Promotor het recht om een andere Winnaar te selecteren
              volgens dezelfde criteria die werden gebruikt voor de selectie van
              de oorspronkelijke Winnaar.
            </p>
          </li>
          <li>
            <p>De actie is ongeldig indien wettelijk verboden of beperkt.</p>
          </li>
          <li>
            <p>
              Prijs: De prijs die door deelname kan worden gewonnen bestaat uit
              ofwel het T-shirt met een winkelwaarde van 50 EURO, ofwel de
              hoodie met een winkelwaarde van 100 EURO . De prijs is niet
              overdraagbaar en er is geen contante vergoeding of vervangende
              prijs mogelijk, behalve naar goeddunken van de Promotor of zoals
              hierin bepaald. Er worden geen contante alternatieven voor de
              prijs aangeboden. Indien de Organisator door omstandigheden buiten
              zijn macht niet in staat is de prijs of een deel hiervan te
              verstrekken, behoudt de Organisator zich het recht voor om een
              vervangende prijs van gelijke of grotere waarde toe te kennen.
            </p>
          </li>
          <li>
            <p>
              Alle vragen of klachten met betrekking tot de Promotie dienen de
              naam van de Promotie te vermelden en per e-mail te worden gestuurd
              naar:
            </p>
            <p>
              Tommy Hilfiger Europe B.V. <br />
              Danzigerkade 165
              <br />
              1013 AP Amsterdam
              <br />
              Nederland
              <br />
              e-mail: <a href="mailto:MYTOMMY@tommy.com">MYTOMMY@tommy.com</a>
            </p>
          </li>
          <li>
            <p>
              De organisator is niet verantwoordelijk voor technische fouten in
              telecommunicatienetwerken, internetverbindingen of andere systemen
              die een inzending voor deze actie onmogelijk maken.
            </p>
          </li>
          <li>
            <p>
              De Promotor behoudt zich het recht voor om een alternatieve
              winnaar te selecteren in het geval de Promotor redelijke gronden
              heeft om te geloven dat een geselecteerde Winnaar een van deze
              Algemene Voorwaarden heeft overtreden. Een alternatieve Winnaar
              wordt geselecteerd door de toepassing van dezelfde criteria die
              zijn gebruikt voor de selectie van de oorspronkelijke Winnaar.
            </p>
          </li>
          <li>
            <p>
              Geen enkele afstand van rechten door de Promotor of Deelnemer zal
              worden begrepen als een afstand van andere rechten die de Promotor
              of Deelnemer kan hebben. Als de Promotor bijvoorbeeld afstand doet
              van het recht om te klagen over een bepaalde overtreding van de
              Algemene Voorwaarden door een Deelnemer, betekent dit niet dat de
              Promotor dan ook niet mag klagen over andere overtredingen van de
              Algemene Voorwaarden.
            </p>
          </li>
          <li>
            <p>
              Alle persoonsgegevens verzameld van Deelnemers door de Promotor
              (bijvoorbeeld de naam van de Winnaar), worden alleen gebruikt voor
              deze Actie.
            </p>
          </li>
          <li>
            <p>
              De algemene voorwaarden vallen uitsluitend onder de Nederlandse
              wetgeving, tenzij verplichte lokale wetgeving zwaarder weegt, en
              geschillen zijn onderhevig aan de exclusieve jurisdictie van de
              bevoegde rechtbanken in Amsterdam, Nederland, tenzij verplichte
              lokale wetgeving zwaarder weegt.
            </p>
          </li>
        </ol>
      </div>
    </ArticleWrapper>
  )
}

export default TermsUK
