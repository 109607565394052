import { Data as d } from "."
import config from "../../flag-config/config.json";

interface Leaderboard {
  designs: Array<any>
}

interface Badge {
  type: number
  position: number
  size: string
}

interface Split {
  slant: boolean
  position: string
}

interface Design {
  badges: Array<Badge>
  leftColour: string
  rightColour: string
  split: Split
  modelType: string
  imageUrl: string
}

export interface DesignView {
  design: Design
  id: string
  totalCreated: number
  likes: number,
  leaderboardPos: number
}

interface DesignCreatedResult {
  designId: string
}

interface SocialCreatedResult {
  likeAdded: boolean
  likeRemoved: boolean
}

interface FileCreatedResult {
  url: string
}

function formatLeaderboard(leaderboard: any): Leaderboard {
  return { designs: leaderboard.designs }
}

function formatDesign(design: DesignView): DesignView {
  return design
}

function formatDesignCreatedResult(designResult: any): DesignCreatedResult {
  return { designId: designResult.designId }
}

function formatSocialCreatedResult(socialResult: any): SocialCreatedResult {
  return {
    likeAdded: socialResult.likeAdded,
    likeRemoved: socialResult.likeRemoved,
  }
}

function formatAttachmentCreatedResult(fileResult: any): FileCreatedResult {
  return { url: fileResult.url }
}

export default {
  getHeaders(): Object {
    return {
      withCredentials: true,
      headers: {
        "X-API-Key": d.APIKEY,
        "Content-Type": "application/json",
      },
    }
  },

  getLeaderboardView(): Promise<Leaderboard> {
    const opts = this.getHeaders()
    return fetch(`${config.flagApiEndpoint}/${d.LeaderboardPath}`, opts)
      .then((res) => res.json())
      .then((res) => formatLeaderboard(res))
      .catch(this.handleError)
  },

  getDesignView(designId: string): Promise<DesignView> {
    const opts = this.getHeaders()
    return fetch(`${config.flagApiEndpoint}/${d.DesignsPath}/${designId}`, opts)
      .then((res) => res.json())
      .then((res) => formatDesign(res))
      .catch(this.handleError)
  },

  saveDesign(design: Design): Promise<DesignCreatedResult> {
    const domain = new URL(window.location.href).hostname
    const opts = {
      ...this.getHeaders(),
      method: "POST",
      body: JSON.stringify(design),
    }
    return fetch(`${config.flagApiEndpoint}/${d.DesignsPath}?origin=${domain}`, opts)
      .then((res) => res.json())
      .then((res) => formatDesignCreatedResult(res))
      .catch(this.handleError)
  },

  likeDesign(designId: string): Promise<any> {
    const opts = { ...this.getHeaders(), method: "POST" }
    return fetch(`${config.flagApiEndpoint}/${d.SocialPath}/like?id=${designId}`, opts)
      .then((res) => res.json())
      .then((res) => formatSocialCreatedResult(res))
      .catch(this.handleError)
  },

  unLikeDesign(designId: string): Promise<any> {
    const opts = { ...this.getHeaders(), method: "POST" }
    return fetch(`${config.flagApiEndpoint}/${d.SocialPath}/unlike?id=${designId}`, opts)
      .then((res) => res.json())
      .then((res) => formatSocialCreatedResult(res))
      .catch(this.handleError)
  },

  uploadFile(file: File): Promise<any> {
    const formData = new FormData()
    formData.append("file", file)
    const opts = {
      withCredentials: true,
      headers: {
        "X-API-Key": d.APIKEY,
      },
      method: "POST",
      body: formData,
    }
    // @ts-ignore
    return fetch(`${config.flagApiEndpoint}/${d.AttachmentPath}`, opts)
      .then((res) => res.json())
      .then((res) => formatAttachmentCreatedResult(res))
      .catch(this.handleError)
  },

  handleError(error: any): Promise<any> {
    console.error("An error occurred", error)
    return Promise.reject(error.message || error)
  },
}
