import React, { useRef, useState } from "react"
import { ArticleWrapper } from "../styles"


interface TermsProps {
    onBack: () => void
  }

const Terms: React.FC<TermsProps> =({
    onBack
  }) => {

  return (
    	<ArticleWrapper>
				<span className="back" onClick={onBack}>
						Back
					</span>
				<div className="container">
					<h1>
						CONDITIONS GÉNÉRALES
					</h1>
					<p>
					Seuls les résidents des Pays-Bas, Allemagne, France et Royaume Uni peuvent participer. Nul et non avenu là où la loi l’interdit. Cette offre est valable du 2 septembre au 7 septembre. Pour participer à cette promotion, vous devez personnaliser et nous envoyer vos créations préférées pour le t-shirt et le sweat à capuche avant le 7 septembre 2020. Le gagnant sera tiré au sort autour du 14 septembre 2020.Le gagnant recevra sa création produite, soit le t-shirt soit le sweat, au hasard. Le nom du(des) gagnant(s) seront publiés sur <a href="https://fr.tommy.com/flag/terms/comp" target="_blank">ici</a>. Les participants doivent avoir plus de 18 ans pour participer. Cliquez <a href="https://fr.tommy.com/flag/terms/comp" target="_blank">ici</a> pour plus d’informations et voir toutes les conditions générales.
					</p>
				</div>
			</ArticleWrapper>
  )
}

export default Terms


