import en from './en.json';

const getDataFromLang = (lang: string): { cookieNotice: any } => {
  switch (lang) {
    case 'en':
    default:
      return en;
  }
};

export default { getDataFromLang };
