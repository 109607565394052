import React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { currentBadge, textureSettings, stepDirection } from "store/atoms"
import { FlexRow } from "theme"
import { IconButton, InlineButton, NextButton, Text } from "components"
import { StepsWrapper } from "./styles"
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg"
import * as ShirtLeft from "assets/images/shirtSliceLeft.png"
import * as ShirtCentre from "assets/images/shirtSliceCentre.png"
import * as ShirtRight from "assets/images/shirtSliceRight.png"
import * as HoodLeft from "assets/images/hoodSliceLeft.png"
import * as HoodCentre from "assets/images/hoodSliceCentre.png"
import * as HoodRight from "assets/images/hoodSliceRight.png"
import * as ShirtSlantLeft from "assets/images/shirtDiagonalLeft.png"
import * as ShirtSlantCentre from "assets/images/shirtDiagonalCentre.png"
import * as ShirtSlantRight from "assets/images/shirtDiagonalRight.png"
import * as HoodSlantLeft from "assets/images/hoodDiagonalLeft.png"
import * as HoodSlantCentre from "assets/images/hoodDiagonalCentre.png"
import * as HoodSlantRight from "assets/images/hoodDiagonalRight.png"
import { useTranslation } from "react-i18next"

const shirtVertical = [ShirtLeft, ShirtCentre, ShirtRight]
const shirtDiagonal = [ShirtSlantLeft, ShirtSlantCentre, ShirtSlantRight]
const hoodVertical = [HoodLeft, HoodCentre, HoodRight]
const hoodDiagonal = [HoodSlantLeft, HoodSlantCentre, HoodSlantRight]

const Step2: React.FC<RouteComponentProps> = () => {
  const settings = useRecoilValue(textureSettings)
  const updateTexture = useSetRecoilState(textureSettings)
  const setCurrBadge = useSetRecoilState(currentBadge)
  const direction = useRecoilValue(stepDirection)
  const setDirection = useSetRecoilState(stepDirection)
  const { t } = useTranslation()

  const chooseSlice = (index: number) => {
    let slice = "center"
    if (index === 0) {
      slice = "left"
    } else if (index === 2) {
      slice = "right"
    }

    const newSettings = { ...settings }
    const newSplit = { ...newSettings.split }
    newSplit.position = slice
    newSettings.split = newSplit
    newSettings.badges = []
    updateTexture(newSettings)
    setCurrBadge({ type: -1, position: -1, size: -1 })
  }

  const goto = (url: string) => {
    const dir = url === `${t("pathStep")}3` ? "right" : "left"
    setDirection(dir)
    navigate(url)
  }

  const { position, slant } = settings.split
  const { modelType } = settings
  let currSlice = 0
  if (position === "center") {
    currSlice = 1
  } else if (position === "right") {
    currSlice = 2
  }

  const getButtons = (slices: any[]) => {
    return slices.map((val, i) => {
      return (
        <IconButton
          key={`iconButton${i}`}
          onClick={() => chooseSlice(i)}
          active={currSlice === i}
          size={{ width: 100, height: 100 }}
        >
          {<img className="image-split" src={val} />}
        </IconButton>
      )
    })
  }

  let dir = 0
  if (direction === "right") {
    dir = 50
  } else if (direction === "left") {
    dir = -50
  }

  let sliceImages = shirtVertical

  if (slant) {
    if (modelType === "shirt") {
      sliceImages = shirtDiagonal
    } else {
      sliceImages = hoodDiagonal
    }
  } else if (!slant && modelType === "hood") {
    sliceImages = hoodVertical
  }

  return (
    <StepsWrapper
      initial={{ x: dir, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -dir, opacity: 0 }}
    >
      <Text textId="editorChooseSliceLocation" type="h5" />
      <FlexRow className="scalable">{getButtons(sliceImages)}</FlexRow>
      <FlexRow className="row center">
        <InlineButton
          textId="buttonBack"
          icon={<ChevronLeft />}
          onClick={() => goto(`${t("pathStep")}1`)}
        />
        <NextButton onClick={() => goto(`${t("pathStep")}3`)} />
      </FlexRow>
    </StepsWrapper>
  )
}

export default Step2
