// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { locale } from '../../../store/atoms';
import UTILS from '../utils';
import FooterBottomLinksWrapper from './styles';

const FooterBottomLinks: React.FC = () => {
  const { longCode: localeCode } = useRecoilValue(locale);
  const [extraLinks, setExtraLinks] = useState<any>([]);

  useEffect(() => {
    const { middle } = UTILS.getDataFromLang(localeCode).footer;
    const unNamed = middle.filter(
      ({ name } : { name: any}) => !name,
    ).map(({ nav } : { nav: any}) => nav[0]);

    setExtraLinks(unNamed);
  }, [localeCode]);

  return (
    <FooterBottomLinksWrapper>
      <li>
        <p className="copyright">© 2020 Tommy Hilfiger Licensing BV. All rights reserved.</p>
      </li>
      {extraLinks.map((item: any) => {
        const { name, url } = item;

        return (
          <li className="item" key={`footer__extra__${name}`}>
            <a className="link" href={url} rel="noopener noreferrer">
              {name}
            </a>
          </li>
        );
      })}
    </FooterBottomLinksWrapper>
  );
};

export default FooterBottomLinks;
